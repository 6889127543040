import xhr from '../../Xhr'
import moment from "moment";

const API = (id) => ({
  node: {
    traffic: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/traffic`,
      method: 'GET'
    },
    bandwidth: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/bandwidth`,
      method: 'GET'
    },
    cache: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/caches`,
      method: 'GET'
    },
    mac: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/mac`,
      method: 'GET'
    },
    config: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/modify_config`,
      method: 'POST',
    },
    bind: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/bind_node`,
      method: 'POST',
    },
    getPhone: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}`,
      method: 'GET'
    },
    getTotal: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/nodes/total`,
      method: 'GET'
    },
      setLimit: {
          url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/nodes/set_limit`,
          method: 'POST'
      },
  }
})

const basicNodeAPI = (param = {}) => ({
  trafficToday: {//当天流量详情
    url: process.env.REACT_APP_NMSAPI_SERVER + `/basic/traffic_m?mac=${param.mac || ""}`,
    method: 'GET'
  },
  cpuIoToday: {//当天流量详情
    url: process.env.REACT_APP_NMSAPI_SERVER + `/basic/cpu_io?mac=${param.mac || ""}`,
    method: 'GET'
  },
  trafficYesterday: {//当天流量详情
    url: process.env.REACT_APP_NMSAPI_SERVER + `/basic/traffic_m?mac=${param.mac || ""}&date=${moment().subtract(1, 'd').unix()}`,
    method: 'GET'
  },
  cpuIoYesterday: {//当天流量详情
    url: process.env.REACT_APP_NMSAPI_SERVER + `/basic/cpu_io?mac=${param.mac || ""}&date=${moment().subtract(1, 'd').unix()}`,
    method: 'GET'
  },
  chargingBWs: {//N天计费带宽
    url: process.env.REACT_APP_NMSAPI_SERVER + `/basic/charging_bws/${param.days || ""}?mac=${param.mac || ""}`,
    method: 'GET'
  },
  inviterNodes: {
    url: process.env.REACT_APP_NMSAPI_SERVER + `/basic/inviter/nodes`,
    method: 'GET'
  }
})

const codeAPI = (phone, code) => ({
  node: {
    sendCode: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/vcode?mobile=${phone}&country_code=86`,
      method: 'GET'
    },
    checkCode: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/vcode/check?mobile=${phone}&vcode=${code}`,
      method: 'GET'
    },
  }
})

export const setLimit = ({data, ...event}) => {
    return xhr({
        api: API(localStorage.getItem('openfogos_userid')).node.setLimit,
        data,
        event
    })
};

const getAPI = (id, page, pageLength) => ({
  list: {
    url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/nodes?page=${page}&page_length=${pageLength}`,
    method: 'GET'
  },
  onlineOffline: {
    url: process.env.REACT_APP_NMSAPI_SERVER + `/node/on_off_stat`,
    method: 'GET'
  }
})

const deleteAPI = (id, mac) => ({
    url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/nodes?mac=${mac}`,
    method: 'DELETE'
})

const setNicknameAPI = (id, mac, nickname) => ({
    set: {
        url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/nodes/set_nickname?mac=${mac}&nickname=${nickname}`,
        method: 'GET'
    }
})

const searchAPI = (id, mac) => ({
  search: {
    url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/nodes?search=${mac}`,
    method: 'GET'
  }
})

export const sendCode = ({phone, ...event}) => {
  return xhr({
    api: codeAPI(phone).node.sendCode,
    action: 'SEND/CODE',
    event
  })
}

export const checkCode = ({phone, code, ...event}) => {
  return xhr({
    api: codeAPI(phone, code).node.checkCode,
    action: 'CHECK/CODE',
    event
  })
}

export const fetchPhone = () => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).node.getPhone,
    action: 'PHONE/FETCH'
  })
}

export const fetchInfo = (page, pageLength) => {
  return xhr({
    api: getAPI(localStorage.getItem('openfogos_userid'), page, pageLength).list,
    action: 'NODE/INFO'
  })
}

export const fetchOnlineOfflineStat = () => {
  return xhr({
    api: getAPI().onlineOffline,
    action: 'NODE/ONOFFSTAT'
  })
}

export const deleteMac = (mac, event) => {
    return xhr({
        api: deleteAPI(localStorage.getItem('openfogos_userid'), mac),
        action: 'NODE/DELETE',
        event
    })
}

export const setNickname = (mac, nickname, event) => {
    return xhr({
        api: setNicknameAPI(localStorage.getItem('openfogos_userid'), mac, nickname).set,
        action: 'NODE/NICKNAME',
        event: event,
        data: {
          mac: mac,
            nickname: nickname
        }
    })
}

export const fetchTotal = () => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).node.getTotal,
    action: 'GET/TOTAL'
  })
}

export const searchMac = (mac) => {
  return xhr({
    api: searchAPI(localStorage.getItem('openfogos_userid'), mac).search,
    action: 'NODE/SEARCH'
  })
}

export const fetchTraffic = ({data, ...event}) => {
  return xhr({
    api: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${localStorage.getItem('openfogos_userid')}/traffic?macs=${data.mac_addr}&start_date=${data.start}&end_date=${data.end}`,
      method: 'GET'
    },
    action: 'NODE/TRAFFIC'
  })
}

export const fetchBandwidth = ({data, ...event}) => {
  return xhr({
    api: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${localStorage.getItem('openfogos_userid')}/bandwidth?mac_addr=${data.mac_addr}&start_date=${data.start}&end_date=${data.end}`,
      method: 'GET'
    },
    action: 'NODE/BANDWIDTH'
  })
}

export const fetchCache = () => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).node.cache,
    action: 'NODE/CACHE'
  })
}

export const fetchMac = () => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).node.mac,
    action: 'NODE/MAC'
  })
}

export const submitConfig = ({data, ...event}) => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).node.config,
    action: 'NODE/CONFIG',
    data,
    event
  })
}

export const submitBind = ({data, ...event}) => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).node.bind,
    action: 'NODE/BIND',
    data,
    event
  })
}

export const stepPrev = () => ({
  type: 'NODE/BIND_STEP_PREV'
})

export const stepNext = () => ({
  type: 'NODE/BIND_STEP_NEXT'
})
export const showModal = () => ({
    type: 'SHOW_MODAL'
})

export const hideModal = () => ({
    type: 'HIDE_MODAL'
})

//获取节点当天流量详情
export const getBasicNodeTrafficToday = (mac, event) => {
  return xhr({
    api: basicNodeAPI({mac:mac}).trafficToday,
    action: 'BASICNODE/TRAFFICTODAY',
    event
  })
};

//获取节点当天IO异常详情
export const getBasicNodeIOToday = (mac, event) => {
  return xhr({
    api: basicNodeAPI({mac:mac}).cpuIoToday,
    action: 'BASICNODE/CPUIOTODAY',
    event
  })
};

//获取节点昨天流量详情
export const getBasicNodeTrafficYesterday = (mac, event) => {
  return xhr({
    api: basicNodeAPI({mac:mac}).trafficYesterday,
    action: 'BASICNODE/TRAFFICYESTERDAY',
    event
  })
};

//获取节点昨天IO异常详情
export const getBasicNodeIOYesterday = (mac, event) => {
  return xhr({
    api: basicNodeAPI({mac:mac}).cpuIoYesterday,
    action: 'BASICNODE/CPUIOYESTERDAY',
    event
  })
};

//获取节点7天计费带宽
export const getBasicNodeChargingBWsLastWeek = (mac, event) => {
  return xhr({
    api: basicNodeAPI({mac:mac, days:7}).chargingBWs,
    action: 'BASICNODE/CHARGINGBWS/LASTWEEK',
    event
  })
};

//获取节点30天计费带宽
export const getBasicNodeChargingBWsLastMonth = (mac, event) => {
  return xhr({
    api: basicNodeAPI({mac:mac, days:30}).chargingBWs,
    action: 'BASICNODE/CHARGINGBWS/LASTMONTH',
    event
  })
};

//获取矿场推广节点
export const getInviterNodes = () => {
  return xhr({
    api: basicNodeAPI().inviterNodes,
    action: 'BASICNODE/INVITE/NODES',
  })
};