import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { connect } from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {fetchRewardStatistics, fetchTop5} from "./actions";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Blue from "@material-ui/core/colors/blue";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Contact from '../../common/contact';


const styles = theme => ({
    card: {
        minWidth: 275,
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    gap: {
        height:theme.spacing( 2),
    },
    tableCell: {
        paddingRight: '10px',
        // paddingLeft: '0px',
        // paddingTop: '5px',
        // paddingBottom: '0px',
        "& a": {
            color: Blue[500]
        }
    },
    top1: {
        color: "#e40015",
        fontWeight: "bold"
    },
    top2: {
        color: "#eb8b00",
        fontWeight: "bold"
    },
    top3: {
        color: "#efdb00",
        fontWeight: "bold"
    },
    statistics: {
        marginTop: theme.spacing( 2),
        marginBottom: theme.spacing( 2
    )},
    aLink: {
        color: Blue[500],
    },
    headerBackground: {
        position: 'absolute',
        // top: -theme.spacing( 2),
        left: 0,
        width: '100%',
        height: '55vh',
        background: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0)), url(/largeRecruitBG.png) `,
        backgroundPosition: 'center bottom',
        backgroundSize: 'cover',
        // zIndex: -200,
    },
    header: {
        height: '55vh',
        padding: '13vh 0 16vh',
        boxSizing: 'border-box',
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative'
    },
    main: {
        marginTop: theme.spacing( 2),
        backgroundColor: '#fff',
        '& h6': {
            paddingTop: theme.spacing( 2),
        },
        '& section': {
            paddingTop: theme.spacing( 3),
            paddingBottom: theme.spacing( 3),
        }
    },
    main2: {
        marginTop: theme.spacing( 4),
        '& h6': {
            paddingTop: theme.spacing( 2),
        },
        '& section': {
            paddingTop: theme.spacing( 3),
            paddingBottom: theme.spacing( 3),
        }
    },
    table: {
        backgroundColor: '#fff',
    },
    bigTitle: {
        fontSize: '4.25em',
        color: '#fff',
        fontWeight: "bold",
        [theme.breakpoints.down('sm')]: {
            fontSize: '3em',
        },
    },
    subTitle: {
        fontSize: '1.5em',
        color: '#e1c052',
        fontWeight: 'normal',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.1em',
        },
    },
    sectionTitle: {
        marginBottom: theme.spacing( 6),
    },
    mainButtonContainer: {
        display: 'block'
    },
    mainButton: {
        // border: '1px solid #fff',
        fontSize: '1.5em',
        padding: '18px 40px',
        backgroundColor: '#ff6a00',
        color: '#fff',
        boxSizing: 'border-box',
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        borderRadius: "8px",
        "&:hover": {
            background: "#ef6900"
        }
    },
    step: {
        backgroundPosition: 'center center',
        backgroundSize: '100%',
        display: 'block',
        width: '70px',
        height: '70px',
        margin: '10px auto 30px',
        filter: "hue-rotate(211.5deg) saturate(53.5%) brightness(137%)",
        // filter: "hue-rotate(177deg) saturate(200%) brightness(137%)",
        // filter: "hue-rotate(204deg) saturate(47%) brightness(142%)"
    },
    step1: {
        backgroundImage: `url(/largeRecruit/step-1.png)`
    },
    step2: {
        width: '65px',
        height: '65px',
        margin: '15px auto 30px',
        backgroundImage: `url(/largeRecruit/step-2.png)`
    },
    step3: {
        backgroundImage: `url(/largeRecruit/step-3.png)`
    },
    step4: {
        backgroundImage: `url(/largeRecruit/step-4.png)`
    },
    step5: {
        backgroundImage: `url(/largeRecruit/step-5.png)`
    },
    stepArrow: {
        display: 'block',
        position: 'absolute',
        backgroundSize: '100%',
        backgroundImage: `url(/largeRecruit/arrow.jpg)`,
        top: '22px',
        right: '-80px',
        width: '110px',
        height: '20px',
        filter: "hue-rotate(211.5deg) saturate(53.5%) brightness(137%)",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        },
        // filter: "hue-rotate(177deg) saturate(200%) brightness(137%)",
    },
    stepContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-around',
        padding: '0',

        '& li': {
            listStyle: 'none',
            width: '150px',
            position: 'relative'
        }
    },
    tableHead: {
        backgroundColor: '#ccc',
        '& th': {
            color: '#111',
            fontSize: '1rem'
        }

    }
});

function getSteps() {
    return ['提交机器信息', '验证和机器部署', '安装资源共享程序', '验收和分发资源测试', '正式共享资源'];
}

function getTableContent1() {
    return [
        ['4线程', '2G', '32G', '720G SSD', <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '静态IP', '千兆网卡', '500M'],
        ['8线程', '4G', '32G', '1T SSD', <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '静态IP', '千兆网卡', '1G'],
        ['16线程', '8G', '32G', '2T SSD', <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '静态IP', '万兆网卡', '2G'],
        ['32线程', '16G', '32G', '2*2T PCIe', <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '静态IP', '万兆网卡', '3-5G'],
        ['48线程', <a href="https://item.taobao.com/item.html?id=613278128251" target="_blank">32G</a>, '32G', '5*2T PCIe', <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '静态IP', '万兆网卡', '10G'],
    ];
}
function getTableContent2() {
    return [
    ['12线程', '16G', '32G SSD以上', ['2*',<a href="https://item.taobao.com/item.htm?id=612266090288" target="_blank">2T SSD</a>], <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '10*100M', '千兆网卡', '1G'],
    ['20线程', <a href="https://item.taobao.com/item.html?id=613278128251" target="_blank">32G</a>, '32G SSD以上', ['4*',<a href="https://item.taobao.com/item.htm?id=612266090288" target="_blank">2T SSD</a>], <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '20*100M', '万兆网卡', '2G'],
    ['104线程', <a href="https://item.taobao.com/item.htm?id=613800018326" target="_blank">128G</a>, '32G SSD以上', ['8*', <a href="https://item.taobao.com/item.htm?id=612266090288" target="_blank">2T SSD</a>], <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '100*50M', '万兆网卡', '5G'],
    ['104线程', <a href="https://item.taobao.com/item.htm?id=613800018326" target="_blank">128G</a>, '32G SSD以上', ['16*', <a href="https://item.taobao.com/item.htm?id=612266090288" target="_blank">2T SSD</a>], <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '100*100M', '万兆网卡', '10G'],
    ['52线程', <a href="https://item.taobao.com/item.htm?id=613800018326" target="_blank">128G</a>, '32G SSD以上', ['16*', <a href="https://item.taobao.com/item.htm?id=612266090288" target="_blank">2T SSD</a>], <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '50*200M', '万兆网卡', '10G'],
    ['40线程', <a href="https://item.taobao.com/item.htm?id=613800018326" target="_blank">128G</a>, '32G SSD以上', ['16*', <a href="https://item.taobao.com/item.htm?id=612266090288" target="_blank">2T SSD</a>], <a href="/home/doc/makesystemdisk">OpenFogOS</a>, '不开80', '20*500M', '万兆网卡', '10G'],
    ];
}

class Site extends React.Component {


    render() {
        const steps = getSteps();
        const tableContent1 = getTableContent1();
        const tableContent2 = getTableContent2();
        const { classes, isAuthenticated } = this.props;
        return (
            <div>
                <div className={classes.headerBackground}>
                </div>
                <header className={classes.header}>
                    <Typography variant="h5" align='center' className={classes.bigTitle}>
                        矿机节点招募
                    </Typography>
                    <Typography variant="body1" align='center'  className={classes.subTitle}>
                        业务稳定&emsp;收益可观&emsp;资源合法&emsp;提现方便
                    </Typography>
                        {isAuthenticated ?
                            <a target='_self' href="/home/submit_node" className={classes.mainButton}>
                                提交节点
                            </a>
                            :
                            <a target='_self' href={process.env.REACT_APP_ACCOUNT_SERVER + "/login?" + encodeURIComponent(`source=${document.location.host}&title=OpenFogOS`)} className={classes.mainButton}>
                                立即接入
                            </a>
                        }

                </header>

                <main className={classes.main}>
                    <section style={{overflow: "auto"}}>
                        <Typography variant="h4" gutterBottom align='center' className={classes.sectionTitle}>
                            接入流程
                        </Typography>
                        {/*<Stepper activeStep={0} alternativeLabel>
                            {steps.map(label => (
                                <Step key={label} active={true}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>*/}
                        <ul className={classes.stepContainer}>
                            <li>
                                <a target='_self' href={isAuthenticated ? "/home/submit_node": process.env.REACT_APP_ACCOUNT_SERVER + "/login?" + encodeURIComponent(`source=${document.location.host}&title=OpenFogOS`)}>
<span className={`${classes.step} ${classes.step1}`}/>
                                <Typography variant="body1" align='center' gutterBottom>Step 1</Typography>
                                <Typography variant="body1" align='center'>{steps[0]}</Typography>
                                <span className={classes.stepArrow}/>
                                </a>
                                
                            </li>
                            <li>
                                <span className={`${classes.step} ${classes.step2}`}/>
                                <Typography variant="body1" align='center' gutterBottom>Step 2</Typography>
                                <Typography variant="body1" align='center'>{steps[1]}</Typography>
                                <span className={classes.stepArrow}/>
                            </li>
                            <li>
                                <span className={`${classes.step} ${classes.step3}`}/>
                                <Typography variant="body1" align='center' gutterBottom>Step 3</Typography>
                                <Typography variant="body1" align='center'>{steps[2]}</Typography>
                                <span className={classes.stepArrow}/>
                            </li>
                            <li>
                                <span className={`${classes.step} ${classes.step4}`}/>
                                <Typography variant="body1" align='center' gutterBottom>Step 4</Typography>
                                <Typography variant="body1" align='center'>{steps[3]}</Typography>
                                <span className={classes.stepArrow}/>
                            </li>
                            <li>
                                <span className={`${classes.step} ${classes.step5}`}/>
                                <Typography variant="body1" align='center' gutterBottom>Step 5</Typography>
                                <Typography variant="body1" align='center'>{steps[4]}</Typography>
                            </li>
                        </ul>
                    </section>

                    <Divider/>



                </main>

                <main className={classes.main2}>
                    <section style={{overflow: "auto"}}>
                        <Typography variant="h4" gutterBottom align="center" className={classes.sectionTitle}>
                            建议配置
                        </Typography>
                        <Table className={classes.table} >
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell className={classes.tableCell} align="center">链路类型</TableCell>
                                    <TableCell className={classes.tableCell} align="center">CPU</TableCell>
                                    <TableCell className={classes.tableCell} align="center">内存</TableCell>
                                    <TableCell className={classes.tableCell} align="center">系统盘</TableCell>
                                    <TableCell className={classes.tableCell} align="center">数据盘</TableCell>
                                    <TableCell className={classes.tableCell} align="center">系统</TableCell>
                                    <TableCell className={classes.tableCell} align="center">端口</TableCell>
                                    <TableCell className={classes.tableCell} align="center">IP</TableCell>
                                    <TableCell className={classes.tableCell} align="center">网卡</TableCell>
                                    <TableCell className={classes.tableCell} align="center">带宽</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(tableContent1).map((row, index) => (
                                    <TableRow key={index}>
                                        {index === 0? <TableCell className={classes.tableCell} align="center" rowSpan={tableContent1.length}>
                                            <Typography  align="center"><span style={{whiteSpace:'nowrap'}}>专线宽带</span></Typography>
                                        </TableCell >: null}
                                        {row.map((value, index) => (
                                            <TableCell key={index} className={classes.tableCell} align="center">
                                                <Typography align="center"><span style={index === 4? null:{whiteSpace:'nowrap'}}>{value}</span></Typography>
                                            </TableCell >
                                        ))}
                                    </TableRow>
                                ))}
                                {(tableContent2).map((row, index) => (
                                    <TableRow key={index}>
                                        {index === 0? <TableCell className={classes.tableCell} align="center" rowSpan={tableContent2.length}>
                                            <Typography align="center"><span style={{whiteSpace:'nowrap'}}>汇聚宽带</span></Typography>
                                        </TableCell >: null}
                                        {row.map((value, index) => (
                                            <TableCell key={index} className={classes.tableCell} align="center">
                                                <Typography align="center"><span style={index === 4? null:{whiteSpace:'nowrap'}}>{value}</span></Typography>
                                            </TableCell >
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </section>
                </main>
                <Contact/>




            </div>)
    }
    componentDidMount(){
        this.props.onRequire();
    }
}

Site.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
    return {
        loading: state.utils.rewardStatisticsStatus,
        isAuthenticated: state.auth.isAuthenticated
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire: () => {
        },
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Site));