const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export default (state = {bw: {}}, action) => {
  switch(action.type) {

    case 'BC/BW_STARTED': {
      return {...state, bcBWStatus: Status.LOADING}
    }
    case 'BC/BW_SUCCESS': {
      return {...state, bcBWStatus: Status.SUCCESS, bw: action.result}
    }
    case 'BC/BW_FAILURE': {
      return {...state, bcBWStatus: Status.FAILURE}
    }
      case 'BC/NODE_STARTED': {
          return {...state, bcNodeStatus: Status.LOADING}
      }
      case 'BC/NODE_SUCCESS': {
          return {...state, bcNodeStatus: Status.SUCCESS, nodes: action.result}
      }
      case 'BC/NODE_FAILURE': {
          return {...state, bcNodeStatus: Status.FAILURE}
      }
    case 'ADVANCEDNODES/NODE_STARTED': {
      return {...state, getAdvancedNodesStatus: Status.LOADING}
    }
    case 'ADVANCEDNODES/NODE_SUCCESS': {
      return {...state, getAdvancedNodesStatus: Status.SUCCESS, advancedNodes: action.result}
    }
    case 'ADVANCEDNODES/NODE_FAILURE': {
      return {...state, getAdvancedNodesStatus: Status.FAILURE}
    }

    case 'ADVANCEDNODE/TRAFFICTODAY_STARTED': {
      return {...state, getadvancedNodeTrafficTodayStatus: Status.LOADING, advancedNodeTrafficToday: []}
    }
    case 'ADVANCEDNODE/TRAFFICTODAY_SUCCESS': {
      return {...state, getadvancedNodeTrafficTodayStatus: Status.SUCCESS, advancedNodeTrafficToday: action.result}
    }
    case 'ADVANCEDNODE/TRAFFICTODAY_FAILURE': {
      return {...state, getadvancedNodeTrafficTodayStatus: Status.FAILURE, advancedNodeTrafficToday: []}
    }

    case 'ADVANCEDNODE/CPUIOTODAY_STARTED': {
      return {...state, getadvancedNodeCPUIOTodayStatus: Status.LOADING, advancedNodeCPUIOToday: null}
    }
    case 'ADVANCEDNODE/CPUIOTODAY_SUCCESS': {
      return {...state, getadvancedNodeCPUIOTodayStatus: Status.SUCCESS, advancedNodeCPUIOToday: action.result}
    }
    case 'ADVANCEDNODE/CPUIOTODAY_FAILURE': {
      return {...state, getadvancedNodeCPUIOTodayStatus: Status.FAILURE, advancedNodeCPUIOToday: null}
    }

    case 'ADVANCEDNODE/TRAFFICYESTERDAY_STARTED': {
      return {...state, getadvancedNodeTrafficYesterdayStatus: Status.LOADING, advancedNodeTrafficYesterday: []}
    }
    case 'ADVANCEDNODE/TRAFFICYESTERDAY_SUCCESS': {
      return {...state, getadvancedNodeTrafficYesterdayStatus: Status.SUCCESS, advancedNodeTrafficYesterday: action.result}
    }
    case 'ADVANCEDNODE/TRAFFICYESTERDAY_FAILURE': {
      return {...state, getadvancedNodeTrafficYesterdayStatus: Status.FAILURE, advancedNodeTrafficYesterday: []}
    }

    case 'ADVANCEDNODE/CPUIOYESTERDAY_STARTED': {
      return {...state, getadvancedNodeCPUIOYesterdayStatus: Status.LOADING, advancedNodeCPUIOYesterday: null}
    }
    case 'ADVANCEDNODE/CPUIOYESTERDAY_SUCCESS': {
      return {...state, getadvancedNodeCPUIOYesterdayStatus: Status.SUCCESS, advancedNodeCPUIOYesterday: action.result}
    }
    case 'ADVANCEDNODE/CPUIOYESTERDAY_FAILURE': {
      return {...state, getadvancedNodeCPUIOYesterdayStatus: Status.FAILURE, advancedNodeCPUIOYesterday: null}
    }

    case 'ADVANCEDNODE/CHARGINGBWS/LASTWEEK_STARTED': {
      return {...state, getadvancedNodeChargingBWsLastWeekStatus: Status.LOADING, advancedNodeChargingBWsLastWeek: []}
    }
    case 'ADVANCEDNODE/CHARGINGBWS/LASTWEEK_SUCCESS': {
      return {...state, getadvancedNodeChargingBWsLastWeekStatus: Status.SUCCESS, advancedNodeChargingBWsLastWeek: action.result}
    }
    case 'ADVANCEDNODE/CHARGINGBWS/LASTWEEK_FAILURE': {
      return {...state, getadvancedNodeChargingBWsLastWeekStatus: Status.FAILURE, advancedNodeChargingBWsLastWeek: []}
    }

    case 'ADVANCEDNODE/CHARGINGBWS/LASTMONTH_STARTED': {
      return {...state, getadvancedNodeChargingBWsLastMonthStatus: Status.LOADING, advancedNodeChargingBWsLastMonth: []}
    }
    case 'ADVANCEDNODE/CHARGINGBWS/LASTMONTH_SUCCESS': {
      return {...state, getadvancedNodeChargingBWsLastMonthStatus: Status.SUCCESS, advancedNodeChargingBWsLastMonth: action.result}
    }
    case 'ADVANCEDNODE/CHARGINGBWS/LASTMONTH_FAILURE': {
      return {...state, getadvancedNodeChargingBWsLastMonthStatus: Status.FAILURE, advancedNodeChargingBWsLastMonth: []}
    }

    default: {
      return state
    }
  }
}
