import React from 'react';
import ReactMarkdown from 'react-markdown';

//如何本地绑定闲小节点

const Hints = () => {
    const contentMD = `## 如何本地绑定闲小节点
1. 确保手机或电脑已接入闲小节点（路由器）下的局域网
   
2. 登录网站[https://openfogos.com](https://openfogos.com)

3. 通过左侧菜单栏，选择进入“闲小节点”页面。

4. 点击页面下方的“绑定本地节点”

5. 点击确认绑定按钮，即可完成绑定。绑定完成后，可以在管理中心看到节点绑定情况（因网络延迟缘故，需要等5分钟左右才会显示绑定的节点信息）

    ![确认绑定.png](/bindNode/s3.png)
6. 若无法自动搜寻到IP信息，会出现以下页面

    ![未检测到设备.png](/bindNode/s4.png)
7. 此时需要手动填写IP（如果是在本地主机上安装的插件，所以IP为 127.0.0.1），点击检测设备，然后绑定

    ![手动输入IP.png](/bindNode/s5.png)
    `;

    return (
        <div className="Advice" >
            <ReactMarkdown source={contentMD}/>
        </div>
    )
}

export default Hints;