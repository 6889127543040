import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles/index";
import withMobileDialog from "@material-ui/core/withMobileDialog/index";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    textField: {
        minWidth: 223
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 223,
    },
});

class RewardHistory extends Component {
    constructor(props, context) {
        super(props, context);
        // this.content = React.createRef();
        this.state = {
        };
        this.onChange = this.onChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.onWheel = this.onWheel.bind(this);
    }

    onChange(event) {
        this.props.onChange(
            {
                ...this.props.form,
                [event.target.name]: event.target.name === "mac_addr"? (event.target.value||"").toLowerCase():event.target.value
            }
        );
    }

    onScroll(e) {
        let content = e.currentTarget;
        let scrolled = content.scrollTop / (content.scrollHeight - content.clientHeight);
        if(scrolled >= 1) {
            console.log("滚动到底");
            if(!this.props.loading){
                this.props.loadMore();
            }
        }

    }

    onWheel(e) {
        console.log("on wheel !!!");
        console.log(e);
        let content = e.currentTarget;
        if(content.scrollHeight <= content.clientHeight) {
            console.log("无滚动条");
            if(!this.props.loading){
                this.props.loadMore();
            }
        }
    }

    componentDidMount(){
    }


    render() {
        const {classes, fullScreen, open, onCancel, list, loading} = this.props;
        return (
            <Dialog fullWidth maxWidth="xs" fullScreen={fullScreen} open={open} onClose={onCancel}>
                <DialogTitle id="form-dialog-title">历史收益</DialogTitle>
                <DialogContent style={{textAlign:"center"}} onScroll={this.onScroll} onWheel={this.onWheel} onTouchMove={this.onWheel}>
                        <Table  size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableCell} align="center">日期</TableCell>
                                    <TableCell className={classes.tableCell} align="center">收益</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((v, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableCell} align="center">
                                            {moment(v.date * 1000).add(-1, "days").format("YYYY年MM月DD日")}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} align="center">
                                            {`${(v.cash || 0) / 100} 元`}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {loading?<CircularProgress />:<Typography align="center" gutterBottom variant="body1">
                            上拉加载更多
                        </Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        关闭
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default withMobileDialog()(withStyles(styles)(RewardHistory))
