import React, { useState, useEffect } from 'react';
//import { view as UserInfo } from '../modules/user/info/'
import {view as UserCoin} from '../modules/user/coin/'
import {view as UserToken} from '../modules/user/token/'
import Assets from '../modules/utils/displayAssets'
import Announce from '../modules/utils/announce'
import {WithdrawListItem as ListItem} from '../modules/utils/listItem'
import {view as NodeList} from '../modules/node/list/'
import {view as UserStore} from '../modules/user/store'
import {view as Invite} from '../modules/user/invite'
import Blue from "@material-ui/core/colors/blue";
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider'
import {fetchCertificationStatus, fetchCoin, fetchInfo, transBalance} from "../modules/user/actions";
import {confirm, messageError, messageOk, messageWarning} from "../modules/utils/actions";
import {connect} from "react-redux";
import {minerGet, bcGet} from "../modules/auth/actions";
import Typography from "@material-ui/core/Typography";
import RightArrowIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import {
  addCollectionMethod, addWithdrawRequest,
  checkWithdrawLog,
  getCash,
  getCollectionMethod, getOpenid,
  getWithdrawLogs, resetOpenid,
  setCollectionMethodType
} from "../modules/userMine/actions";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import {Title} from "../modules/utils/title";
import Tab from "../modules/utils/tab";
import Table from "../modules/utils/table";
import {AdvancedNodeListItem, LinkListItem, ActionListItem, InviteeListItem} from "../modules/utils/listItem";
import {reducerStatus} from "../modules/userMine/reducer";
import moment from "moment";
import {fetchAdvancedNodes} from "../modules/bc/actions";
import {cashToRMB} from "../modules/utils/functions";
import {AdvancedNodeDetail} from "../modules/utils/nodeDetail";

const useStyles = makeStyles(theme => ({
  paragraph: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  }
}));

function MyReward(props) {
  const classes = useStyles();

  const {myNodes, myNodesLoading, onRequire} = props;

  const [openedNodeInfo, setOpenedNodeInfo] = useState(null);

  useEffect(()=>{
    onRequire();
  }, []);

  const myAdvancedNodesTableParam = {
    list: myNodes.map(v => ({
      ...v,
      id: v.machine_id,
      name: v.name,
      online: v.online,
      approved: true, //目前不考虑是否验收通过
      rewardYesterday: cashToRMB(v.yesterday_reward),
      totalReward: cashToRMB(v.total_reward),
      waiting: (v.yesterday_reward||0) < 0
    })),
    loading: myNodesLoading,
    render: (v, i)=><AdvancedNodeListItem {...v} key={i} onClick={()=>setOpenedNodeInfo(v)} />
  };
  return (
    <div>
      <Title>优享节点</Title>

      <Table {...myAdvancedNodesTableParam}/>
      <Title>添加节点</Title>
      <Typography className={classes.paragraph} variant="body1">需要添加优享节点，请与工作人员联系，邮箱：shu@pear.hk</Typography>
      <AdvancedNodeDetail open={openedNodeInfo != null} onClose={()=>setOpenedNodeInfo(null)} nodeInfo={openedNodeInfo}/>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    myNodes: state.bc.advancedNodes || [],
    myNodesLoading: state.bc.getAdvancedNodesStatus === "loading",
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire : ()=>{
      dispatch(fetchAdvancedNodes());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyReward);
