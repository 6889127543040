import React from 'react'
import { view as LoginBox } from '../modules/auth/login/'

const Login = (props) => {
    return (
        <div className="Login">
            <LoginBox {...props} />
        </div>
    )
}

export default Login