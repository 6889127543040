import React from 'react'
import copy from "copy-to-clipboard/index";
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconInvite from '@material-ui/icons/GroupAdd';
import IconCopy from '@material-ui/icons/FileCopyOutlined';
import {messageOk} from "../../utils/actions";
import {connect} from "react-redux";
import classNames from "classnames";

const styles = theme => ({
    root: {
        // marginTop: theme.spacing( 2),
    },
    button: {
        width: '100%',
        size: 'medium',
    },
    input: {
        width: '100%',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalMiddle: {
        verticalAlign:'middle'
    }
});

class Invite extends React.Component {

    copyText(text) {
        copy(text);
        this.props.messageOk("复制成功");
    }

  render() {
    const inviteURL = 'https://i.openfogos.com?inviter=' + localStorage.getItem('openfogos_username');
    const {classes} = this.props;
    return (
        <div>
                <Typography gutterBottom variant="subtitle1">
                    <IconInvite className={classNames(classes.verticalMiddle, classes.leftIcon)}/><span className={classes.verticalMiddle}>邀请</span>
                </Typography>
                <Typography gutterBottom>
                    邀请好友注册并绑定设备，您将额外获得相当于被邀请节点共享计算收益 10% 的奖励：
                </Typography>
                <Grid container spacing={16}>
                    <Grid item xs={10} sm={7}>
                        <Input className={classes.button} id="inviteURL" readOnly defaultValue={inviteURL} />
                    </Grid>
                    <Grid item xs={2} sm={5}>
                        <IconCopy onClick={()=>this.copyText(inviteURL)}/>
                    </Grid>
                </Grid>
        </div>
    )
  }
}

Invite.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        messageOk: (dataStr) => {
            dispatch(messageOk(dataStr))
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Invite));
