import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { connect } from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {fetchRewardStatistics, fetchTop5} from "./actions";
import classNames from "classnames";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Blue from "@material-ui/core/colors/blue";
import {Link} from "react-router-dom";
import {cashToRMB, cashToRMBString} from "./functions";

const styles = theme => ({
    root: {
        margin: theme.spacing(2)
    },
    card: {
        minWidth: 275,
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    gap: {
        height:theme.spacing( 2),
    },
    tableCell: {
        paddingRight: '20px',
        paddingLeft: '0px',
        paddingTop: '5px',
        paddingBottom: '0px'
    },
    top1: {
        color: "#e40015",
        fontWeight: "bold"
    },
    top2: {
        color: "#eb8b00",
        fontWeight: "bold"
    },
    top3: {
        color: "#efdb00",
        fontWeight: "bold"
    },
    statistics: {
        marginTop: theme.spacing( 2),
        marginBottom: theme.spacing( 2
    )},
    aLink: {
        color: Blue[500],
    },
});

class Site extends React.Component {
    render() {
        const { classes } = this.props;
        const cups = {
            1: "goldenCup.svg",
            2: "silverCup.svg",
            3: "copperCup.svg"
        };
        return (
            <div className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            公告
                        </Typography>
                        <Typography variant="body1" paragraph>
                            2021年1月1日起，OpenFogOS官网将全面更新，主要更新内容如下：<br/><br/>
                            &emsp;1. 闲小节点收益将以人民币的形式发放，届时没有提取的 PRT 和 PRC 将自动转换成人民币余额。<br/>
                            &emsp;2. 增加“优享节点”模式，该模式下的收益在每月15日发放后有半个月的冻结期，次月1日后才可提取。<br/>
                            &emsp;3. 闲小节点、矿机节点、优享节点收益发放方式统一，每月15日发放上月收益（人民币形式）。<br/>
                            &emsp;4. 提现方式：1）提取到支付宝账号：自动扣除6%的税点，16-19日可发起提现申请，20-21日统一审批。2）发票对公转账：1-15日可发起申请，15日前将发票寄送到我司，发票经审核无误后，15日统一对公转账。
                        </Typography>
                <Typography variant="body1" paragraph>
                    <Link className={classes.aLink} to={"/home/doc/supporteddevices"} target={"_self"}>已适配设备列表</Link>
                </Typography>
                        <Typography variant="body2" align="right">
                            OpenFogOS 团队{<br/>}
                            2020年12月11日
                        </Typography>


                {/*<div className={classes.gap}></div>*/}
                {this.props.loading == "success"? <div className={classes.statistics}>
                        <div style={{ display: 'inline-block'}}>
                            <Typography variant="h6">
                                昨日发放数量：
                            </Typography>
                            <Typography variant="h6">
                                历史发放总量：
                            </Typography>
                        </div>
                        <div style={{ display: 'inline-block'}}>
                            <Typography variant="h6" align='right'>
                                {cashToRMBString((this.props.rewardStatistics.yesterday||0)/100)} 元
                            </Typography>
                            <Typography variant="h6" align='right'>
                                {cashToRMBString((this.props.rewardStatistics.total||0)/100)} 元
                            </Typography>
                        </div>
                    </div>:""}
                <Typography variant="subtitle1" align="center">
                    单台设备昨日收益排行
                </Typography>
                {((this.props.top5) && (this.props.top5[0].reward_yesterday < 0))?
                    <Typography variant="subheading" align="center">
                        结算中...
                    </Typography>:
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell} align="center">收益排名</TableCell>
                                <TableCell className={classes.tableCell} align="center">MAC</TableCell>
                                {/*<TableCell align="right">别名</TableCell>*/}
                                <TableCell className={classes.tableCell} align="center">用户</TableCell>
                                <TableCell className={classes.tableCell} align="center"><span style={{whiteSpace:'nowrap'}}>昨日收益</span></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.props.top5 || []).map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell} align="center">
                                        <Typography variant="subtitle1" className={classes['top' + (index + 1)]}>{index < 3?<embed src={cups[index + 1]} type="image/svg+xml" width="22" height="22"/>: index + 1}</Typography>
                                    </TableCell >
                                    <TableCell className={classes.tableCell} align="center">
                                        **{row.mac_addr.substr(3)}
                                    </TableCell >
                                    <TableCell className={classes.tableCell} align="center"><Typography noWrap >**{row.user_name.substr(2)}</Typography></TableCell>
                                    <TableCell className={classes.tableCell} align="center">{cashToRMB(row.reward_yesterday)} <Typography variant="caption" display="inline" >元</Typography></TableCell>
                                    {/*<TableCell align="right" className={classes.tableCell}>{row.storage_total ? (row.storage_total > 1024 * 1024 ? (row.storage_total / 1024 / 1024).toFixed(1) + 'TB' :(row.storage_total / 1024).toFixed(1) + 'GB') : '未知'}</TableCell>*/}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }



            </div>)
    }
    componentDidMount(){
        this.props.onRequire();
    }
}

Site.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
    return {
        rewardStatistics: state.utils.rewardStatistics,
        top5: state.utils.top5,
        loading: state.utils.rewardStatisticsStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire: () => {
            dispatch(fetchRewardStatistics())
            dispatch(fetchTop5())
        },
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Site));