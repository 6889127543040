import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

//系统盘制作教程

const Hints = () => {
    const contentMD = `## 系统盘制作教程
### 综述
> OpenFogOS系统镜像是Pear的OpenFogOS团队基于Ubuntu LTS官方最新版，借鉴腾讯TencentOS的部分内核优化方法，以及Google、阿里巴巴、华为等公司在大并发、低延迟、高吞吐互联网/电信业务的多年持续优化经验，深度定制的用于共享雾计算、软路由、NAS等场景的Linux系统镜像。计算、网络和I/O参数充分调优，并可根据硬件条件自适应到最佳状态。用此系统，资源贡献者可望跑出最佳的利用率，从而获得相应的高回报。

1. Linux下可通过 \`dd\` 命令制作U盘安装盘，用以系统安装
2. Windows下可用UltraISO刻录U盘安装盘，用以系统安装
3. Windows下亦可用DiskGenius，用还原分区镜像的方法直接生成系统盘
3. 下载地址
   - [ISO镜像](https://download.openfogos.com/pear-server/pear-server.iso) 
   - [DiskGenius分区镜像包](https://download.openfogos.com/pear-server/pear-server-diskgenius.zip)
### 方法一（Linux环境）：
1. 查看U盘设备号，假设是/dev/sdh

   \`\`\`
   lsblk 
   fdisk /dev/sdh
   \`\`\`
2. 下载所需ISO镜像到本地

    \`\`\`
    cd /tmp/
    wget https://download.openfogos.com/pear-server/pear-server.iso
    \`\`\`
3. 用dd命令将上述ISO镜像写到U盘设备

    \`\`\`
    sudo dd if=/tmp/pear-server.iso bs=1M of=/dev/sdh ; sync
    \`\`\`
4. 用所制作的系统安装U盘来安装系统盘，方法可参考[Ubuntu的安装过程](https://ubuntu.com/tutorials/tutorial-install-ubuntu-server#3-boot-from-install-media)

### 方法二（Windows环境）：
1. 下载安装[软碟通UltraISO](https://cn.ultraiso.net/)

1. 下载所需的ISO镜像文件：[pear-server.iso](https://download.openfogos.com/pear-server/pear-server.iso)

1. 启动UltraISO，点击菜单栏中的“文件”->“打开”，选择下载的镜像文件

1. 写入硬盘镜像

    - 菜单栏，“启动”->“写入硬盘镜像”，点击“写入方式”，选择 \`RAW\`
    
    - 检查硬盘驱动器中显示的驱动器是准备好的U盘

    ![UltraISO_11.png](/MakeSystemDisk/UltraISO_11.png)
1. 点击“写入”，等待写入完成

1. 用所制作的系统安装U盘来安装系统盘，方法可参考[Ubuntu的安装过程](https://ubuntu.com/tutorials/tutorial-install-ubuntu-server#3-boot-from-install-media)

### 方法三（Windows环境）：
1. 下载[DiskGenius分区镜像包](https://download.openfogos.com/pear-server/pear-server-diskgenius.zip)

2. 下载[DiskGenius](http://www.diskgenius.cn/download.php)软件，解压

3. 把需要制作的系统盘插在电脑上，打开DiskGenius，选中系统盘进行快速格式化

   ![系统盘快速分区.png](/MakeSystemDisk/fig1.png)
4. 如图所示进行勾选

   ![快速分区选项.png](/MakeSystemDisk/fig2.png)
5. 从步骤一中下载的镜像文件依次还原ESP分区、分区一

   ![还原.png](/MakeSystemDisk/fig3.png)
   ![进行还原.png](/MakeSystemDisk/fig4.png)
6. 等待还原完成

   ![还原完成.png](/MakeSystemDisk/fig5.png)
   ![全部完成.png](/MakeSystemDisk/fig6.png)
7. 将制作好的系统盘安装到机器上，开机

   默认用户名： \`pear\`

   默认密码  ： \`123456\`
    `;

    return (
        <div className="Advice" >
            <ReactMarkdown source={contentMD}/>
        </div>
    )
}

export default Hints;