import React from 'react';
import View from '../modules/utils/largeRecruit';


const Page = (props) => {
    return (
        <div>
            <View {...props}/>
        </div>)
};

export default Page;
