import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {fetchCoin, fetchInfo} from '../actions';
import {messageOk, messageWarning} from "../../utils/actions";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Wallet from '@material-ui/icons/AccountBalanceWalletOutlined';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import IconWithdraw from '@material-ui/icons/Payment';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        minWidth: 275,
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalMiddle: {
        verticalAlign:'middle'
    }
});

class UserCoin extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.withdrawStatusOnchange = this.withdrawStatusOnchange.bind(this);
      this.hideWithdrawModal = this.hideWithdrawModal.bind(this);
      this.showWithdrawModal = this.showWithdrawModal.bind(this);
      this.onWithdraw = this.onWithdraw.bind(this);
      this.showWithdrawConfirm = this.showWithdrawConfirm.bind(this);
      this.hideWithdrawConfirm = this.hideWithdrawConfirm.bind(this);
      this.showTransBalanceConfirm = this.showTransBalanceConfirm.bind(this);
      this.hideTransBalanceConfirm = this.hideTransBalanceConfirm.bind(this);
    this.state = {
      withdrawVisible: false,
      withdrawBalance: 0,
        openDialogTransBalance: false,
        openDialogWithdrawBalance: false,
        withdrawConfirmVisible: false,
        transBalanceConfirmVisible: false
    }
  }

  showWithdrawModal() {
    this.setState({
      withdrawVisible: true,
      // withdrawBalance: Math.min(Math.floor(this.props.balance) / 100, 20)
        withdrawBalance: 1
    })
  }
  hideWithdrawModal() {
    this.setState({
      withdrawVisible: false
    })
  }
    showWithdrawConfirm() {
        this.setState({
            withdrawConfirmVisible: true
        })
    }
    hideWithdrawConfirm() {
        this.setState({
            withdrawConfirmVisible: false
        })
    }
    showTransBalanceConfirm() {
        this.setState({
            transBalanceConfirmVisible: true
        })
    }
    hideTransBalanceConfirm() {
        this.setState({
            transBalanceConfirmVisible: false
        })
    }
  withdrawStatusOnchange(event) {
        this.setState({
            withdrawBalance: event.target.value
        })
  }

  onWithdraw() {
    let withdrawBalance = Number(this.state.withdrawBalance);
    if(!withdrawBalance){
          this.props.messageWarning("请输入正确的金额！");
          return;
      }
      if(withdrawBalance > Math.min(Math.floor(this.props.balance) / 100, 20)){
          withdrawBalance = Math.min(Math.floor(this.props.balance) / 100, 20);
      }
      if(withdrawBalance < 1){
          withdrawBalance = 1;
      }
      this.setState({
          withdrawBalance: withdrawBalance
      });
    this.hideWithdrawModal();
    this.showWithdrawConfirm();
  }

  render() {
    const { classes } = this.props;
    const coin2Balance = this.props.coin.coin2balance? this.props.coin.coin2balance : 0.00
    const balance2Cash = this.props.coin.balance2cash? this.props.coin.balance2cash : 0.00
    const balance2get = Math.floor(this.props.have * coin2Balance)
    const inputParam = {
      readOnly: true,
      size: 'large',
        style: { width: '100%' },

    }

    const buttonWithdrawParam = {
      variant: 'contained',
        color:'primary',
      size: 'medium',
      style: { width: '100%' },
      disabled: this.props.balance * balance2Cash >= 100 && !this.props.withdrewToday ? false : true,
      onClick: () => this.showWithdrawModal()
    }

    const balance2show = (Math.floor(this.props.balance) / 100).toFixed(2);
    const withdrawTooltip = this.props.withdrewToday? "今日提现次数已用完": "";
    return (
      <div>
              <Typography variant="subtitle1">
                  <Wallet className={classNames(classes.verticalMiddle, classes.leftIcon)}/><span className={classes.verticalMiddle}>钱包</span>
              </Typography>
              <Typography variant="body2">
                  PRC余额（{1 / balance2Cash} PRC = {balance2Cash} RMB）<Tooltip title="PRC(Pear Coin)余额超过1元才可提现，每天最多可提现一次，每次最多提取20"><HelpIcon color="action" style={{ fontSize: 20 }}/></Tooltip>
              </Typography>
                  <Grid container spacing={16}>
                      <Grid item xs={7}>
                          <Input value={balance2show} suffix="￥" {...inputParam} />
                      </Grid>
                      <Grid item xs={5}>
                          <Tooltip title={withdrawTooltip} >
                              <Button {...buttonWithdrawParam} >
                                  <IconWithdraw className={classes.leftIcon}/>提现
                              </Button>
                          </Tooltip>
                      </Grid>
                  </Grid>

        <Dialog open={this.state.withdrawVisible}>
            <DialogTitle>请输入提现金额（PRC）</DialogTitle>
            <DialogContent>
                <Input value={this.state.withdrawBalance} onChange={this.withdrawStatusOnchange}/>
                PRC (每天可提取一次，每次最多可提取20)
            </DialogContent>
            <DialogActions>
                <Button onClick={this.hideWithdrawModal} color="primary">
                    取消
                </Button>
                <Button onClick={this.onWithdraw} color="primary" autoFocus>
                    确定
                </Button>
            </DialogActions>
        </Dialog>

          <Dialog open={this.state.withdrawConfirmVisible}>
              <DialogTitle>提现</DialogTitle>
              <DialogContent>
                  {`确认提取 ${this.state.withdrawBalance} RPC为 ${(this.state.withdrawBalance * this.props.coin.balance2cash).toFixed(2)} 元人民币?`}
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.hideWithdrawConfirm} color="primary">
                      取消
                  </Button>
                  <Button color="primary" autoFocus onClick={
                    () => {
                      this.props.history.push({pathname: "/home/withdraw", state: {userId: localStorage.getItem('openfogos_userid'), balance: Math.floor(this.state.withdrawBalance * 100)}})
                  }}>
                      确定
                  </Button>
              </DialogActions>
          </Dialog>
      </div>
    )
  }
  componentDidMount() {
    this.props.onRequire()
  }
}

UserCoin.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    have: state.user.info.pear_coin,
    balance: state.user.info.balance,
      withdrewToday: state.user.info.withdrew_today,
    coin: state.user.coin,
    fetchLoading: (state.user.coinStatus === 'loading') ? true : false,
    submitLoading: (state.user.transCoinStatus === 'loading') ? true : false
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(fetchCoin({}))
      // dispatch(fetchTrafficYesterday({}))
      dispatch(fetchInfo({}))
    },
      messageOk: (dataStr) => {
        dispatch(messageOk(dataStr));
      },
      messageWarning: (dataStr) => {
          dispatch(messageWarning(dataStr));
      }
  }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserCoin)));
