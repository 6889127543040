import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import {
    fetchBCBW,
    fetchBCNodes,
    setLimit
} from '../actions'
import copy from 'copy-to-clipboard';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Edit from '@material-ui/icons/Edit';
import Power from '@material-ui/icons/PowerTwoTone';
import PowerOff from '@material-ui/icons/PowerOffTwoTone';
import SmileIcon from '@material-ui/icons/SentimentVerySatisfied';
import SadIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import IconList from '@material-ui/icons/List';
import moment from 'moment';
import {messageOk, messageError} from "../../utils/actions";
import classNames from "classnames";
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import AreaChart from 'recharts/lib/chart/AreaChart';
import Area from 'recharts/lib/cartesian/Area';
import ChartIcon from '@material-ui/icons/ShowChart';
import {CSVLink, CSVDownload} from "react-csv";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconCheck from '@material-ui/icons/Check';
import IconBack from '@material-ui/icons/ArrowBack';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import IconClose from '@material-ui/icons/Close';
import blue from '@material-ui/core/colors/blue';
import IconNetwork from '@material-ui/icons/SwapVert';
import IconMemory from '@material-ui/icons/Memory';
import IconStorage from '@material-ui/icons/SaveOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Slider from '@material-ui/lab/Slider';

import echarts from 'echarts/lib/echarts';
// 引入柱状图
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import withMobileDialog from "@material-ui/core/withMobileDialog/index";


//const Search = Input.Search

const styles = theme => ({
    root: {
        // overflowX: 'auto',
    },
    table: {
        overflowX: 'auto',
    },
    tableCell: {
        paddingRight: '20px',
        paddingLeft: '0px',
        paddingTop: '5px',
        paddingBottom: '0px'
    },
    leftButton: {
        marginRight: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    downloadIcon: {
        marginLeft: theme.spacing(1),
        marginBottom: "7px"
    },
    verticalMiddle: {
        verticalAlign: 'middle'
    },
    verticalBottom: {
        verticalAlign: 'bottom'
    },
    power: {
        // color: '#a68540',
        // color: green['A700']
    },
    mac: {
        // color: '#898989',
        marginTop: '4px'
    },
    golden: {
        color: '#a68540',
    },
    idealRewardButton: {
        cursor: 'pointer',
        border:"1px solid",
        padding:"5px",
        borderRadius:"3px"
    },
    spanButton: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        color: blue[500]
    },
    sliderItem: {
        marginTop: theme.spacing( 1),
        marginBottom: theme.spacing( 3),
    },
    slider: {
        marginTop: theme.spacing( 2),
    },
    setModeButton: {
        marginTop: theme.spacing( 2),
        marginRight: theme.spacing(1),
    },
    bottomButton: {
        marginTop: theme.spacing( 3),
    },
});

class NodeList extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
        this.openNodeDetail = this.openNodeDetail.bind(this);
        this.closeNodeDetail = this.closeNodeDetail.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
        this.handleSetMode = this.handleSetMode.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.setLimitNow = this.setLimitNow.bind(this);
        this.state = {
            rowsPerPage: 5,
            page: 0,
            nodeDetailOpen: false,
            nodeDetailIndex: 0,
            nodeLimit: {},
            timer: 0,
        }
    }

    handleChangePage(event, newPage) {
        this.setState({
            ...this.state,
            page: newPage
        });
    }

    handleChangeRowsPerPage(event) {
        this.setState({
            ...this.state,
            rowsPerPage: event.target.value
        });
    }
    handleSliderChange = (name) => (event, value) => {
        console.log('target', event.target);
        this.setState({
            nodeLimit: {
                ...this.state.nodeLimit,
                [name]:value
            }
        });
        this.setLimit();
    };
    handleTextFieldChange = (name) => (event) => {
        this.setState({
            nodeLimit: {
                ...this.state.nodeLimit,
                [name]:event.target.value
            }
        });
        this.setLimit();
    };

    handleSetMode = (percent) => () => {
        this.setState({
            nodeLimit: {
                ...this.state.nodeLimit,
                storage:percent,
                bandwidth:percent,
                memory:percent,
            }
        });
        this.setLimit();
    }

    setLimit = ()=> {
        // window.clearTimeout(this.state.timer);
        // this.setState({
        //     timer: window.setTimeout(()=>this.props.setLimit(this.state.nodeLimit),1000)
        // });

    };

    setLimitNow = ()=> {
        this.props.setLimit(this.state.nodeLimit);
    };

    openNodeDetail = (index, item) => () => {
        this.setState({
            nodeDetailIndex: index,
            nodeDetailOpen: true,
            nodeLimit: {
                mac_addr: item.mac_addr,
                storage: item.limit_storage||0,
                bandwidth: item.limit_bandwidth||0,
                memory: item.limit_memory||0,
                cpu: item.limit_cpu||0,
            },
        })
    };
    closeNodeDetail = () => {
        this.setState({
            nodeDetailOpen: false
        })
    };


    render() {
        const {classes, bw, nodes} = this.props;
        const today = moment().format('YYYYMMDD');
        console.log("today", today);

        var routerBW = (bw.router||[]).map((value, index) => ({
            name: moment(index * 300000 + 1553616000000).format('HH:mm'),
            BW: value * 1024 * 8 / (1000 * 1000 * 300),
        }));
        var serverBW = (bw.server||[]).map((value, index) => ({
            name: moment(index * 300000 + 1553616000000).format('HH:mm'),
            BW: value * 1024 * 8 / (1000 * 1000 * 300),
        }));
        const rowsPerPageOptionsOrigin = [5, 10, 25, 50];
        let rowsPerPageOptions = [];
        rowsPerPageOptionsOrigin.map(value => {
            if (value < this.props.nodes.length) {
                rowsPerPageOptions.push(value)
            }
        });
        rowsPerPageOptions.push(this.props.nodes.length);

        const modeIndex = this.state.nodeLimit.storage === this.state.nodeLimit.bandwidth && this.state.nodeLimit.storage === this.state.nodeLimit.memory? this.state.nodeLimit.storage:0;

        var routerNodes = (nodes||[]).filter(node=>node.type === "router");
        var serverNodes = (nodes||[]).filter(node=>node.type === "server");
        var okRouters = [];
        var badRouters = [];
        var offlineRouters = [];
        var okServers = [];
        var badServers = [];
        var offlineServers = [];
        (routerNodes || []).forEach((node) => {
            if (node.online) {
                if (node.error_code) {
                    badRouters.push(node);
                } else {
                    okRouters.push(node);
                }

            } else {
                offlineRouters.push(node);
            }
        });
        (serverNodes || []).forEach((node) => {
            if (node.online) {
                if (node.error_code) {
                    badServers.push(node);
                } else {
                    okServers.push(node);
                }

            } else {
                offlineServers.push(node);
            }
        });
        var bcNodeStatistics = [
            {
                name: '路由器',
                length: routerNodes.length,
                ok: okRouters,
                bad: badRouters,
                offline: offlineRouters
            },
            {
                name: 'x86服务器',
                length: serverNodes.length,
                ok: okServers,
                bad: badServers,
                offline: offlineServers
            }
        ];

        const csvHeaders = [
            {label: "MAC地址", key: "mac_addr"},
            {label: "公网IP", key: "public_ip"},
            {label: "省份", key: "province"},
            {label: "运营商", key: "isp"},
            {label: "硬盘大小（MB）", key: "storage_total"},
            {label: "剩余空间（MB）", key: "storage_avail"},
            {label: "上行带宽（Mbps）", key: "upload_bw"},
            {label: "故障码", key: "error_code"},
        ];
        const nodeDetail =<Dialog maxWidth="md"  fullScreen={this.props.fullScreen} open={this.state.nodeDetailOpen} onClose={this.closeNodeDetail}>

            <div className={classes.dialogTitle}>
                <IconButton color="primary" onClick={this.closeNodeDetail}>
                    <IconBack />
                </IconButton>
                <Typography display="inline" className={classes.verticalMiddle} variant="h6">节点信息</Typography>
            </div>

            <DialogContent className={this.props.fullScreen?"":classes.dialogContent}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left" className={classes.tableCell}>公网IP</TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                {((this.props.nodes[this.state.nodeDetailIndex]||{}).public_ip||"")}
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                {(this.props.nodes[this.state.nodeDetailIndex]||{}).http == 1?<IconCheck color="primary"/>:null}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" className={classes.tableCell}>可用空间</TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                {(((this.props.nodes[this.state.nodeDetailIndex]||{}).storage_avail||0) / 1024).toFixed(2)}
                                <Typography display="inline" noWrap color="textSecondary" variant="caption" className={classes.mac}> GB</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                {(((this.props.nodes[this.state.nodeDetailIndex]||{}).storage_avail||0) / 1024) >= 500?<IconCheck color="primary"/>:
                                    '建议共享500G以上的闲置空间'}
                            </TableCell>
                        </TableRow>


                        <TableRow>
                            <TableCell align="left" className={classes.tableCell}>上行带宽</TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                {((this.props.nodes[this.state.nodeDetailIndex]||{}).upload_bw||0).toFixed(2)}
                                <Typography display="inline" noWrap color="textSecondary" variant="caption" className={classes.mac}> Mbps</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                {((this.props.nodes[this.state.nodeDetailIndex]||{}).upload_bw||0) >= 100?<IconCheck color="primary"/>:
                                    <Link to="/home/advice/bandwidth" className={classes.aLink}>优化方案</Link>}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" className={classes.tableCell}>运营商</TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                {(this.props.nodes[this.state.nodeDetailIndex]||{}).isp||""}
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                                {(this.props.nodes[this.state.nodeDetailIndex]||{}).isp == "电信"?<IconCheck color="primary"/>:'一般而言：电信 > 联通 > 移动'}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Button onClick={this.handleSetMode(100)} color={modeIndex == 100?'primary':'default'} variant={modeIndex == 100?'contained':'outlined'}  size="small" className={classes.setModeButton}>高收益模式</Button>
                <Button onClick={this.handleSetMode(80)} color={modeIndex == 80?'primary':'default'} variant={modeIndex == 80?'contained':'outlined'} size="small" className={classes.setModeButton}>保守模式</Button>
                <Button onClick={this.handleSetMode(60)} color={modeIndex == 60?'primary':'default'} variant={modeIndex == 60?'contained':'outlined'} size="small" className={classes.setModeButton}>低收益模式</Button>
                <div className={classes.sliderItem}>
                    <Typography id="storage-label" variant="body1" className={classes.verticalMiddle} gutterBottom><IconStorage className={classes.verticalMiddle}/> 分配存储：{this.state.nodeLimit.storage == 100? '默认':this.state.nodeLimit.storage + '%'}</Typography>
                    <Slider
                        min={60}
                        max={100}
                        step={1}
                        classes={{ container: classes.slider }}
                        className={classes.slider}
                        value={this.state.nodeLimit.storage}
                        aria-labelledby="storage-label"
                        onChange={this.handleSliderChange('storage')}
                    />
                </div>
                <div className={classes.sliderItem}>
                    <Typography id="bandwidth-label" variant="body1" className={classes.verticalMiddle}><IconNetwork className={classes.verticalMiddle}/> 分配带宽：{this.state.nodeLimit.bandwidth == 100? '默认':this.state.nodeLimit.bandwidth + '%'}</Typography>
                    <Slider
                        min={60}
                        max={100}
                        step={1}
                        classes={{ container: classes.slider }}
                        className={classes.slider}
                        value={this.state.nodeLimit.bandwidth}
                        aria-labelledby="bandwidth-label"
                        onChange={this.handleSliderChange('bandwidth')}
                    />
                </div>
                <div className={classes.sliderItem}>
                    <Typography variant="body1" className={classes.verticalMiddle}><embed className={classes.verticalMiddle} src="/memory.svg" type="image/svg+xml" width="24" height="24" style={{marginRight:"0px"}}/> 分配内存：{this.state.nodeLimit.memory == 100? '默认':this.state.nodeLimit.memory + '%'}</Typography>
                    <Slider
                        min={60}
                        max={100}
                        step={1}
                        classes={{ container: classes.slider }}
                        className={classes.slider}
                        value={this.state.nodeLimit.memory}
                        onChange={this.handleSliderChange('memory')}
                    />
                </div>



                <Typography variant="body1" className={classes.verticalMiddle}><IconMemory className={classes.verticalMiddle}/> CPU核数：</Typography>
                <FormControl margin="normal" fullWidth>
                    <Select
                        value={this.state.nodeLimit.cpu}
                        onChange={this.handleTextFieldChange('cpu')}
                        inputProps={{
                            name: 'age',
                            id: 'age-simple',
                        }}
                    >
                        <MenuItem value={0}>auto</MenuItem>
                        {((this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0) > 2?
                            <MenuItem value={2}>2</MenuItem>: null
                        }
                        {((this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0) > 4?
                            <MenuItem value={4}>4</MenuItem>: null
                        }
                        {((this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0) > 8?
                            <MenuItem value={8}>8</MenuItem>: null
                        }
                        {((this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0) > 12?
                            <MenuItem value={12}>12</MenuItem>: null
                        }
                        {((this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0) > 16?
                            <MenuItem value={16}>16</MenuItem>: null
                        }
                        {((this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0) > 24?
                            <MenuItem value={24}>24</MenuItem>: null
                        }
                        {((this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0) > 32?
                            <MenuItem value={32}>32</MenuItem>: null
                        }
                        {((this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0) > 64?
                            <MenuItem value={64}>64</MenuItem>: null
                        }
                        {((this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0) > 0?
                            <MenuItem value={(this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0}>{(this.props.nodes[this.state.nodeDetailIndex]||{}).cpu_cores||0}</MenuItem>:null
                        }
                    </Select>
                </FormControl>
                <Typography align="center" gutterBottom>
                    <Button color="primary" size="small" variant="contained" onClick={()=>this.setLimitNow()} className={classes.bottomButton}>保存修改</Button>
                </Typography>

                <Typography align="center">
                    <IconButton className={classes.closeButton} onClick={this.closeNodeDetail}>
                        <IconClose fontSize="large" color="primary"/>
                    </IconButton>
                </Typography>


            </DialogContent>

        </Dialog>;

        return (
            <div className={classes.root}>
                <Typography gutterBottom variant='h6'>
                    <IconList className={classNames(classes.verticalMiddle, classes.leftIcon)}/><span
                    className={classes.verticalMiddle}>节点状态统计</span>
                </Typography>
                <div className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>平台号</TableCell>
                                <TableCell className={classes.tableCell} align="right">节点数</TableCell>
                                <TableCell className={classes.tableCell} align="right">正常</TableCell>
                                <TableCell className={classes.tableCell} align="right">异常</TableCell>
                                <TableCell className={classes.tableCell} align="right">离线</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {(bcNodeStatistics || []).map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCell} align="left">
                                        <Typography variant="caption">{row.name}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="right">
                                        {row.length}
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableCell, classes.verticalMiddle)}
                                               align="right">{row.ok.length}<CSVLink
                                        headers={csvHeaders} data={row.ok} filename={row.name + "-正常节点.csv"}><DownloadIcon
                                        className={classNames(classes.golden, classes.verticalMiddle, classes.downloadIcon)}/></CSVLink></TableCell>
                                    <TableCell className={classes.tableCell}
                                               align="right">{row.bad.length}<CSVLink
                                        headers={csvHeaders} data={row.bad}
                                        filename={row.name + "-异常节点.csv"}><DownloadIcon
                                        className={classNames(classes.golden, classes.verticalMiddle, classes.downloadIcon)}/></CSVLink></TableCell>
                                    <TableCell className={classes.tableCell}
                                               align="right">{row.offline.length}<CSVLink
                                        headers={csvHeaders} data={row.offline}
                                        filename={row.name + "-离线节点.csv"}><DownloadIcon
                                        className={classNames(classes.golden, classes.verticalMiddle, classes.downloadIcon)}/></CSVLink></TableCell>
                                    {/*<TableCell align="right" className={classes.tableCell}>{row.storage_total ? (row.storage_total > 1024 * 1024 ? (row.storage_total / 1024 / 1024).toFixed(1) + 'TB' :(row.storage_total / 1024).toFixed(1) + 'GB') : '未知'}</TableCell>*/}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>


                <Typography gutterBottom variant='h6' style={{marginTop: "20px"}}>
                    <ChartIcon className={classNames(classes.verticalMiddle, classes.leftIcon)}/><span
                    className={classes.verticalMiddle}>当天带宽统计</span>
                </Typography>

                <Typography gutterBottom variant='subtitle1'>
                    路由器节点 Mbps
                </Typography>
                <ResponsiveContainer width="100%" height={320}>
                    <AreaChart data={routerBW}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="10%" stopColor="#a68540" stopOpacity={0.8}/>
                                <stop offset="100%" stopColor="#a68540" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <CartesianGrid vertical={false} strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Area type="monotone" dataKey="BW" stroke="#a68540" fillOpacity={1} fill="url(#colorUv)"/>
                    </AreaChart>
                </ResponsiveContainer>

                <Typography gutterBottom variant='subtitle1'>
                    x86服务器节点 Mbps
                </Typography>
                <ResponsiveContainer width="100%" height={320}>
                    <AreaChart data={serverBW}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="10%" stopColor="#a68540" stopOpacity={0.8}/>
                                <stop offset="100%" stopColor="#a68540" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <CartesianGrid vertical={false} strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Area type="monotone" dataKey="BW" stroke="#a68540" fillOpacity={1} fill="url(#colorUv)"/>
                    </AreaChart>
                </ResponsiveContainer>

                <div className={classes.table} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableCell}>节点</TableCell>
                                {/*<TableCell align="right">别名</TableCell>*/}
                                <TableCell className={classes.tableCell} align="right">状态</TableCell>
                                <TableCell className={classes.tableCell} align="right"><span style={{whiteSpace:'nowrap'}}>操作</span></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.nodes.slice(this.state.rowsPerPage * this.state.page, this.state.rowsPerPage * (this.state.page + 1)).map((row, index) => (
                                <TableRow key={row.mac_addr}>
                                    <TableCell className={classes.tableCell}>
                                        {row.mac_addr}
                                    </TableCell >
                                    <TableCell align="right" className={classes.tableCell}><Typography noWrap >{row.online? <SmileIcon className={classes.power} color="primary"/>:<SadIcon />}</Typography></TableCell>
                                    {/*<TableCell align="right" className={classes.tableCell}><Typography noWrap >{row.online? "在线":"离线"}</Typography></TableCell>*/}
                                    <TableCell align="right" className={classNames(classes.tableCell, classes.idealReward)}>
                                  <span onClick={this.openNodeDetail(index, row)} className={classes.spanButton}>配置</span>

                                    </TableCell>
                                    {/*<TableCell align="right" className={classes.tableCell}>{row.storage_total ? (row.storage_total > 1024 * 1024 ? (row.storage_total / 1024 / 1024).toFixed(1) + 'TB' :(row.storage_total / 1024).toFixed(1) + 'GB') : '未知'}</TableCell>*/}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    style={this.props.nodes.length <= 5? {display: 'none'}:{}}
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={this.props.nodes.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    labelRowsPerPage={"每页行数："}
                    // labelDisplayedRows={({ from, to, count }) => `总数：${count}，当前：${from}-${to}`}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
                />
                {nodeDetail}
            </div>
        )
    }

    componentDidMount() {
        console.log("componentDidMount");
        this.props.onRequire(this.state.page + 1, this.state.rowsPerPage);
    }

    componentDidUpdate() {
        console.log("componentDidUpdate");
        // this.props.onUpdate(this.props.platformMacs);
    }
}

NodeList.propTypes = {
    classes: PropTypes.object.isRequired,
};

NodeList.defaultProps = {
    bw: {},
    nodes: []
}

const mapStateToProps = (state) => {
    return {
        bw: state.bc.bw,
        nodes: state.bc.nodes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire: (page, pageLength) => {
            dispatch(fetchBCBW());
            dispatch(fetchBCNodes());
        },
        messageOk: (str) => {
            dispatch(messageOk(str));
        },
        setLimit: (data) => {
            dispatch(setLimit({data,
                success: () => {
                    dispatch(messageOk("修改成功"));
                    dispatch(fetchBCBW());
                    dispatch(fetchBCNodes());
                },
                error: () => {
                    dispatch(messageError('修改配置失败'));
                }
            }));
        }

    }
}

export default withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeList)))
