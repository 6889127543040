import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {fetchCoin, fetchInfo, transBalance} from '../actions';
import {messageOk, messageWarning} from "../../utils/actions";
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/HelpOutline';
import AccountBalance from '@material-ui/icons/AccountBalance';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import IconPRC from '@material-ui/icons/LocalAtm';
import classNames from 'classnames';

const styles = theme => ({
    root: {
        minWidth: 275,
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalMiddle: {
        verticalAlign:'middle'
    },
    goldenText: {
        color: '#a68540',
        // color: '#cebf74',
        // color: '#cfae51'
    },
    accountCard: {
        backgroundImage: 'url(/card.png)',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        height: '220px',//220
        maxWidth: '373px',//349
      marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '24px',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
    },
    cardContent: {
        padding: theme.spacing(2),
    }

});

class UserToken extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.withdrawStatusOnchange = this.withdrawStatusOnchange.bind(this);
      this.hideWithdrawModal = this.hideWithdrawModal.bind(this);
      this.showTransBalance = this.showTransBalance.bind(this);
      this.hideTransBalance = this.hideTransBalance.bind(this);
      this.showTransBalanceConfirm = this.showTransBalanceConfirm.bind(this);
      this.hideTransBalanceConfirm = this.hideTransBalanceConfirm.bind(this);
      this.onTransBalance = this.onTransBalance.bind(this);
      this.coinToConvertOnchange = this.coinToConvertOnchange.bind(this);
    this.state = {
      withdrawVisible: false,
      withdrawBalance: 0,
        coinToConvert: 100,
        openDialogTransBalance: false,
        openDialogWithdrawBalance: false,
        withdrawConfirmVisible: false,
        transBalanceVisible: false,
        transBalanceConfirmVisible: false
    }
  }

  hideWithdrawModal() {
    this.setState({
      withdrawVisible: false
    })
  }
    showTransBalance() {
        this.setState({
            transBalanceVisible: true
        })
    }
    hideTransBalance() {
        this.setState({
            transBalanceVisible: false
        })
    }
    showTransBalanceConfirm() {
        this.setState({
            transBalanceConfirmVisible: true
        })
    }
    hideTransBalanceConfirm() {
        this.setState({
            transBalanceConfirmVisible: false
        })
    }
    onTransBalance() {
        let coinToConvert = Number(this.state.coinToConvert);
        if(!coinToConvert){
            this.props.messageWarning("请输入正确的数量！");
            return;
        }
        if(coinToConvert > this.props.have){
            coinToConvert = this.props.have;
        }
        if(coinToConvert < 100){
            coinToConvert = 100;
        }
        this.setState({
            coinToConvert: coinToConvert
        });
        this.hideTransBalance();
        this.showTransBalanceConfirm();
    }
  withdrawStatusOnchange(event) {
        this.setState({
            withdrawBalance: event.target.value
        })
  }
    coinToConvertOnchange(event) {
        this.setState({
            coinToConvert: event.target.value
        })
    }


  render() {
    const { classes } = this.props;
    const coin2Balance = this.props.coin.coin2balance? this.props.coin.coin2balance : 0.00
    const balance2get = Math.floor(this.state.coinToConvert * coin2Balance)
    const inputParam = {
      readOnly: true,
      size: 'large',
        style: { width: '100%' },

    }
    const buttonConvertBalanceParam = {
        variant: 'contained',
        color:'primary',
      size: 'medium',
      onClick: this.showTransBalance,
      style: { background: 'linear-gradient(135deg,  #E9C85F, #cfae51, #a68540)' },//#cfae51,
      disabled: (!this.props.have || this.props.have * coin2Balance < 1) ? true : false
    }

    const tooltip1 = "PRT超过" + Math.ceil(1/coin2Balance) + "才可兑换PRC"
    const coin2show = this.props.have || 0;
    return (
        <div>
            <div className={classes.accountCard}>
                <div className={classes.cardContent}>
                    <Typography variant="h6" color="primary">
                        <AccountBalance className={classNames(classes.verticalMiddle, classes.leftIcon, classes.goldenText)} style={{fontSize: 30}}/><span className={classNames(classes.verticalMiddle, classes.goldenText)}>收益银行</span>
                    </Typography>
                    <div style={{padding: '10px'}}></div>
                    <Typography variant="h4" color="primary" className={classes.goldenText} display="inline">
                        {coin2show.toLocaleString()}
                    </Typography>
                    <Typography variant="subtitle1" color="primary" className={classes.goldenText} display="inline">
                        &ensp;PRT
                    </Typography>
                    <br/>
                    <Typography variant="h5" color="primary" className={classes.goldenText} display="inline" >
                        {Number((this.props.balance / 100).toFixed(2)).toLocaleString()}
                    </Typography>
                    <Typography variant="subtitle1" color="primary" className={classes.goldenText} display="inline" >
                        &ensp;PRC
                    </Typography>
                    <Typography variant="body2" align='right' gutterBottom>
                        <Button {...buttonConvertBalanceParam} ><IconPRC className={classes.leftIcon}/>兑换</Button>
                    </Typography>
                    <Typography variant="body2" className={classes.goldenText} align='right' color="primary">
                        {(1 / (coin2Balance * 0.01)).toLocaleString()} PRT = 1 PRC
                    </Typography>
                </div>
            </div>

                {/*<CardContent>
                    <Typography variant="subtitle1">
                        <AccountBalance className={classNames(classes.verticalMiddle, classes.leftIcon)}/><span className={classes.verticalMiddle}>收益</span>
                    </Typography>
                    <Typography variant="body2">
                        PRT余额（{1 / (coin2Balance * 0.01)} PRT = 1 PRC）<Tooltip title={tooltip1}><HelpIcon color="action" style={{ fontSize: 20 }}/></Tooltip>
                    </Typography>
                    <Grid container spacing={16}>
                        <Grid item xs={7}>
                            <Input value={coin2show} suffix="￥" {...inputParam} />
                        </Grid>
                        <Grid item xs={5}>
                            <Button {...buttonConvertBalanceParam} ><IconPRC className={classes.leftIcon}/>兑换</Button>
                        </Grid>
                    </Grid>
                </CardContent>*/}

                <Dialog open={this.state.transBalanceVisible}>
                    <DialogTitle>请输入兑换金额（PRT，不小于100）</DialogTitle>
                    <DialogContent>
                        <Input value={this.state.coinToConvert.to} onChange={this.coinToConvertOnchange}/>
                        PRT
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.hideTransBalance} color="primary">
                            取消
                        </Button>
                        <Button onClick={this.onTransBalance} color="primary" autoFocus>
                            确定
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.transBalanceConfirmVisible}>
                    <DialogTitle>确定兑换</DialogTitle>
                    <DialogContent>
                        {`兑换 ${this.state.coinToConvert.toLocaleString()} PRT为 ${balance2get * 0.01.toFixed(2)} PRC?`}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.hideTransBalanceConfirm} color="primary">
                            取消
                        </Button>
                        <Button color="primary" autoFocus onClick={
                            () => {this.props.onTransBalance(this.state.coinToConvert, balance2get);
                                this.hideTransBalanceConfirm();}}>
                            确定
                        </Button>
                    </DialogActions>
                </Dialog>

        </div>

    )
  }
  componentDidMount() {
    this.props.onRequire()
  }
}

UserToken.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    have: state.user.info.pear_coin,
    balance: state.user.info.balance,
    coin: state.user.coin,
    fetchLoading: (state.user.coinStatus === 'loading') ? true : false,
    submitLoading: (state.user.transCoinStatus === 'loading') ? true : false
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(fetchCoin({}))
      // dispatch(fetchTrafficYesterday({}))
      dispatch(fetchInfo({}))
    },
    onTransBalance: (coinToConvert, balance2get) => {
      dispatch(transBalance({
        data: {
            coinToConvert: coinToConvert,
            balanceToGet: balance2get
        },
        success: () => {
            dispatch(messageOk('兑换成功'));
        }
      }))
    },
      messageOk: (dataStr) => {
        dispatch(messageOk(dataStr));
      },
      messageWarning: (dataStr) => {
          dispatch(messageWarning(dataStr));
      }
  }
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserToken)));
