import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SiteContent from '../modules/utils/site';

const styles = {
    card: {
        minWidth: 275,
        maxWidth: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
};

const Site = (props) => {
    const { classes } = props;
    return (
        <div>
            <SiteContent/>

        </div>)};
Site.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Site);
