import React from 'react';
//import { view as UserInfo } from '../modules/user/info/'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Blue from "@material-ui/core/colors/blue";
import ReactMarkdown from 'react-markdown';


const styles = theme => ({
    root: {
        padding: theme.spacing(2),
    },
    gap: {
        height:theme.spacing( 2),
    },
    divider: {
        marginTop:theme.spacing( 2),
        marginBottom:theme.spacing( 2
    )},
    aLink: {
        color: Blue[500],
    },
});

const Hints = (props) => {
    const {classes} = props;
    const contentMD = `## 节点收益提升方法
- 保持节点在线。
   - 只有当天在线22小时以上才有收益。
   - 晚8点--11点的业务高峰时刻保持在线。在此时间段内，收益更高。
- 上行带宽越大，收益越高。
   - 建议分配给每台上行带宽为50Mbps+。[测速1](https://www.speedtest.net/)  [测速2](http://www.speedtest.cn/)
- 不同运营商网络环境，收益也有差距。
   - 一般地，电信质量最优，电信 > 联通 > 移动。
- 尽量开通公网IP，免费。  
以家中有视频监控需求为由，电话客服开通：
   - 中国电信：10000
   - 中国联通：10010
   - 中国移动：10086
- 设备内插或外接容量越大、性能越高的存储越好。
   - 一般地，SSD固态硬盘 > HDD机械硬盘 > U盘。
   - 使用扩容盘会伤害服务业务，会被检测出并被封禁，收益为零。
- 文件系统格式化为exFAT（跨平台兼容性最强、使用寿命最长、性能高），或ext4（仅供激励计划使用）。[格式化工具下载](http://www.diskgenius.cn/download.php)
- 节点网络位置越靠近Internet公网，收益越高。[图文教程](https://github.com/OpenFog/guide/wiki/%E5%A6%82%E4%BD%95%E4%BD%BF%E8%AE%BE%E5%A4%87%E6%9B%B4%E5%8A%A0%E9%9D%A0%E8%BF%91%E5%85%AC%E7%BD%91)
  - 光猫设置为桥接模式，使用路由器拨号上网。
- 将设备上级的路由器开启UPnP，或将网络类型设置为全锥（Full Cone）或限制型（Restricted）也会提升收益。
- 一条线路只挂一台机器，多挂并不能使总收益提升。
- 刷成官方固件，用性能高的机器挂。
   - 内存512MB以上，MTK7621以上的CPU，带USB3.0或SATA接口。
## 实用工具下载
- 硬盘坏道修复工具  
用反向磁化方法物理修复您的坏道硬盘，让废旧硬盘为您继续创造价值
   - [HDD Regenerator](https://km.webrtc.win/index.php?share/file&user=102&sid=r4qi8QTY)
   - [Victoria](http://www.upantool.com/hfxf/hdd/2650.html)
- 扩容盘检测工具  
轻松检测挂载的外接存储是否为扩容盘，避免因为封禁导致收益为0  
   - [H2testw](http://www.upantool.com/test/xinpian/2016/8469.html)
   - [USB Flash Drive Tester](http://www.upantool.com/test/2011/USB_Flash_Drive_Tester_V1.14.html)
- 硬盘格式化工具  
选择硬盘的文件系统格式，exFAT（跨平台兼容性最强、使用寿命最长、性能高），或ext4（仅供激励计划使用）
   - [DiskGenius](http://www.diskgenius.cn/download.php)
- 路由器刷机工具
   - [PuTTY](https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html)
   - [XShell](http://www.onlinedown.net/soft/36383.htm)`;

    return (
        <div className={classes.root}>
            <ReactMarkdown source={contentMD}/>
            {/*<Typography variant='h5' gutterBottom>
                节点收益提升方法
            </Typography>
            <Typography variant="body1" paragraph>
                1. 保持节点在线，只有当天在线22小时以上才会结算收益。
            </Typography>
            <Typography variant="body1" paragraph>
                2. 晚8点–11点的流量峰值时刻保持在线。在此时间段内，利用带宽峰值更高，收益更高。
            </Typography>
            <Typography variant="body1" paragraph>
                3. 上行带宽越大，收益越高，建议分配给每台上行带宽为50Mbps。
            </Typography>
            <Typography variant="body1" paragraph>
                4. 不同运营商收益也有差距，一般来讲，电信质量最优，电信 > 联通 > 移动。
            </Typography>
            <Typography variant="body1" >
                5. 尽量开通公网IP（以家中有视频监控需求为由，请求开通公网IP），免费。
            </Typography>
            <Typography variant="body1" >
                &emsp;&emsp;各运营商联系电话如下：
            </Typography>
            <Typography variant="body1" >
                 &emsp;&emsp;中国电信：10000
            </Typography>
            <Typography variant="body1" >
                &emsp;&emsp;中国联通：10010
            </Typography>
            <Typography variant="body1" paragraph>
                &emsp;&emsp;中国移动：10086
            </Typography>
            <Typography variant="body1" paragraph>
                6. 设备内插或外接越大容量的存储越好，SSD > 机械硬盘 > 其他存储（不要使用扩容盘，会被封禁，收益为零）。
            </Typography>
            <Typography variant="body1" paragraph>
                7. 文件系统格式化为ext4（性能最强、仅供激励计划使用）、exFAT(跨平台兼容性最强、使用寿命最长），<a className={classes.aLink} href="http://www.diskgenius.cn/download.php" rel="nofollow" target="_blank">格式化工具下载</a>。
            </Typography>
            <Typography variant="body1" paragraph>
                8. 设备处于网络之中的位置越靠近公网，收益越高。<a className={classes.aLink} href="https://blog.csdn.net/weixin_44388511/article/details/88304907" rel="nofollow" target="_blank">图文教程</a>
            </Typography>
            <Typography variant="body1" >
                &emsp;&emsp;光猫设置为桥接模式，使用路由器拨号上网。
            </Typography>
            <Typography variant="body1" >
                &emsp;&emsp;将设备上级的路由器开启UPnP或将网络类型设置为全锥（Full Cone）或者端口限制型（Port Restricted）也会提升收益。
            </Typography>
            <Typography variant="body1" paragraph>
                9. 一条线路只挂一台机器，多挂并不能使总收益提升。
            </Typography>
            <Typography variant="body1" paragraph>
                10. 刷成官方固件，用性能高的机器挂（内存512MB以上，MTK7621以上的芯片，带USB3.0或SATA接口）。
            </Typography>*/}


        </div>
    )
}
Hints.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Hints);