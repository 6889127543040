const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export default (state = {bw: {}}, action) => {
  switch(action.type) {

    case 'MINER/BW_STARTED': {
      return {...state, bwStatus: Status.LOADING}
    }
    case 'MINER/BW_SUCCESS': {
      return {...state, bwStatus: Status.SUCCESS, bw: action.result}
    }
    case 'MINER/BW_FAILURE': {
      return {...state, bwStatus: Status.FAILURE}
    }
      case 'MINER/MAC_STARTED': {
          return {...state, macStatus: Status.LOADING}
      }
      case 'MINER/MAC_SUCCESS': {
          return {...state, macStatus: Status.SUCCESS, macs: action.result}
      }
      case 'MINER/MAC_FAILURE': {
          return {...state, macStatus: Status.FAILURE}
      }
      case 'MINER/MAC/INFO_STARTED': {
          return {...state, macInfoStatus: Status.LOADING}
      }
      case 'MINER/MAC/INFO_SUCCESS': {
          return {...state, macInfoStatus: Status.SUCCESS, macInfo: {...state.macInfo, [action.data.platform]:action.result}}
      }
      case 'MINER/MAC/INFO_FAILURE': {
          return {...state, macInfoStatus: Status.FAILURE}
      }

    default: {
      return state
    }
  }
}
