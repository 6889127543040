import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const Hints = () => {
    const contentMD = `## 如何将路由器设置成公网IP？
1. 开通公网IP，免费。  
以家中有视频监控需求为由，电话客服开通：
   - 中国电信：10000
   - 中国联通：10010
   - 中国移动：10086
2. 光猫设置为桥接模式，使用路由器拨号上网。[图文教程](https://blog.csdn.net/weixin_44388511/article/details/88304907)`;

    return (
        <div className="Advice" >
            <ReactMarkdown source={contentMD}/>
        </div>
    )
}
Hints.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default Hints;