import xhr from '../../Xhr'
import moment from 'moment';
const API = (param = {}) => ({
    node: {
        bw: {
            url: process.env.REACT_APP_NMSAPI_SERVER + `/dp/bws`,
            method: 'GET'
        },
        mac: {
            url: process.env.REACT_APP_NMSAPI_SERVER + `/dp/node/ids`,
            method: 'GET'
        },
        macInfo: {
            url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${param.id || ""}/mac_nodes`,
            method: 'POST'
        },
    },
    bc: {
        bw: {
            url: process.env.REACT_APP_NMSAPI_SERVER + `/openfogos/bc/traffic_m?date=${moment().format('X')}`,
            method: 'GET'
        },
        node: {
            url: process.env.REACT_APP_NMSAPI_SERVER + `/openfogos/bc/nodes`,
            method: 'GET'
        },
        setLimit: {
            url: process.env.REACT_APP_NMSAPI_SERVER + `/openfogos/bc/set_limit`,
            method: 'POST'
        },
    },
    advancedNodes: {
        nodes: {
            url: process.env.REACT_APP_NMSAPI_SERVER + `/advanced/nodes`,
            method: 'GET'
        },
        trafficToday: {//当天流量详情
            url: process.env.REACT_APP_NMSAPI_SERVER + `/advanced/traffic_m/${param.id || ""}`,
            method: 'GET'
        },
        cpuIoToday: {//当天流量详情
            url: process.env.REACT_APP_NMSAPI_SERVER + `/advanced/cpu_io/${param.id || ""}`,
            method: 'GET'
        },
        trafficYesterday: {//当天流量详情
            url: process.env.REACT_APP_NMSAPI_SERVER + `/advanced/traffic_m/${param.id || ""}?date=${moment().subtract(1, 'd').unix()}`,
            method: 'GET'
        },
        cpuIoYesterday: {//当天流量详情
            url: process.env.REACT_APP_NMSAPI_SERVER + `/advanced/cpu_io/${param.id || ""}?date=${moment().subtract(1, 'd').unix()}`,
            method: 'GET'
        },
        chargingBWs: {//N天计费带宽
            url: process.env.REACT_APP_NMSAPI_SERVER + `/advanced/charging_bws/${param.id || ""}/${param.days || ""}`,
            method: 'GET'
        }
    }
})

export const fetchBCBW = () => {
    return xhr({
        api: API().bc.bw,
        action: 'BC/BW'
    })
};

export const fetchBCNodes = () => {
    return xhr({
        api: API().bc.node,
        action: 'BC/NODE'
    })
};

export const setLimit = ({data, ...event}) => {
    return xhr({
        api: API().bc.setLimit,
        action: 'BC/SETLIMIT',
        data,
        event
    })
};

export const fetchMinerBW = () => {
    return xhr({
        api: API().node.bw,
        action: 'MINER/BW'
    })
};

export const fetchMinerMacs = ({...event}) => {
    return xhr({
        api: API().node.mac,
        action: 'MINER/MAC',
        event
    })
};

export const fetchMacInfo = ({data, ...event}) => {
    return xhr({
        api: API({id: localStorage.getItem('openfogos_userid')}).node.macInfo,
        action: 'MINER/MAC/INFO',
        data,
        event
    })
};

export const fetchAdvancedNodes = () => {
    return xhr({
        api: API().advancedNodes.nodes,
        action: 'ADVANCEDNODES/NODE'
    })
};

//获取节点当天流量详情
export const getAdvancedNodeTrafficToday = (id, event) => {
    return xhr({
        api: API({id:id}).advancedNodes.trafficToday,
        action: 'ADVANCEDNODE/TRAFFICTODAY',
        event
    })
};

//获取节点当天IO异常详情
export const getAdvancedNodeIOToday = (id, event) => {
    return xhr({
        api: API({id:id}).advancedNodes.cpuIoToday,
        action: 'ADVANCEDNODE/CPUIOTODAY',
        event
    })
};

//获取节点昨天流量详情
export const getAdvancedNodeTrafficYesterday = (id, event) => {
    return xhr({
        api: API({id:id}).advancedNodes.trafficYesterday,
        action: 'ADVANCEDNODE/TRAFFICYESTERDAY',
        event
    })
};

//获取节点昨天IO异常详情
export const getAdvancedNodeIOYesterday = (id, event) => {
    return xhr({
        api: API({id:id}).advancedNodes.cpuIoYesterday,
        action: 'ADVANCEDNODE/CPUIOYESTERDAY',
        event
    })
};

//获取节点7天计费带宽
export const getAdvancedNodeChargingBWsLastWeek = (id, event) => {
    return xhr({
        api: API({id:id, days:7}).advancedNodes.chargingBWs,
        action: 'ADVANCEDNODE/CHARGINGBWS/LASTWEEK',
        event
    })
};

//获取节点30天计费带宽
export const getAdvancedNodeChargingBWsLastMonth = (id, event) => {
    return xhr({
        api: API({id:id, days:30}).advancedNodes.chargingBWs,
        action: 'ADVANCEDNODE/CHARGINGBWS/LASTMONTH',
        event
    })
};