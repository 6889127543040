import React, {useEffect} from "react";
import Bandwidth from "../../utils/displayBandwidth";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import moment from 'moment'
import {getUserMineTrafficToday, getUserMineChargingBWsLastWeek, getUserMineChargingBWsLastMonth, getUserMineIOToday, getUserMineIOYesterday, getUserMineTrafficYesterday} from "../actions";
import {fetchCertificationStatus} from "../../user/actions";
import {node} from "prop-types";

function BandwidthTodayInner(props) {
  const {nodeInfo, getToday, trafficToday, cpuIoToday} = props;
  useEffect(()=>{
    if(nodeInfo && nodeInfo.id){
      getToday(nodeInfo.id);
    }
  }, [nodeInfo]);

  let data = trafficToday.map(v=>v>=0?v:0).map((v, i)=>({label:moment().startOf('day').add(i * 5, 'm').format('HH:mm'), bw:v * 8/300/1024}));
  if(cpuIoToday){
    const cpuUtil = cpuIoToday.cpu_util_abnormal_times || Array(288).fill(0);
    const ioUtil = cpuIoToday.io_util_abnormal_times || Array(288).fill(0);
    const ioReadAwait = cpuIoToday.io_read_await_abnormal_times || Array(288).fill(0);
    const ioWriteAwait = cpuIoToday.io_write_await_abnormal_times || Array(288).fill(0);
    const online = cpuIoToday.online || Array(288).fill(0);
    data = data.map((v, i)=>({...v, cpu:cpuUtil[i]||0, io: ioUtil[i]||0, ioRead: ioReadAwait[i]||0, ioWrite: ioWriteAwait[i]||0, online: online[i]||0}));
  }
  return <Bandwidth data={data} bwOnly={false}/>
}

function BandwidthYesterdayInner(props) {
  const {nodeInfo, getYesterday, trafficYesterday, cpuIoYesterday} = props;
  useEffect(()=>{
    if(nodeInfo && nodeInfo.id){
      getYesterday(nodeInfo.id);
    }
  }, [nodeInfo]);

  let data = trafficYesterday.map(v=>v>=0?v:0).map((v, i)=>({label:moment().startOf('day').add(i * 5, 'm').format('HH:mm'), bw:v * 8/300/1024}));
  if(cpuIoYesterday){
    const cpuUtil = cpuIoYesterday.cpu_util_abnormal_times || Array(288).fill(0);
    const ioUtil = cpuIoYesterday.io_util_abnormal_times || Array(288).fill(0);
    const ioReadAwait = cpuIoYesterday.io_read_await_abnormal_times || Array(288).fill(0);
    const ioWriteAwait = cpuIoYesterday.io_write_await_abnormal_times || Array(288).fill(0);
    const online = cpuIoYesterday.online || Array(288).fill(0);
    data = data.map((v, i)=>({...v, cpu:cpuUtil[i]||0, io: ioUtil[i]||0, ioRead: ioReadAwait[i]||0, ioWrite: ioWriteAwait[i]||0, online: online[i]||0}));
  }
  return <Bandwidth data={data} bwOnly={false}/>
}

function BandwidthLastWeekInner(props) {
  const {nodeInfo, getLastWeek, chargingBWsLastWeek} = props;
  useEffect(()=>{
    if(nodeInfo && nodeInfo.id){
      getLastWeek(nodeInfo.id);
    }
  }, [nodeInfo]);

  const data = chargingBWsLastWeek.map(v=>v>=0?v:0).map((v, i)=>({label:moment().subtract(7-i, 'd').format('M-DD'), bw:v}))
  return <Bandwidth data={data} bwOnly={true}/>
}

function BandwidthLastMonthInner(props) {
  const {nodeInfo, getLastMonth, chargingBWsLastMonth} = props;
  useEffect(()=>{
    if(nodeInfo && nodeInfo.id){
      getLastMonth(nodeInfo.id);
    }
  }, [nodeInfo]);

  const data = chargingBWsLastMonth.map(v=>v>=0?v:0).map((v, i)=>({label:moment().subtract(30-i, 'd').format('M-DD'), bw:v}))

  return <Bandwidth data={data}  bwOnly={true}/>
}

const mapStateToProps = (state) => {
  return {
    trafficToday: state.userMine.userMineTrafficToday || [],
    cpuIoToday: state.userMine.userMineCPUIOToday || {},
    trafficYesterday: state.userMine.userMineTrafficYesterday || [],
    cpuIoYesterday: state.userMine.userMineCPUIOYesterday || {},
    chargingBWsLastWeek: state.userMine.userMineChargingBWsLastWeek || [],
    chargingBWsLastMonth: state.userMine.userMineChargingBWsLastMonth || [],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getToday: (id) => {
      dispatch(getUserMineTrafficToday(id));
      dispatch(getUserMineIOToday(id));
    },
    getYesterday: (id) => {
      dispatch(getUserMineTrafficYesterday(id));
      dispatch(getUserMineIOYesterday(id));
    },
    getLastWeek: (id) => {
      dispatch(getUserMineChargingBWsLastWeek(id));
    },
    getLastMonth: (id) => {
      dispatch(getUserMineChargingBWsLastMonth(id));
    },
  }
}

export const BandwidthToday = connect(mapStateToProps, mapDispatchToProps)(BandwidthTodayInner);
export const BandwidthYesterday = connect(mapStateToProps, mapDispatchToProps)(BandwidthYesterdayInner);
export const BandwidthLastWeek = connect(mapStateToProps, mapDispatchToProps)(BandwidthLastWeekInner);
export const BandwidthLastMonth = connect(mapStateToProps, mapDispatchToProps)(BandwidthLastMonthInner);
