import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

//系统盘制作教程

const Hints = () => {
    const contentMD = `## 如何绑定闲置小节点
1. 确保OpenFogOS插件已经安装成功并且正在运行
   
2. 打开浏览器，进入网站[https://i.openfogos.com](https://i.openfogos.com)

3. 登录账号（如果没有账号，先注册）。

    ![注册登录.png](/bindNode/s1.png)
4. 进入“闲置小节点招募”界面，点击“本地绑定节点”

    ![本地绑定节点.png](/bindNode/s2.png)
5. 点击确认绑定按钮，即可完成绑定。绑定完成后，可以在管理中心看到节点绑定情况（因网络延迟缘故，需要等5分钟左右才会显示绑定的节点信息）

    ![确认绑定.png](/bindNode/s3.png)
6. 若无法自动搜寻到IP信息，会出现以下页面

    ![未检测到设备.png](/bindNode/s4.png)
7. 此时需要手动填写IP（如果是在本地主机上安装的插件，所以IP为 127.0.0.1），点击检测设备，然后绑定

    ![手动输入IP.png](/bindNode/s5.png)
    `;

    return (
        <div className="Advice" >
            <ReactMarkdown source={contentMD}/>
        </div>
    )
}
export default Hints;