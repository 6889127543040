import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import SiteLayout from './layout/SiteLayout'
import HomeLayout from './layout/HomeLayout'
import NotFound from './pages/NotFound'
import Site from './pages/Site'
import LargeRecruit from './pages/LargeRecruit'
import Login from './pages/Login'
import Home from './pages/Home';
import HomeWindows from './pages/HomeWindows';
import Hints from './pages/Hints';
import Advice from './pages/Advice';
import AdviceBandwidth from './pages/AdviceBandwidth';
import AdviceUPnP from './pages/AdviceUPnP';
import AdvicePublicIP from './pages/AdvicePublicIP';
import MakeScript from './pages/MakeScript';
import {view as Store} from './modules/user/store';
import {view as Advanced} from './modules/miner/list';
import {view as AdvancedBC} from './modules/bc/list';
import {view as BindNode} from './modules/node/bind';
import {view as Certification} from './modules/user/certification';
import {view as SubmitNode} from './modules/userMine/submitNode';
import {view as ModifyNode} from './modules/userMine/modifyNode';
import {view as NodeManage} from './modules/userMine/nodeManage';
import {view as WithdrawLog} from './modules/userMine/withdrawLog';
// import Node from './pages/Node'
// import Config from './pages/Config'
import Withdraw from './pages/Withdraw'
import Goods1 from './modules/user/store/goods1'
import Goods2 from './modules/user/store/goods2'
import Link1 from './pages/Link1';
import Link2 from './pages/Link2';
import Link3 from './pages/Link3';
import Link4 from './pages/Link4';
import Link5 from './pages/Link5';
import MyReward from './pages/MyReward';
import MyHistory from './pages/MyHistory';
import MyBill from './pages/MyBill';
import MyWithdraw from './pages/MyWithdraw';
import MyBasicNodes from './pages/MyBasicNodes';
import MyMineNodes from './pages/MyMineNodes';
import MyAdvancedNodes from './pages/MyAdvancedNodes';
import Help from './pages/Help';

import HelpBasicLocal from './pages/HelpBasicLocal';
import HelpBasicRemote from './pages/HelpBasicRemote';
import HelpDifference from './pages/HelpDifference';
import HelpRule from './pages/HelpRule';
// import Detail from './pages/Detail'
// import Cache from './pages/Cache'
// import Bind from './pages/Bind'
// import Update from './pages/Update'

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/home" component={HomeLayout}></Route>
            <Route path="/login" component={Login}></Route>
            <Route path="/" component={SiteLayout}></Route>
            <Route path="*" component={NotFound}></Route>
        </Switch>
    </BrowserRouter>
)

const SiteRoutes = () => (
    <Switch>
        {/*<Route path="/login" component={Login}></Route>*/}
        <Route path="/" component={LargeRecruit} exact></Route>
        <Route path="/mini" component={Site} exact></Route>
        <Route path="/large" component={LargeRecruit} exact></Route>
        <Route path="/*" component={NotFound}></Route>
    </Switch>
)

const HomeRoutes = () => (
    <Switch>
      <PrivateRoute path="/home/my_reward" component={MyReward}></PrivateRoute>
        <PrivateRoute path="/home/my_history" component={MyHistory}></PrivateRoute>
      <PrivateRoute path="/home/my_bill" component={MyBill}></PrivateRoute>
      <PrivateRoute path="/home/my_withdraw" component={MyWithdraw}></PrivateRoute>
      <PrivateRoute path="/home/my_basic_nodes" component={MyBasicNodes}></PrivateRoute>
      <PrivateRoute path="/home/my_mine_nodes" component={MyMineNodes}></PrivateRoute>
      <PrivateRoute path="/home/my_advanced_nodes" component={MyAdvancedNodes}></PrivateRoute>
      <PrivateRoute path="/home/help" component={Help}></PrivateRoute>

      <PrivateRoute path="/home/help_basic_local" component={HelpBasicLocal}></PrivateRoute>
      <PrivateRoute path="/home/help_basic_remote" component={HelpBasicRemote}></PrivateRoute>
      <PrivateRoute path="/home/help_difference" component={HelpDifference}></PrivateRoute>
      <PrivateRoute path="/home/help_rule" component={HelpRule}></PrivateRoute>

        <PrivateRoute path="/home/index" component={LargeRecruit}></PrivateRoute>
        <PrivateRoute path="/home/mini_manage" component={Home}></PrivateRoute>
        <PrivateRoute path="/home/windows_manage" component={HomeWindows}></PrivateRoute>
        <Route path="/home/large_recruit" component={LargeRecruit} exact></Route>
        <Route path="/home/doc/makesystemdisk" component={Link1} exact></Route>
        <Route path="/home/doc/networkconfig" component={Link2} exact></Route>
            <Route path="/home/doc/pcdn" component={Link3} exact></Route>
      <Route path="/home/doc/bindnode" component={Link4} exact></Route>
      <Route path="/home/doc/supporteddevices" component={Link5} exact></Route>
        {/*<PrivateRoute path="/home/update" component={Update}></PrivateRoute>
        <PrivateRoute path="/home/node/bind" component={Bind}></PrivateRoute>
        <PrivateRoute path="/home/node/cache" component={Cache}></PrivateRoute>
        <PrivateRoute path="/home/node/detail" component={Detail}></PrivateRoute>
        <PrivateRoute path="/home/node/config" component={Config}></PrivateRoute>
        <PrivateRoute path="/home/node" component={Node}></PrivateRoute>*/}
        <PrivateRoute path="/home/withdraw" component={Withdraw}></PrivateRoute>
        <PrivateRoute path="/home/hints" component={Hints}></PrivateRoute>
        <PrivateRoute path="/home/advice" component={Advice} exact></PrivateRoute>
        <PrivateRoute path="/home/advice/bandwidth" component={AdviceBandwidth} exact></PrivateRoute>
        <PrivateRoute path="/home/advice/upnp" component={AdviceUPnP} exact></PrivateRoute>
        <PrivateRoute path="/home/advice/public_ip" component={AdvicePublicIP} exact></PrivateRoute>
        <PrivateRoute path="/home/script" component={MakeScript}></PrivateRoute>
        <PrivateRoute path="/home/store" exact component={Store}></PrivateRoute>
        <PrivateRoute path="/home/advanced" exact component={Advanced}></PrivateRoute>
        <PrivateRoute path="/home/advanced_bc" exact component={AdvancedBC}></PrivateRoute>
        <PrivateRoute path="/home/bind" exact component={BindNode}></PrivateRoute>
        <PrivateRoute path="/home/certification" exact component={Certification}></PrivateRoute>
        <PrivateRoute path="/home/submit_node" exact component={SubmitNode}></PrivateRoute>
        <PrivateRoute path="/home/modify_node" exact component={ModifyNode}></PrivateRoute>
        <PrivateRoute path="/home/large_manage" exact component={NodeManage}></PrivateRoute>
        <PrivateRoute path="/home/large_withdraw_log" exact component={WithdrawLog}></PrivateRoute>

        <PrivateRoute path="/home/store/goods1" component={Goods1}></PrivateRoute>
        <PrivateRoute path="/home/store/goods2" component={Goods2}></PrivateRoute>
        <PrivateRoute path="/home" component={LargeRecruit}></PrivateRoute>
    </Switch>
)

export { Routes, SiteRoutes, HomeRoutes }