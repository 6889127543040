import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Blue from '@material-ui/core/colors/blue';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        textAlign: "center"
    },
    verticalMiddle: {
        verticalAlign: 'middle'
    },
}));

const Footer = () => {
    const classes = useStyles();
    return (
        <footer className={classes.root}>
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502002232">
                    < img src="/备案图标.png" alt="备案图标" className={classes.verticalMiddle} />
                    <Typography align="center" color='textSecondary' variant={"caption"} display="block">粤公网安备 44030502002232号</Typography>
                </a>
                <a target="_blank" href="http://www.miitbeian.gov.cn/state/outPortal/loginPortal.action" rel="noopener noreferrer">
                    <Typography align="center" color='textSecondary' variant={"caption"} display="block">粤ICP备15114601号</Typography>
                </a>
        </footer>
    )
};

export default Footer;