import React from 'react'
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {messageError, messageOk, confirm} from "../../utils/actions";
import {connect} from "react-redux";
import {reducerStatus} from "../reducer";
import {getWithdrawLogs, getCash, checkWithdrawLog, getCollectionMethod, addCollectionMethod, setCollectionMethodType, resetOpenid, getOpenid, addWithdrawRequest} from "../actions";
import PearTable from "../../utils/table";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import blue from "@material-ui/core/colors/blue";
import moment from "moment";
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';
import IconHelp from '@material-ui/icons/HelpOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {fetchCertificationStatus} from "../../user/actions";
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import UnionpayForm from './unionpayForm';
import WechatpayForm from './wechatpayForm';
import AlipayForm from './alipayForm';
import withMobileDialog from "@material-ui/core/withMobileDialog/index";



moment.locale('zh-cn', {
    months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
    monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
    weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
    weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
    weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'YYYY/MM/DD',
        LL: 'YYYY年M月D日',
        LLL: 'YYYY年M月D日Ah点mm分',
        LLLL: 'YYYY年M月D日ddddAh点mm分',
        l: 'YYYY/M/D',
        ll: 'YYYY年M月D日',
        lll: 'YYYY年M月D日 HH:mm',
        llll: 'YYYY年M月D日dddd HH:mm'
    },
    meridiemParse: /凌晨|早上|上午|中午|下午|晚上/,
    meridiemHour: function (hour, meridiem) {
        if (hour === 12) {
            hour = 0;
        }
        if (meridiem === '凌晨' || meridiem === '早上' ||
            meridiem === '上午') {
            return hour;
        } else if (meridiem === '下午' || meridiem === '晚上') {
            return hour + 12;
        } else {
            // '中午'
            return hour >= 11 ? hour : hour + 12;
        }
    },
    meridiem: function (hour, minute, isLower) {
        var hm = hour * 100 + minute;
        if (hm < 600) {
            return '凌晨';
        } else if (hm < 900) {
            return '早上';
        } else if (hm < 1130) {
            return '上午';
        } else if (hm < 1230) {
            return '中午';
        } else if (hm < 1800) {
            return '下午';
        } else {
            return '晚上';
        }
    },
    calendar: {
        sameDay: '[今天]LT',
        nextDay: '[明天]LT',
        nextWeek: '[下]ddddLT',
        lastDay: '[昨天]LT',
        lastWeek: '[上]ddddLT',
        sameElse: 'L'
    },
    dayOfMonthOrdinalParse: /\d{1,2}(日|月|周)/,
    ordinal: function (number, period) {
        switch (period) {
            case 'd':
            case 'D':
            case 'DDD':
                return number + '日';
            case 'M':
                return number + '月';
            case 'w':
            case 'W':
                return number + '周';
            default:
                return number;
        }
    },
    relativeTime: {
        future: '%s内',
        past: '%s前',
        s: '几秒',
        ss: '%d 秒',
        m: '1 分钟',
        mm: '%d 分钟',
        h: '1 小时',
        hh: '%d 小时',
        d: '1 天',
        dd: '%d 天',
        M: '1 个月',
        MM: '%d 个月',
        y: '1 年',
        yy: '%d 年'
    },
    week: {
        // GB/T 7408-1994《数据元和交换格式·信息交换·日期和时间表示法》与ISO 8601:1988等效
        dow: 1, // Monday is the first day of the week.
        doy: 4  // The week that contains Jan 4th is the first week of the year.
    }
});

const styles = theme => ({
    root: {
        // marginTop: theme.spacing( 2),
    },
    button: {
        width: '100%',
        size: 'medium',
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
        verticalAlign: 'middle'
    },
    verticalMiddle: {
        verticalAlign: 'middle'
    },
    tablePaper: {
        marginTop: theme.spacing( 3),
        overflowX: 'auto',
    },
    rewardBox: {
        width: "300px",
        marginTop: "60px",
        marginBottom: "30px"
    },
    link: {
        color: blue[500],
        verticalAlign: 'bottom'
    },
    inputPaper: {
        padding: '4px 4px 2px',
        display: 'inline-flex',
        alignItems: 'center',
        verticalAlign: 'middle',
        width: 410,
    },
    withdrawButton: {
        marginLeft: theme.spacing(1),
        verticalAlign: 'middle'
    },
    divider: {
        marginTop: theme.spacing( 2),
        marginBottom: theme.spacing(1),
    },
    cmDiv: {
        marginBottom: theme.spacing( 2
    )},
    cmTitle: {
        width: 130,
        display: "inline-block"
    },
    cmContent: {
        paddingRight: theme.spacing( 2),
    }
});

const withdrawStatusMap = {
    0: "预生成",
    1: "待审核",
    2: "发放成功",
    3: "拒绝",
    4: "发放失败"
};

class NodeManage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            withdrawAmount: 0,
            withdrawAmountStr: "",
            jumpToCertificationOpen: false,
            formOpenType: 0,
            formData: {},
            checkOpenidID: 0
        };
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onWithdraw = this.onWithdraw.bind(this);
        this.openJumpToCertification = this.openJumpToCertification.bind(this);
        this.closeJumpToCertification = this.closeJumpToCertification.bind(this);
        this.onActiveTypeChange = this.onActiveTypeChange.bind(this);
        this.onChangeForm = this.onChangeForm.bind(this);
        this.onOpenForm = this.onOpenForm.bind(this);
        this.onCloseForm = this.onCloseForm.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.setOpenid = this.setOpenid.bind(this);
    }

    onChange(event) {
        this.setState({
            withdrawAmountStr: event.target.value,
        })
    }

    onBlur() {
        let amount = parseInt(this.state.withdrawAmountStr) || 0;
        this.setState({
            withdrawAmountStr: amount.toString(),
            withdrawAmount: amount,
        })
    }

    openJumpToCertification() {
        this.setState({
            jumpToCertificationOpen: true,
        })
    }

    closeJumpToCertification() {
        this.setState({
            jumpToCertificationOpen: false,
        })
    }
    onWithdraw(activeCM, {unionpay, alipay, wechat, amount: pendingAmount}) {
        if (this.props.certificationStatus !== 1) {
            this.openJumpToCertification();
            return;
        }
        if (!activeCM) {
            this.props.messageError("请先设置并选择收款方式");
            return;
        }
        if (activeCM.type !== 3) {
            this.props.messageError("提现方式暂仅支持支付宝");
            return;
        }
        let amount = this.state.withdrawAmount;
        let maxAmountThisTime = Math.min((this.props.cash.cash||0) / 100, 50000) - pendingAmount;
        if (amount + pendingAmount > (this.props.cash.cash||0) / 100) {
            this.props.messageError(`本次提现金额加上待审核金额大于余额，您这次最多可提 ${maxAmountThisTime}元`);
            return;
        }
        if(amount + pendingAmount > 50000){
            this.props.messageError(`超过每月提现总额上限（50000元），您这次最多可提 ${maxAmountThisTime}元`);
            return;
        }
        switch(activeCM.type){
            case 1:
                if(unionpay >= 10){
                    this.props.messageError("银联提现超过每月次数上限（10次）");
                    return;
                }
                if (amount < 50 || amount > 1500) {
                    this.props.messageError("请输入50-1500的提现金额");
                    return;
                }
                break;
            case 2:
                if(wechat >= 5){
                    this.props.messageError("微信提现超过每月次数上限（5次）");
                    return;
                }
                if (amount < 50 || amount > 200) {
                    this.props.messageError("请输入50-200的提现金额");
                    return;
                }
                break;
            case 3:
                if(alipay >= 1){
                    this.props.messageError("支付宝提现超过每月次数上限（1次）");
                    return;
                }
                if (amount < 50 || amount > 50000) {
                    this.props.messageError("请输入50-50000的提现金额");
                    return;
                }
                break;
            default:
                this.props.messageError("不支持的提现方式！");
                return;
        }
        // if (activeCM.type === 1) {
        //     if (amount < 50 || amount > 1500) {
        //         this.props.messageError("请输入50-1500的提现金额");
        //         return;
        //     }
        // } else {
        //     if (amount < 50 || amount > 200) {
        //         this.props.messageError("请输入50-200的提现金额");
        //         return;
        //     }
        // }

        this.props.confirm(`确定提现${amount}元？`,() => this.props.onAddWithdrawRequest(amount, activeCM), false)
        return;
    }

    onActiveTypeChange(activeType){
        return (event) => {
            let newType = event.target.value;
            if (newType !== activeType.toString()) {
                this.props.onSetCollectionMethodType(newType);
            }
        }
    }
    setOpenid = (openid) => {
        clearInterval(this.state.checkOpenidID);
        this.setState({
            formData: {
                ...this.state.formData,
                openid: openid
            },
        })
    }
    onChangeForm = (form) => {
        this.setState({
            formData: {
                ...form
            },
        })
    };
    onOpenForm = (type, data) => {
        if (type === 2) {
            this.props.onResetOpenid();
            this.setState({
                formOpenType: type,
                formData: {
                    ...data
                },
                checkOpenidID: window.setInterval(()=>this.props.onCheckOpenid(this.setOpenid), 2000)
            });
        } else if (type === 1) {
            this.setState({
                formOpenType: type,
                formData: {
                    ...data
                }
            });
        } else if (type === 3) {
            this.setState({
                formOpenType: type,
                formData: {
                    ...data
                }
            });
        }
        
    };
    onCloseForm = () => {
        clearInterval(this.state.checkOpenidID);
        this.setState({
            formOpenType: 0,
            formData: {}
        });
    };
    onSubmitForm = () => {
        clearInterval(this.state.checkOpenidID);
        let formData = this.state.formData;
        this.props.onAddCollectionMethod({
            ...formData,
            type: this.state.formOpenType
        });
        this.setState({
            formOpenType: 0,
            formData: {}
        });
    };

    render() {
        const {classes, getWithdrawLogsStatus, list, cash, collectionMethods, fullScreen} = this.props;
        const sortedList = [...list].sort((a, b) => (a.created_at - b.created_at)).reverse();
        const pendingSum = {
            unionpay: 0,
            wechat: 0,
            alipay: 0,
            amount: 0
        };
        for(let log of sortedList){
            if(log.status === 1){
                switch(log.type) {
                    case 1:
                        pendingSum.unionpay++;
                        break;
                    case 2:
                        pendingSum.wechat++;
                        break;
                    case 3:
                        pendingSum.alipay++;
                        break;
                    default:
                        break;
                }
                pendingSum.amount += log.cash;
            }
        }
        pendingSum.amount = Math.ceil(pendingSum.amount/100);
        console.log("pendingSum", pendingSum);
        const titleProps = {
            gutterBottom: true,
            align: 'center',
            variant: 'h5',

        };
        let unionpayCM = null, wechatpayCM = null, alipayCM = null, activeCM = null;
        let activeType = 0;
        collectionMethods.forEach(v=>{
            switch(v.type) {
                case 1:
                    unionpayCM = v;
                    break;
                case 2:
                    wechatpayCM = v;
                    break;
                case 3:
                    alipayCM = v;
                    break;
                default:
                    break;
            }
            if(v.type > 0 && v.type === v.active_type) {
                activeCM = v;
                activeType = v.type;
            }
        });
        const now = moment();
        const buttonWithdrawParam = {
            variant: 'contained',
            color: 'primary',
            size: 'medium',
            onClick: ()=>this.onWithdraw(activeCM, pendingSum),
            className: classes.withdrawButton,
            disabled: (now.date() < 16 || now.date() > 19 || now.hours() < 9 || now.hours() >= 17),
        }
        const tableParam = {
            list: sortedList,
            loading: getWithdrawLogsStatus === reducerStatus.LOADING,
            columns: [
                {
                    title: "日期",
                    key: "created_at",
                    render: (value) => (moment(value * 1000).format("LL LTS"))
                },

                {
                    title: "提现金额",
                    key: "cash",
                    render: (value) => `${(value || 0) / 100} 元`
                },
                {
                    title: "扣税",
                    key: "tax",
                    render: (value) => `${(value || 0) / 100} 元`
                },
                {
                    title: "到账金额",
                    key: "cash",
                    render: (value, index, arr) => `${((arr[index].cash || 0) - (arr[index].tax || 0)) / 100} 元`
                },
                {
                    title: "状态",
                    key: "status",
                    render: (value, index, arr) => value ? withdrawStatusMap[value || 0] : (moment().unix() - (arr[index].created_at || 0) > 30 * 60 ? "已失效" : withdrawStatusMap[0])
                },

            ]
        };
        const unionpayFormParam = {
            open: this.state.formOpenType === 1,
            fullScreen: fullScreen,
            onCancel: this.onCloseForm,
            onChange: this.onChangeForm,
            onOk: this.onSubmitForm,
            form: this.state.formData,
            title: "设置银行卡信息"
        };
        const wechatpayFormParam = {
            open: this.state.formOpenType === 2,
            fullScreen: fullScreen,
            onCancel: this.onCloseForm,
            onChange: this.onChangeForm,
            onOk: this.onSubmitForm,
            form: this.state.formData,
            title: "设置微信"
        };
        const alipayFormParam = {
            open: this.state.formOpenType === 3,
            fullScreen: fullScreen,
            onCancel: this.onCloseForm,
            onChange: this.onChangeForm,
            onOk: this.onSubmitForm,
            form: this.state.formData,
            title: "设置支付宝信息"
        };

        const jumpToCertificationDialog = (
            <Dialog open={this.state.jumpToCertificationOpen}>
                <DialogTitle>尚未通过实名认证</DialogTitle>
                <DialogContent>
                    {`您尚未通过实名认证，无法提取，是否前往实名认证页面?`}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeJumpToCertification} color="primary">
                        取消
                    </Button>
                    <Link to={'/home/certification'}>
                        <Button color="primary" autoFocus>
                            确定
                        </Button>
                    </Link>

                </DialogActions>
            </Dialog>
        );
        return (
            <div>
                <Typography {...titleProps}>
                    矿机收益提现
                </Typography>
                <div>
                    <Paper className={classes.inputPaper}>
                        <InputBase
                            className={classes.input}
                            placeholder="输入金额（银联50~1500 微信50~200 支付宝50~50000）"
                            value={this.state.withdrawAmountStr}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                        />
                    </Paper>

                    <Button {...buttonWithdrawParam} >
                        提现
                    </Button>
                    <Tooltip title={
                        <ol>
                            {/*<li>银联单次最大提现1500元，每月最多10次。</li>
                            <li>微信单次最大提现200元，每月最多5次。</li>*/}
                            <li>支付宝单次最大提现50000元，每月最多1次。</li>
                            <li>最小提现额度为50元。</li>
                            <li>提现扣除税费。</li>
                            <li>提现时间为每月16、17、18、19日（09:00-17:00）。</li>
                            <li>为保障用户的资金安全，每笔提现会由风控系统审核后发放，发放时间统一为19日后的1个工作日。</li>
                        </ol>} placement="bottom">
                        <IconHelp className={classes.rightIcon} color="action" style={{fontSize: 24}}/>
                    </Tooltip>
                    <span>{`当前余额：${(cash.cash||0) / 100}元`}</span>
                </div>
                {jumpToCertificationDialog}
                

                <Divider className={classes.divider}/>
                <Typography gutterBottom={true} variant='h6'>收款方式</Typography>
                <div className={classes.cmDiv}>
                    <Radio
                        checked={activeType === 3}
                        onChange={this.onActiveTypeChange(activeType)}
                        value="3"
                        color="primary"
                        name="active-type"
                        inputProps={{'aria-label': '支付宝转账'}}
                        size="small"
                        disabled={!alipayCM}
                    />
                    <span className={classes.cmTitle}>
                    <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-zhifubao"></use>
                </svg>支付宝转账</span>
                    {alipayCM && <React.Fragment>
                        <span className={classes.cmContent}>{alipayCM.name || ""}</span>
                        <span className={classes.cmContent}>{alipayCM.account || ""}</span>
                    </React.Fragment>}
                    <Button style={{float: "right"}} variant="outlined" color="primary" onClick={() => {
                        this.onOpenForm(3, alipayCM || {})
                    }}>{alipayCM ? "修改" : "添加"}</Button>
                </div>

                {/*<div className={classes.cmDiv}>
                <Radio
                    checked={activeType === 1}
                    onChange={this.onActiveTypeChange(activeType)}
                    value="1"
                    color="primary"
                    name="active-type"
                    inputProps={{ 'aria-label': '银联转账' }}
                    size="small"
                    disabled={!unionpayCM}
                    />
                    <span className={classes.cmTitle}>
                    <svg class="icon" aria-hidden="true">
                    <use xlinkHref="#icon-zhifupingtai-yinlian"></use>
                </svg>银联转账</span>
                    {unionpayCM && <React.Fragment>
                        <span className={classes.cmContent}>{unionpayCM.name||""}</span>
                        <span className={classes.cmContent}>{unionpayCM.account||""}</span>
                        <span className={classes.cmContent}>{unionpayCM.bank||""}</span>
                        <span className={classes.cmContent}>{unionpayCM.branch||""}</span>
                        </React.Fragment>}
                        <Button style={{float:"right"}} variant="outlined" color="primary" onClick={()=>{this.onOpenForm(1, unionpayCM||{})}}>{unionpayCM?"修改":"添加"}</Button>
                </div>
                <div className={classes.cmDiv}>
                <Radio
                    checked={activeType === 2}
                    onChange={this.onActiveTypeChange(activeType)}
                    value="2"
                    color="primary"
                    name="active-type"
                    inputProps={{ 'aria-label': '微信红包' }}
                    size="small"
                    disabled={!wechatpayCM}
                    />
                    <span className={classes.cmTitle}>
                    <svg class="icon" aria-hidden="true">
                    <use xlinkHref="#icon-weixinzhifu"></use>
                </svg>微信红包</span>
                    {wechatpayCM && <React.Fragment>
                        <span className={classes.cmContent}>{wechatpayCM.name||""}</span>
                        <span className={classes.cmContent}>{wechatpayCM.account||""}</span>
                        </React.Fragment>}
                        <Button style={{float:"right"}} variant="outlined" color="primary" onClick={()=>{this.onOpenForm(2, wechatpayCM||{})}}>{wechatpayCM?"修改":"添加"}</Button>
                </div>*/}

                <Divider className={classes.divider}/>

                <Typography gutterBottom={true} variant='h6'>
                    提现历史
                </Typography>
                <Paper className={classes.tablePaper}>
                    <PearTable {...tableParam}/>
                </Paper>
                <UnionpayForm {...unionpayFormParam} />
                <WechatpayForm {...wechatpayFormParam} />
                <AlipayForm {...alipayFormParam} />
            </div>
        );

    }

    componentDidMount() {
        this.props.onRequire();
    }
}

NodeManage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        cash: state.userMine.cash || {},
        getWithdrawLogsStatus: state.userMine.getWithdrawLogsStatus,
        list: state.userMine.withdrawLogs || [],
        certificationStatus: state.user.certificationStatus||0,
        collectionMethods: state.userMine.collectionMethods||[],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire: () => {
            dispatch(getCash());
            dispatch(getWithdrawLogs());
            dispatch(fetchCertificationStatus())
            dispatch(getCollectionMethod());
        },
        onCheckRequest: (id, clearInterval) => {
            dispatch(checkWithdrawLog(id, {
                success: (result) => {
                    if (result.status) {
                        clearInterval();
                        dispatch(getWithdrawLogs());
                    }
                },
                error: () => {
                }
            }))
        },
        onSetCollectionMethodType: (type) => {
            dispatch(setCollectionMethodType(type, {
                success: () => {
                    dispatch(getCollectionMethod());
                },
                error: () => {
                    dispatch(messageError("设置失败"))
                }
            }))
        },
        onAddCollectionMethod: (data) => {
            dispatch(addCollectionMethod(data, {
                success: () => {
                    dispatch(messageOk("设置成功"))
                    dispatch(getCollectionMethod());
                },
                error: () => {
                    dispatch(messageError("设置失败"))
                }
            }))
        },
        onResetOpenid: () => {
            dispatch(resetOpenid());
        },
        onCheckOpenid: (setOpenid) => {
            dispatch(getOpenid({
                success: (result) => {
                    if (result) {
                        dispatch(messageOk("openid 上传成功"))
                        setOpenid(result);
                    }
                },
                error: () => {
                }
            }))
        },
        onAddWithdrawRequest: (cash, collectionMethod) => {
            dispatch(addWithdrawRequest(cash, collectionMethod, {
                success: () => {
                    dispatch(messageOk("提交成功"))
                    dispatch(getWithdrawLogs());
                },
                error: () => {
                    dispatch(messageError("提交失败"))
                }
            }))
        },
        messageOk: (dataStr) => {
            dispatch(messageOk(dataStr))
        },
        messageError: (dataStr) => {
            dispatch(messageError(dataStr))
        },
        confirm: (message, callback, hasVcode) => {
            dispatch(confirm(message, callback, hasVcode))
        },
    }
}


export default withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeManage)));
