import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles/index";
import withMobileDialog from "@material-ui/core/withMobileDialog/index";

const styles = theme => ({
    textField: {
        minWidth: 223
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 223,
    },
});

class AddForm extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.onChange(
            {
                ...this.props.form,
                [event.target.name]: event.target.name === "mac_addr"? (event.target.value||"").toLowerCase():event.target.value
            }
        );
    }

    render() {
        const {classes, fullScreen, open, onCancel, onOk, form, title} = this.props;
        const textFieldProps = {
            className: classes.textField,
            variant: "outlined",
            margin: "dense",
        };
        return (
            <Dialog fullWidth maxWidth="xs" fullScreen={fullScreen} open={open} onClose={onCancel}>
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent style={{textAlign:"center"}}>
                    <TextField
                        {...textFieldProps}
                        onChange={this.onChange}
                        label="姓名（支付宝实名认证）"
                        name="name"
                        type="text"
                        value={form.name||""}
                    /><br/>
                    <TextField
                        {...textFieldProps}
                        onChange={this.onChange}
                        label="支付宝登录号（手机或邮箱）"
                        name="account"
                        type="text"
                        value={form.account||""}
                    /><br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        取消
                    </Button>
                    <Button onClick={onOk} color="primary"
                    disabled={!(form.name && form.account)}
                    >
                        完成设置
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default withMobileDialog()(withStyles(styles)(AddForm))
