import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';


const Hints = () => {
    const contentMD = `## 如何打开路由器UPnP功能？
1. 访问路由器配置页面
2. 找到UPnP相关设置，并打开`;

    return (
        <div className="Advice" >
            <ReactMarkdown source={contentMD}/>
        </div>
    )
}
Hints.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default Hints;