import React from 'react'
import { connect } from 'react-redux'
import { closeConfirm } from './actions'
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';



const styles = theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    textField: {
        // marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250,
    },
});

class Confirm extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleVcodeChange = this.handleVcodeChange.bind(this);
        this.state = {
            vcode: ''
        }
    }

    handleVcodeChange (event){
        this.setState({
            vcode: event.target.value
        })
    }

    render () {
        const {classes} = this.props;

        const confirmDeleteTextFieldProps = {
            className: classes.textField,
            variant: "outlined",
            margin: "dense",
            type: "text",
            onChange: this.handleVcodeChange

        };

        return (
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.props.open}
                    onClose={this.props.closeConfirm}
                >
                    <DialogTitle id="alert-dialog-title">确认操作</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.props.message}
                        </DialogContentText>
                        {this.props.vcode?
                            <TextField
                                {...confirmDeleteTextFieldProps}
                                label="验证码"
                                value={this.state.vcode||""}
                            />:null}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.closeConfirm} color="primary">
                            取消
                        </Button>
                        <Button onClick={()=>{this.props.callback(this.state.vcode);this.props.closeConfirm();this.setState({vcode:''});}} color="primary" autoFocus>
                            确定
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        )
    }
}

Confirm.propTypes = {
    classes: PropTypes.object.isRequired,
};

Confirm.defaultProps = {
    open: false,
    message: "",
    callback: function () {},
    vcode: false
}

const mapStateToProps = (state) => {
    return {
        open: state.utils.confirmOpen || false,
        message: state.utils.confirmMessage || "",
        callback: state.utils.confirmCallback || function () {},
        vcode: state.utils.hasVcode || false,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeConfirm: () => {
            dispatch(closeConfirm())
        },
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Confirm))