import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MyAdvancedNodes from "../../pages/MyAdvancedNodes";
import IconButton from "@material-ui/core/IconButton";
import IconBack from "@material-ui/icons/ArrowBack";
import {BasicNodeInfoListItem, SimpleLinkListItem, SimpleActionListItem, MineNodeInfoListItem} from "./listItem";
import IconCheck from "@material-ui/icons/Check";
import Blue from "@material-ui/core/colors/blue";
import {Link} from 'react-router-dom';
import {SimpleTitle} from './title';
import Table from "./table";
import Tab from "./tab";
import * as BasicNodeBandwidth from '../node/bandwidth';
import * as MineNodeBandwidth from '../userMine/bandwidth';
import * as AdvancedNodeBandwidth from '../bc/bandwidth';
import {Resource as NodeResource} from '../node/resource';
import IconClose from "@material-ui/icons/Close";
import classNames from "classnames";
import Edit from "@material-ui/icons/Edit";
import Input from "@material-ui/core/Input";
import {cashToRMB, cashToRMBString} from "./functions";

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    paddingLeft: 0,
    paddingBottom: 0,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  verticalMiddle: {
    verticalAlign: "middle"
  },
  warning: {
    color: theme.myColor.red,
  },
  aLink: {
    color: Blue[500],
  },
  buttonGroup: {
    background: "#f7f7f7"
  },
  gap: {
    flexBasis: theme.spacing(5),
    flexShrink: 0,
  },
  closeButton: {
    marginTop: theme.spacing(3),
  },


}));

const BasicNodeInfoList = (props) => {
  const {nodeInfo} = props;
  const classes = useStyles();
  const approvedStatus = {
    "0": "验收中",
    "1": "试跑中",
    "2": "压测中",
    "3": "验收通过",
    "4": "验收不通过",
  }
  const approvedStatusInfo = {
    "0": "约1~2天内启动验收",
    "1": "开始小规模切量测试",
    "2": "测试最大切量，约2~14天完成",
    "3": "",
    "4": "",
  }
  const natTypes = {
    "FullCone": '全锥型',
    "Open-FullCone": '全锥型',
    "Open-PortRestricted": '端口限制型',
    "Open-RestricNat": '限制型',
    "Open-RestricPortNat": '端口限制型',
    "Open-Restricted": '限制型',
    "Open-Symmetric": '对称型',
    "PortRestricted": '端口限制型',
    "RestricNat": '限制型',
    "RestricPortNat": '端口限制型',
    "Restricted": '限制型',
    "Symmetric": '对称型',
    "SymmetricNat": '对称型'
  };
  const displayInfo = [
    {
      label: "公网IP",
      content: nodeInfo.public_ip || "",
      comment: nodeInfo.http == 1 && <IconCheck color="primary"/>
    },
    {
      label: "内网IP",
      content: nodeInfo.local_ip || "",
    },
    {
      label: "昨日预估收益",
      content: `${((nodeInfo.reward_yesterday || 0) / 100).toFixed(2)} 元`,
    },
    {
      label: "可用空间",
      content: `${((nodeInfo.storage_avail || 0) / 1024).toFixed(2)} GB`,
      comment: ((nodeInfo.storage_avail || 0) / 1024) >= 500 ? <IconCheck color="primary"/> : '建议共享500G以上的闲置空间'
    },
    {
      label: "已用空间",
      content: `${((nodeInfo.storage_used || 0) / 1024).toFixed(2)} GB`,
    },
    {
      label: "验收状态",
      content: approvedStatus[(nodeInfo.approved || 0).toString()],
      comment: (nodeInfo.approved || 0) == 3 ?
        <IconCheck color="primary"/> : approvedStatusInfo[(nodeInfo.approved || 0).toString()]
    },
    {
      label: "NAT类型",
      content: natTypes[(nodeInfo.nat_type || "")] || '未检测出',
    },
    {
      label: "上行带宽",
      content: `${(nodeInfo.upload_bw || 0).toFixed(2)} Mbps`,
      comment: (nodeInfo.upload_bw || 0) >= 100 ? <IconCheck color="primary"/> :
        <Link to="/home/advice/bandwidth" className={classes.aLink}>优化方案</Link>
    },
    {
      label: "实时业务带宽",
      content: `${((nodeInfo.biz_bw || 0) * 8 / 1000 / 1000).toFixed(2)} Mbps`,
    },
    {
      label: "运营商",
      content: nodeInfo.isp || "",
    },
    {
      label: "昨日在线",
      content: (nodeInfo.online_time || 0) < 0 ? '统计中' : `${(nodeInfo.online_time || 0).toFixed(2)} h`,
      comment: (nodeInfo.online_time || 0) >= 24 ? <IconCheck color="primary"/> : '请保持节点稳定运行'
    },
    {
      label: "昨日离线次数",
      content: (nodeInfo.online_time || 0) < 0 ? '统计中' : <span
        className={(nodeInfo.online_time || 0) >= 24 ? "" : classes.warning}>{(nodeInfo.online_time || 0) >= 24 ? 0 : (nodeInfo.offline_times || 1)}</span>,
      comment: (nodeInfo.online_time || 0) >= 24 && <IconCheck color="primary"/>
    },

  ]
  return (
    <React.Fragment>
      <SimpleTitle>节点信息</SimpleTitle>
      {displayInfo.map((v, i) => <BasicNodeInfoListItem key={i} {...v}/>)}
    </React.Fragment>)
}

const MineNodeInfoList = (props) => {
  const {nodeInfo} = props;
  const classes = useStyles();
  const approveStatusMap = {
    0: "未验收",
    1: "验收中",
    2: "验收通过",
    3: "验收不通过"
  };
  const configStatusMap = {
    0: "待配置",
    1: "配置中",
    2: "配置成功",
    3: "配置失败"
  };
  const displayInfo = [
    {
      label: "SSH 地址",
      content: nodeInfo.ssh || "",
    },
    {
      label: "昨日预估收益",
      content: nodeInfo.waiting ? '结算中' : `${((nodeInfo.reward_yesterday || 0) / 100).toFixed(2)} 元`,
    },
    {
      label: "总收益（含预估）",
      content: `${((nodeInfo.reward_total || 0) / 100).toFixed(2)} 元`,
    },
    {
      label: "在线状态",
      content: nodeInfo.online ? '在线' : '离线',
    },
    {
      label: "配置状态",
      content: configStatusMap[(nodeInfo.config_status || 0).toString()],
    },
    {
      label: "验收状态",
      content: approveStatusMap[(nodeInfo.approve_status || 0).toString()],
    },
    {
      label: "工程师备注",
      content: nodeInfo.comment || "",
    },
    {
      label: "昨日在线",
      content: `${(nodeInfo.onlineTime || 0).toFixed(2)} h`,
    },

  ]
  return (
    <React.Fragment>
      <SimpleTitle>节点信息</SimpleTitle>
      {displayInfo.map((v, i) => <MineNodeInfoListItem key={i} {...v}/>)}
    </React.Fragment>)
}

const AdvancedNodeInfoList = (props) => {
  const {nodeInfo} = props;
  const displayInfo = [
    {
      label: "昨日预估收益",
      content: nodeInfo.waiting ? '结算中' : `${nodeInfo.rewardYesterday.toFixed(2)} 元`,
    },
    {
      label: "总收益（含预估）",
      content: `${nodeInfo.totalReward.toFixed(2)} 元`,
    },
    {
      label: "在线状态",
      content: nodeInfo.online ? '在线' : '离线',
    },

  ]
  return (
    <React.Fragment>
      <SimpleTitle>节点信息</SimpleTitle>
      {displayInfo.map((v, i) => <MineNodeInfoListItem key={i} {...v}/>)}
    </React.Fragment>)
}

const BandwidthInfo = (props) => {
  const {Bandwidth, nodeInfo} = props
  return (
    <Tab
      data={[
        {
          label: "今日",
          content: <Bandwidth.BandwidthToday nodeInfo={nodeInfo}/>
        },
        {
          label: "昨日",
          content: <Bandwidth.BandwidthYesterday nodeInfo={nodeInfo}/>
        },
        {
          label: "近一周",
          content: <Bandwidth.BandwidthLastWeek nodeInfo={nodeInfo}/>
        },
        /*{
          label: "最近一月",
          content: <Bandwidth.BandwidthLastMonth nodeInfo={nodeInfo}/>
        },*/
      ]}
    />)
}

const BasicNodeBandwidthInfo = (props) => <BandwidthInfo Bandwidth={BasicNodeBandwidth} nodeInfo={props.nodeInfo}/>
const MineNodeBandwidthInfo = (props) => <BandwidthInfo Bandwidth={MineNodeBandwidth} nodeInfo={props.nodeInfo}/>
const AdvancedNodeBandwidthInfo = (props) => <BandwidthInfo Bandwidth={AdvancedNodeBandwidth} nodeInfo={props.nodeInfo}/>

/**
 *   title: "通知",
 *   titleColor: "#f00"
 *   abstract: "九月份是囧代分销三等分砍掉啊道街，发的赛快餐広。翻到从来光扫到草桑代非常"
 */
function MyDialog(props) {
  const {title, open, onClose, onSetTitle, children} = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [newTitle, setNewTitle] = useState(title);
  const [newTitleDirty, setNewTitleDirty] = useState(false);
  const [titleEditorOpen, setTitleEditorOpen] = useState(false);

  useEffect(() => {
    if (title) {
      setNewTitle(title);
      setNewTitleDirty(false);
    }
  }, [title])

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth='lg'
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className={classes.dialogTitle}>
        <IconButton color="primary" onClick={onClose} className={classes.verticalMiddle}>
          <IconBack/>
        </IconButton>
        {titleEditorOpen?null:<Typography display="inline" className={classes.verticalMiddle} variant="h6">{newTitle || '未命名'}</Typography>}
        {
          onSetTitle ? (
            titleEditorOpen ?
              <Input maxLength={20} autoFocus value={newTitle} onChange={event=>{setNewTitle(event.target.value);setNewTitleDirty(true)}}
                     onKeyUp={e=>{if(e.key === "Enter" && newTitleDirty){onSetTitle(newTitle); setTitleEditorOpen(false)}}} onBlur={()=>{setTitleEditorOpen(false);if(newTitleDirty)onSetTitle(newTitle);}}/>
              :
              <Edit
                style={{fontSize: 16}} className={classes.verticalMiddle}
                color="action" onClick={() => {
                setTitleEditorOpen(true)
              }}/>
          ) : null
        }
      </div>
      {/*<DialogTitle id="responsive-dialog-title">{title}</DialogTitle>*/}
      {children}
      <DialogActions style={{justifyContent: "center"}}>
        <IconButton onClick={onClose}>
          <IconClose fontSize="large" color="primary"/>
        </IconButton>
      </DialogActions>
    </Dialog>)
}

//闲小节点详情
export function BasicNodeDetail(props) {
  const {open, onClose, nodeInfo, onSaveLimit, onDelete, onSetNickname} = props;
  const [nodeLimit, setNodeLimit] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    if (nodeInfo) {
      setNodeLimit(nodeInfo.nodeLimit || {
        storage: 100,
        bandwidth: 100,
        memory: 100,
        cpu: 0,
      });
    }
  }, [nodeInfo])
  return (nodeInfo &&
    <MyDialog title={nodeInfo.nickname || ""} open={open} onClose={onClose} onSetTitle={(nickname)=>onSetNickname(nodeInfo.mac, nickname)}>
      <BasicNodeBandwidthInfo nodeInfo={nodeInfo}/>
      <div className={classes.gap}></div>
      <BasicNodeInfoList
        nodeInfo={nodeInfo}
      />
      <div className={classes.gap}></div>
      <NodeResource
        nodeLimit={nodeLimit}
        setNodeLimit={setNodeLimit}
        cpuCores={nodeInfo.cpu_cores || 0}
      />
      <div className={classes.gap}></div>
      <div className={classes.buttonGroup}>
        <SimpleLinkListItem title="查看定制优化方案" to={{pathname: "/home/advice", state: {...nodeInfo}}}/>
        <SimpleActionListItem title="保存修改" onClick={()=>onSaveLimit({mac_addr: nodeInfo.mac_addr, ...nodeLimit})}/>
        <SimpleActionListItem title="删除节点" color="error" onClick={() => onDelete(nodeInfo.mac_addr || "")}/>
      </div>
    </MyDialog>
  )
}

//矿机节点详情
export function MineNodeDetail(props) {
  const {open, onClose, nodeInfo, onResubmit, onDelete, onSetNickname} = props;
  const classes = useStyles();
  return (nodeInfo &&
    <MyDialog title={nodeInfo.nickname || ""} onSetTitle={(nickname)=>onSetNickname(nodeInfo.id, nickname)} open={open} onClose={onClose}>
      <MineNodeBandwidthInfo nodeInfo={nodeInfo}/>
      <div className={classes.gap}></div>
      <MineNodeInfoList
        nodeInfo={nodeInfo}
      />
      <div className={classes.gap}></div>
      <div className={classes.buttonGroup}>
        <SimpleLinkListItem title="修改配置" to={{pathname: "/home/modify_node", state: {...nodeInfo}}}/>
        {nodeInfo.config_status === 3 &&
        <SimpleActionListItem title="重新提交" onClick={() => onResubmit(nodeInfo.id || 0)}/>}
        <SimpleActionListItem title="删除节点" color="error" onClick={() => onDelete(nodeInfo.id || 0)}/>
      </div>
    </MyDialog>
  )
}

//优享节点详情
export function AdvancedNodeDetail(props) {
  const {open, onClose, nodeInfo} = props;
  const classes = useStyles();
  return (nodeInfo &&
    <MyDialog title={nodeInfo.name || ""} open={open} onClose={onClose}>
      <AdvancedNodeBandwidthInfo nodeInfo={nodeInfo}/>
      <div className={classes.gap}></div>
      <AdvancedNodeInfoList
        nodeInfo={nodeInfo}
      />
    </MyDialog>
  )
}