import React from 'react'
import copy from "copy-to-clipboard/index";
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconCertification from '@material-ui/icons/GroupAdd';
import IconCopy from '@material-ui/icons/FileCopyOutlined';
import {messageOk} from "../../utils/actions";
import {connect} from "react-redux";
import classNames from "classnames";
import {reducerStatus} from "../reducer";
import {fetchCertificationStatus} from "../actions";
import Form from './form';

const styles = theme => ({
    root: {
        // marginTop: theme.spacing( 2),
    },
    button: {
        width: '100%',
        size: 'medium',
    },
    input: {
        width: '100%',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalMiddle: {
        verticalAlign:'middle'
    }
});

const certificationStatusCaption = {
    0: "提交实名认证",
    1: "实名认证通过",
    2: "实名认证审核中",
    3: "实名认证失败，请重新提交",
};
class Certification extends React.Component {

    copyText(text) {
        copy(text);
        this.props.messageOk("复制成功");
    }

  render() {
    const {classes,certificationStatus, certificationStatusStatus} = this.props;
    const titleProps = {
        gutterBottom: true,
        align: 'center',
        variant: 'h6',

    }
    switch (certificationStatusStatus) {
        case reducerStatus.LOADING:
            return (<div>
                <Typography {...titleProps}>
                    加载中...
                </Typography>
            </div>);
        case reducerStatus.FAILURE:
            return (<div>
                <Typography {...titleProps}>
                    查询失败
                </Typography>
            </div>);
        case reducerStatus.SUCCESS:
            switch (certificationStatus) {
                case 0:
                    return (
                        <div>
                            <Typography {...titleProps}>
                                {certificationStatusCaption[0]}
                            </Typography>
                            <Form/>
                        </div>
                    );
                case 1:
                    return (
                        <div>
                            <Typography {...titleProps}>
                                {certificationStatusCaption[1]}
                            </Typography>
                        </div>
                    );
                case 2:
                    return (
                        <div>
                            <Typography {...titleProps}>
                                {certificationStatusCaption[2]}
                            </Typography>
                        </div>
                    );
                case 3:
                    return (
                        <div>
                            <Typography {...titleProps}>
                                {certificationStatusCaption[3]}
                            </Typography>
                            <Form/>
                        </div>
                    );
            }
            return (<div>
                查询失败
            </div>);
        default:
            return null;
    }
  }
  componentDidMount() {
        this.props.onRequire();
  }
}

Certification.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        certificationStatus: state.user.certificationStatus,
        certificationStatusStatus: state.user.certificationStatusStatus || reducerStatus.LOADING
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire: () => {
            dispatch(fetchCertificationStatus())
        },
        messageOk: (dataStr) => {
            dispatch(messageOk(dataStr))
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Certification));
