import React from 'react';
import ReactMarkdown from 'react-markdown';

//如何本地绑定闲小节点

const Hints = () => {
    const contentMD = `## 如何本地绑定闲小节点
1. 部分平台的节点只能使用远程绑定，需依照各自的方式获取节点的NodeID和MAC地址
   
2. 登录网站[https://openfogos.com](https://openfogos.com)

3. 通过左侧菜单栏，选择进入“闲小节点”页面。

4. 点击页面下方的“绑定远程节点”

5. 在对应位置填入节点的MAC地址和NodeID，点击“绑定”即可。

    `;

    return (
      <div className="Advice" >
          <ReactMarkdown source={contentMD}/>
      </div>
    )
}

export default Hints;