import React from 'react';
import ReactMarkdown from 'react-markdown';

const Hints = () => {
    const contentMD = `
### 收益发放

- 闲小节点和矿机节点：每月15日发放上月收益（人民币形式）。
- 优享节点：每月15日发放上月收益（人民币形式），出于冻结状态，下个月1日自动解冻。
### 提现
- 支付宝转账：16-19日可发起提现申请，20-21日统一审批，将自动扣除6%的税点，。
- 发票对公转账：1-15日可发起申请，开票后填写发票号，15日前将发票寄送到我司，发票经审核无误后，15日统一对公转账。
    `;

    return (
      <div className="Advice" >
          <ReactMarkdown source={contentMD}/>
      </div>
    )
}

export default Hints;