import React from 'react'
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconCertification from '@material-ui/icons/GroupAdd';
import IconCopy from '@material-ui/icons/FileCopyOutlined';
import {messageError, messageOk} from "../../utils/actions";
import {connect} from "react-redux";
import classNames from "classnames";
import {reducerStatus} from "../reducer";
import {submitUserMine} from "../actions";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {Redirect} from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
    root: {
        // marginTop: theme.spacing( 2),
    },
    button: {
        width: '100%',
        size: 'medium',
    },
    input: {
        width: '300px',
        margin: theme.spacing(1)
    },
    inputDiv: {
        display: 'inline-flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        width: '300px',
        margin: theme.spacing(1),

        '& span': {
            marginLeft: theme.spacing(1),
            textAlign: 'left',
            flexGrow: 1,
            flexShrink: 1,
            overFlow: 'visible',
            whiteSpace: 'nowrap'
        }
    },
    leftIcon: {
        marginLeft: -theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    verticalMiddle: {
        verticalAlign: 'middle'
    },
    form: {
        textAlign: 'center'
    },
    tooltip: {
        background: 'none',
        maxWidth: 'none'
    }
});

const certificationStatusCaption = {
    0: "提交实名认证",
    1: "实名认证通过",
    2: "实名认证审核中",
    3: "实名认证失败，请重新提交",
};

const selectDataGroup1 = [
    {
        name: "isp",
        title: "运营商",
        values: [
            "电信",
            "联通",
            "移动",
        ]
    },
    {
        name: "link",
        title: "链路类型",
        values: [
            "专线",
            "汇聚线路",
        ]
    },
    /*{
        name: "bandwidth",
        title: "可用带宽",
        values: [
            "500M",
            "1G-10G"
        ]
    },*/

];
const selectDataGroup2 = [
    {
        name: "cpu",
        title: "CPU",
        values: [
            "8线程",
            "16线程",
            "24线程",
            "32线程",
            "40线程",
            "64线程",
            "80线程",
            "96线程",
            "104线程及以上",
        ]
    },
    {
        name: "memory",
        title: "内存",
        values: [
            "8G",
            "16G",
            "32G",
            "64G",
            "128G",
            "256G及以上",
        ]
    },
    {
        name: "storage_type",
        title: "磁盘类型",
        values: [
            "HDD",
            "SATA SSD",
            "PCIe SSD（含NVMe, M.2, U.2）",
        ]
    },
];

const selectDataGroup3 = [
    {
        name: "system",
        title: "系统类型",
        values: [
            "OpenFogOS 官方Server版镜像",
            "Ubuntu 18.04 官方桌面版镜像",
        ]
    },
];

const requiredNames = ["region", "isp", "link", "access_type", "bandwidth", "cpu", "memory", "storage_type", "storage", "system", "ssh", "username", "password", "contact_info"];


    class Certification extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onTextChange = this.onTextChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.checkForm = this.checkForm.bind(this);
        this.jumpToManage = this.jumpToManage.bind(this);
        this.state = {
            formData: {},
            jump: "",
        }
    }

    onTextChange(event) {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.value
            }
        })
    }

    onFileChange(event) {
        this.setState({
            formData: {
                ...this.state.formData,
                [event.target.name]: event.target.files[0],
                [event.target.name + '_value']: event.target.value
            }
        })
    }

    jumpToManage() {
        this.setState({
            jump: "/home/large_manage"
        });
    }

    checkForm() {
        let formData = this.state.formData;
        let result = true;
        requiredNames.forEach(name=>{
            if(!formData[name]){
                console.log("checkForm false");
                result = false;
            }
        });
        return result;
    }

    render() {
        const {classes} = this.props;
        const textFieldProps = {
            className: classes.input,
            onChange: this.onTextChange,
        }
        const canSubmit = this.checkForm();

        const generateSelect = (data) => (
            <div key={data.name}>
                <FormControl className={classes.input} required>
                    <InputLabel htmlFor={`submit-node-${data.name}`}>{data.title}</InputLabel>
                    <Select
                        value={this.state.formData[data.name]||""}
                        onChange={this.onTextChange}
                        inputProps={{
                            name: data.name,
                            id: `submit-node-${data.name}`,
                        }}
                    >
                        {data.values.map(value=>(<MenuItem key={value} value={value}>{value}</MenuItem>))}
                    </Select>
                </FormControl>
                <br/>
            </div>

        );
        if (this.state.jump){
            return <Redirect to={this.state.jump} />;
        }

        return (<form className={classes.form}>
            <TextField {...textFieldProps} required label="地区" name="region" placeholder="直辖市或者省-市" value={this.state.formData.region||""}/>
            <br/>
            {selectDataGroup1.map(data=>(generateSelect(data)))}
            <FormControl component="fieldset" className={classes.input} required>
                <FormLabel component="legend">物理接入方式（序号越小，利用率越高）</FormLabel>
                <RadioGroup row aria-label="access_type" name="access_type" value={this.state.formData.access_type||""} onChange={this.onTextChange}>
                    {[1,2,3,4,5,6,7,8].map(i=>(
                        <FormControlLabel value={`${i}`} control={<Tooltip interactive classes={{tooltip: classes.tooltip}} title={<img src={`/accessType/type_${i}.png`} alt={`type_${i}`}/>} aria-label={`type_${i}`}><Radio /></Tooltip>} label={i} key={i} />
                    ))}
                </RadioGroup>
            </FormControl>
            <br/>
            <TextField {...textFieldProps} required label="可用带宽" name="bandwidth" placeholder="线路数*单路带宽，如1*500M、10*1G" value={this.state.formData.bandwidth||""}/>
            {selectDataGroup2.map(data=>(generateSelect(data)))}
            <TextField {...textFieldProps} required label="磁盘容量" name="storage" placeholder="个数及单盘容量，例如10*2T" value={this.state.formData.storage||""}/>
            <br/>
            {selectDataGroup3.map(data=>(generateSelect(data)))}
            <TextField {...textFieldProps} required label="SSH地址" name="ssh" placeholder="IP或域名" value={this.state.formData.ssh||""}/>
            <br/>
            <TextField {...textFieldProps} required label="登录用户" name="username" placeholder="需要root权限" value={this.state.formData.username||""}/>
            <br/>
            <TextField {...textFieldProps} required label="登录密码" name="password" placeholder="服务器登录密码" value={this.state.formData.password||""}/>
            <br/>
            <TextField {...textFieldProps} required label="联系方式" name="contact_info" placeholder="电话或QQ" value={this.state.formData.contact_info||""}/>
            <br/>

            <div className={classes.inputDiv}>
                <label htmlFor="file_input">
                    <Button color="default" variant={"outlined"} component="span">
                        <UploadIcon className={classes.leftIcon}/>
                        上传汇聚带宽的拨号账号和密码
                    </Button>
                </label>
                <span>{this.state.formData.file_value || '未选择文件'}</span>
                <input id="file_input" name="file" {...textFieldProps} type="file" style={{display: 'none'}} onChange={this.onFileChange}/>

            </div>
            <br/>
            <Button className={classes.input} color="primary" variant={"contained"} component="span" disabled={!canSubmit} onClick={()=>this.props.onSubmit(this.state.formData, this.jumpToManage)}>提交</Button>
        </form>);

    }
}

Certification.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        certificationStatusStatus: state.user.certificationStatusStatus || reducerStatus.LOADING
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        messageOk: (dataStr) => {
            dispatch(messageOk(dataStr))
        },
        onSubmit: (data, jumpToManage) => {
            var formData = new FormData();
            requiredNames.forEach(name=>{
                formData.append(name, data[name]);
            });
            if(data.file) {
                formData.append('file', data.file);
            }
            dispatch(submitUserMine({
                data:formData,
                success: () => {
                    dispatch(messageOk('提交成功'));
                    jumpToManage();
                },
                error: () => {
                    dispatch(messageError('提交失败'));
                }
            }))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Certification));
