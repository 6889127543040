import React, {useEffect} from 'react';
//import { view as UserInfo } from '../modules/user/info/'
import {view as UserCoin} from '../modules/user/coin/'
import {view as UserToken} from '../modules/user/token/'
import Assets from '../modules/utils/displayAssets'
import Announce from '../modules/utils/announce'
import {RewardListItem as ListItem} from '../modules/utils/listItem'
import {view as NodeList} from '../modules/node/list/'
import {view as UserStore} from '../modules/user/store'
import {view as Invite} from '../modules/user/invite'
import Blue from "@material-ui/core/colors/blue";
import PropTypes, {func} from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider'
import {messageOk, messageWarning} from "../modules/utils/actions";
import {connect} from "react-redux";
import {getCash, getUserMine} from "../modules/userMine/actions";
import {fetchOnlineOfflineStat as fetchBasicNodesInfo} from "../modules/node/actions";
import {fetchAdvancedNodes} from "../modules/bc/actions";
import Typography from "@material-ui/core/Typography";
import {Title} from "../modules/utils/title";
import {cashToRMB} from "../modules/utils/functions";

const styles = theme => ({

  gap: {
    height: theme.spacing( 2),
  },
  divider: {
    marginTop: theme.spacing( 2),
    marginBottom: theme.spacing( 2
  )},
});

function MyReward(props) {
  const {onRequire, cashInfo, userMineList, basicNodeStat, advancedNodeList} = props;
  const basicNodesOnline = basicNodeStat.online||0;
  const basicNodesOffline = basicNodeStat.offline||0;
  let mineNodesOnline = 0, mineNodesOffline = 0, advancedNodesOnline = 0, advancedNodesOffline = 0;
  userMineList.forEach(v =>{
    if(v.online){
      mineNodesOnline++;
    } else {
      mineNodesOffline++;
    }
  })
  advancedNodeList.forEach(v =>{
    if(v.online){
      advancedNodesOnline++;
    } else {
      advancedNodesOffline++;
    }
  })
  const assetsData = {
    title: "昨日收益（预估）",
    total: cashToRMB(cashInfo.expected_basic_reward_yesterday) + cashToRMB(cashInfo.expected_mine_reward_yesterday) + cashToRMB(cashInfo.expected_advanced_reward_yesterday),
    details: [
      {
        name: "余额",
        amount: cashToRMB(cashInfo.cash) + cashToRMB(cashInfo.frozen_cash)
      },
      {
        name: "本月累计收益（预估）",
        amount: cashToRMB(cashInfo.expected_this_month)
      },
      {
        name: cashToRMB(cashInfo.delivered_last_month) > 0?"上月收益":"上月收益（待发放）",
        amount: cashToRMB(cashInfo.delivered_last_month) > 0?cashToRMB(cashInfo.delivered_last_month):cashToRMB(cashInfo.expected_last_month)
      }
    ],
    history: {
      title: "历史",
      linkTo: "/home/my_history"
    }
  }

  useEffect(()=>{
    onRequire();
  }, []);
  return (
    <div className="Home">
      <Assets data={assetsData}/>
      <Announce title={"通知"} titleColor={"#f62b2b"}
                abstract={"10月至11月（截止11月中旬）需求缺口：西北电信（要求有公网IP，单条100M或以上）：15G 华北电信（要求有公网IP，单条100M或以上，优先河南山东）：15G 广东电信（要求有公网IP，单条100M或以上）：30G"}>
        <Typography variant="body1" paragraph>
          近期需求缺口：<br/>
          华北移动10G；华南联通5G<br/>
          不分地区的：电信35G；移动20G；联通20G<br/>
          都是利用率100%附近的高质量业务！请赶在元旦前后上机！<br/>
          另公司有高性能服务器部件及大容量固态硬盘库存，可选购或租用，或者探讨其它融资租赁类合作模式。<br/><br/>

          如有相应资源，请联系：shu@pear.hk<br/>
        </Typography>
        <Typography variant="body2" align="right">
          OpenFogOS 团队<br/>
          2020年12月21日
        </Typography>
      </Announce>
      <Title>
        昨日收益
      </Title>
      <ListItem name={"闲小节点"} online={basicNodesOnline} offline={basicNodesOffline} amount={cashToRMB(cashInfo.expected_basic_reward_yesterday)} to="/home/my_basic_nodes"/>
      <ListItem name={"矿机节点"} online={mineNodesOnline} offline={mineNodesOffline} amount={cashToRMB(cashInfo.expected_mine_reward_yesterday)} to="/home/my_mine_nodes"/>
      <ListItem name={"优享节点"} online={advancedNodesOnline} offline={advancedNodesOffline} amount={cashToRMB(cashInfo.expected_advanced_reward_yesterday)} to="/home/my_advanced_nodes"/>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    cashInfo: state.userMine.cash||{},
    userMineList: state.userMine.userMineList||[],
    basicNodeStat: state.node.onOffStat|| {},
    advancedNodeList: state.bc.advancedNodes||[]
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(getCash());
      dispatch(getUserMine());
      dispatch(fetchBasicNodesInfo());
      dispatch(fetchAdvancedNodes());
    },
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyReward));
