import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunkMiddleware from 'redux-thunk'
import { reducer as Auth } from './modules/auth'
import { reducer as Node } from './modules/node/'
import { reducer as User } from './modules/user/'
import { reducer as Utils } from './modules/utils/'
import { reducer as Miner } from './modules/miner/'
import { reducer as BC } from './modules/bc/'
import { reducer as userMine } from './modules/userMine/'

const reducer = combineReducers({
    auth: Auth,
    node: Node,
    user: User,
    utils: Utils,
    miner: Miner,
    bc: BC,
    userMine: userMine,
})

const middlewares = [thunkMiddleware]

const storeEnhancers = compose(
    applyMiddleware(...middlewares),
    (window && window.devToolsExtension) ? window.devToolsExtension() : (f) => f,
)

export default createStore(reducer, {}, storeEnhancers)