import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

// Ubuntu Server版系统网络配置教程

const Hints = () => {
    const contentMD = `## 如何将设备上网
### 概述
 1. 首先检查网卡状态是否正常，如果用 \`ifconfig\` 查看不到期望的网卡，用 \`ip link\` 查看网卡的名称，用 \`ifconfig 网卡名 up\` 命令启动该网卡
 2. 拨号上网时用 \`pppoeconf\` 配置拨号帐号完成拨号
 3. 静态IP地址上网时手动设置IP地址和路由数据　


### 一、启动网卡
1. 开机，输入用户名和密码登录进系统中

    默认用户名： pear

    默认密码  ： 123456
2. 获取管理员权限：输入 \`sudo -i\` ，再输入密码 \`123456\`

    \`\`\`Shell
    pear@pear:~$ sudo -i
    [sudo] password for pear: 
    root@pear:~# 
    \`\`\`
3. 查看当前网络状况： 输入 \`ifconfig\` 回车，再输入 \`ip link\` 回车

    \`\`\`Shell
    root@pear:~# ifconfig
    lo: flags=73<UP,LOOPBACK,RUNNING>  mtu 65536
            inet 127.0.0.1  netmask 255.0.0.0
            inet6 ::1  prefixlen 128  scopeid 0x10<host>
            loop  txqueuelen 1000  (Local Loopback)
            RX packets 151709  bytes 26082133 (26.0 MB)
            RX errors 0  dropped 0  overruns 0  frame 0
            TX packets 151709  bytes 26082133 (26.0 MB)
            TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0
    
    root@pear:~# ip link
    1: lo: <LOOPBACK,UP,LOWER_UP> mtu 65536 qdisc noqueue state UNKNOWN mode DEFAULT group default qlen 1000
        link/loopback 00:00:00:00:00:00 brd 00:00:00:00:00:00
    2: enp2s0: <BROADCAST,MULTICAST,UP,LOWER_UP> mtu 1500 qdisc fq_codel state DOWN mode DEFAULT group default qlen 1000
        link/ether 00:e0:4c:68:52:9b brd ff:ff:ff:ff:ff:ff
    \`\`\`
4. 如上图所示，需要启动enp2s0这个网卡，输入 \`ifconfig enp2s0 up\`

    \`\`\`Shell
    root@pear:~# ifconfig enp2s0 up
    root@pear:~# ifconfig
    enp2s0: flags=4099<UP,BROADCAST,MULTICAST>  mtu 1500
            inet 192.168.99.229  netmask 255.255.255.0  broadcast 192.168.99.255
            ether 00:e0:4c:68:52:9b  txqueuelen 1000  (Ethernet)
            RX packets 1093661  bytes 566176554 (566.1 MB)
            RX errors 0  dropped 0  overruns 0  frame 0
            TX packets 1719264  bytes 1367788641 (1.3 GB)
            TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0
            device interrupt 124  base 0xd000  
    
    lo: flags=73<UP,LOOPBACK,RUNNING>  mtu 65536
            inet 127.0.0.1  netmask 255.0.0.0
            inet6 ::1  prefixlen 128  scopeid 0x10<host>
            loop  txqueuelen 1000  (Local Loopback)
            RX packets 152753  bytes 26256737 (26.2 MB)
            RX errors 0  dropped 0  overruns 0  frame 0
            TX packets 152753  bytes 26256737 (26.2 MB)
            TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0
    
    
    root@pear:~# ip link
    1: lo: <LOOPBACK,UP,LOWER_UP> mtu 65536 qdisc noqueue state UNKNOWN mode DEFAULT group default qlen 1000
        link/loopback 00:00:00:00:00:00 brd 00:00:00:00:00:00
    2: enp2s0: <BROADCAST,MULTICAST,UP,LOWER_UP> mtu 1500 qdisc fq_codel state UP mode DEFAULT group default qlen 1000
        link/ether 00:e0:4c:68:52:9b brd ff:ff:ff:ff:ff:ff
    \`\`\`
5. 输入 \`ifconfig\` 可以看到enp2s0这个网卡已经启动
    
### 二、拨号上网
如果运营商提供的是拨号上网方式，获取用户名和密码之后进行拨号

1. 输入 \`pppoeconf enp2s0\`

    \`\`\`
    root@pear:~# pppoeconf enp2s0
    
    \`\`\`

2. 一直确认回车，输入拨号的账户和拨号的密码，再一直点回车确认

![pppoeconf1.png](/link2/pppoeconf1.png)

![pppoeconf2.png](/link2/pppoeconf2.png)

![pppoeconf3.png](/link2/pppoeconf3.png)

![pppoeconf4.png](/link2/pppoeconf4.png)

![pppoeconf5.png](/link2/pppoeconf5.png)

![pppoeconf6.png](/link2/pppoeconf6.png)

![pppoeconf7.png](/link2/pppoeconf7.png)

![pppoeconf8.png](/link2/pppoeconf8.png)

![pppoeconf9.png](/link2/pppoeconf9.png)

![pppoeconf10.png](/link2/pppoeconf10.png)




3. 拨号成功
    \`\`\`
    root@pear:/etc/ppp/peers# pppoeconf epn2s0
    Plugin rp-pppoe.so loaded.
    
    \`\`\`
    
    输入 \`ifconfig\` 可以看到当前PPP0拨号获取的IP，如180.174.137.47

    \`\`\`
    pear@pear:~$ ifconfig
    enp2s0: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500
            ether bc:30:5b:f4:85:29  txqueuelen 1000  (Ethernet)
            RX packets 76596061723  bytes 60368191007337 (60.3 TB)
            RX errors 0  dropped 0  overruns 4989795  frame 0
            TX packets 90640863674  bytes 120209178510707 (120.2 TB)
            TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0
            device memory 0xd8c00000-d8cfffff  
    lo: flags=73<UP,LOOPBACK,RUNNING>  mtu 65536
            inet 127.0.0.1  netmask 255.0.0.0
            loop  txqueuelen 1000  (Local Loopback)
            RX packets 69211424  bytes 52753545694 (52.7 GB)
            RX errors 0  dropped 0  overruns 0  frame 0
            TX packets 69211424  bytes 52753545694 (52.7 GB)
            TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0
    
    ppp0: flags=4305<UP,POINTOPOINT,RUNNING,NOARP,MULTICAST>  mtu 1492
            inet 180.174.137.47  netmask 255.255.255.255  destination 114.83.148.1
            ppp  txqueuelen 1000  (Point-to-Point Protocol)
            RX packets 9984788439  bytes 8327608903870 (8.3 TB)
            RX errors 0  dropped 0  overruns 0  frame 0
            TX packets 10988162087  bytes 14021476085874 (14.0 TB)
            TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0
    \`\`\`

4. 拨号失败时检查原因
 - 配置错误时重新拨号

    \`\`\`
    root@pear:~# pppoeconf epn2s0
    Plugin rp-pppoe.so loaded.
    /usr/sbin/pppd: In file /etc/ppp/peers/dsl-provider: unrecognized option 'nic-enp2s0：：'
    root@pear:~# cat /etc/ppp/peers/dsl-provider 
    # Minimalistic default options file for DSL/PPPoE connections
    
    noipdefault
    defaultroute
    replacedefaultroute
    hide-password
    #lcp-echo-interval 30
    #lcp-echo-failure 4
    noauth
    persist
    #mtu 1492
    #persist
    #maxfail 0
    #holdoff 20
    plugin rp-pppoe.so
    nic-enp2s0：：
    usepeerdns
    nic-epn2s0
    user "username"
    root@pear:~# pwd
    
    \`\`\`
 - 其它错误用 \`plog\` 命令查看详细信息








### 三、静态IP上网

1. 配置静态IP地址
输入 \`ifconfig enp2s0 静态IP地址 netmask 子网掩码地址\` 回车
 
   \`\`\`
   pear@pear:~$ sudo ifconfig epn2s0 183.196.222.161 netmask 255.255.255.0
   pear@pear:~$ ifconfig
   epn2s0: flags=4163<UP,BROADCAST,RUNNING,MULTICAST>  mtu 1500
           inet 183.196.222.161  netmask 255.255.255.0  broadcast 192.168.99.255
           ether 00:e0:4c:68:52:9b  txqueuelen 1000  (Ethernet)
           RX packets 1153077  bytes 642865417 (642.8 MB)
           RX errors 0  dropped 0  overruns 0  frame 0
           TX packets 1746394  bytes 1370433321 (1.3 GB)
           TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0
           device interrupt 124  base 0xd000  
    
   lo: flags=73<UP,LOOPBACK,RUNNING>  mtu 65536
           inet 127.0.0.1  netmask 255.0.0.0
           inet6 ::1  prefixlen 128  scopeid 0x10<host>
           loop  txqueuelen 1000  (Local Loopback)
           RX packets 157583  bytes 27061240 (27.0 MB)
           RX errors 0  dropped 0  overruns 0  frame 0
           TX packets 157583  bytes 27061240 (27.0 MB)
           TX errors 0  dropped 0 overruns 0  carrier 0  collisions 0
     
   \`\`\`

2. 配置网关和路由

    输入 \`ip route add default via 网关地址 dev enp2s0\` 回车
    
    \`\`\`
    pear@pear:~$ sudo ip route add default via 183.196.222.1 dev epn2s0
    pear@pear:~$ ip route
    default via 192.168.99.1 dev epn2s0 
    192.168.99.0/24 dev epn2s0 proto kernel scope link src 192.168.99.229 metric 100 
    \`\`\`
    `;

    return (
        <div className="Advice" >
            <ReactMarkdown source={contentMD}/>
        </div>
    )
}

export default Hints;