import React from 'react'
import copy from "copy-to-clipboard/index";
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconCertification from '@material-ui/icons/GroupAdd';
import IconCopy from '@material-ui/icons/FileCopyOutlined';
import {messageOk} from "../../utils/actions";
import {connect} from "react-redux";
import classNames from "classnames";
import {reducerStatus} from "../reducer";
import {submitUserMine} from "../actions";
import Form from './form';

const styles = theme => ({
    root: {
        // marginTop: theme.spacing( 2),
    },
    button: {
        width: '100%',
        size: 'medium',
    },
    input: {
        width: '100%',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalMiddle: {
        verticalAlign:'middle'
    }
});
class Certification extends React.Component {

    copyText(text) {
        copy(text);
        this.props.messageOk("复制成功");
    }

  render() {
    const {classes} = this.props;
    const titleProps = {
        gutterBottom: true,
        align: 'center',
        variant: 'h6',

    }
    return (
        <div>
            <Typography {...titleProps}>
                提交矿机节点
            </Typography>
            <Form/>
        </div>
    );

  }
  componentDidMount() {
        this.props.onRequire();
  }
}

Certification.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire: () => {
        },
        messageOk: (dataStr) => {
            dispatch(messageOk(dataStr))
        }
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Certification));
