import React from 'react';
import { Link } from 'react-router-dom';
import {connect} from "react-redux";
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const QRCode = require('qrcode.react');

const WECHAT_APPID = "wx0253252851e1874c";

class Withdraw extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      userId: props.location.state.userId,
      balance: props.location.state.balance
    }
    console.log('userID', this.state.userId)
  }

  render(){
    var redirect_url = "https://wechat.webrtc.win/v1/vdn/owner/" + this.state.userId + "/withdraw/" + this.state.balance
    var redirect_url_encode = encodeURIComponent(redirect_url)
    var url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + WECHAT_APPID + "&redirect_uri=" + redirect_url_encode +
      "&response_type=code&scope=snsapi_base&state=nms_state#wechat_redirect"
    var url1 = "http://weixin.qq.com/r/Tig5IS7E33OgrWpC931c"
    console.log('get money url:', url)
    return (
      <div className="Withdraw">
            <Card>
                <CardContent>
                    <Typography variant="h6">
                        目前仅支持微信红包提现，请先搜索并关注微信公众号"梨享雾计算"。关注后，使用微信扫一扫扫描以下二维码，即可获取红包。
                    </Typography>
                  <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 20, marginBottom: 20, width: 280}}>
                      <QRCode size={280} value={url}/>
                  </div>

                    <Typography variant="body2" align="center">
                        手机请截屏保存二维码后使用微信扫一扫功能
                    </Typography>
                </CardContent>
            </Card>
        {/*<Card title={'公众号二维码'}>
          <Row gutter={20}>
            <Col span={12}>
              <QRCode size={280} value={url1}/>
            </Col>
          </Row>
        </Card>*/}


          <Button color="primary" variant="contained" size="large" style={{marginTop: 10}}><Link to="/home/index">返回</Link></Button>
      </div>


    )
  }
}



export default Withdraw


