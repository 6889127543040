import React, { useState, useEffect } from 'react';
//import { view as UserInfo } from '../modules/user/info/'
import {view as UserCoin} from '../modules/user/coin/'
import {view as UserToken} from '../modules/user/token/'
import Assets from '../modules/utils/displayAssets'
import Announce from '../modules/utils/announce'
import {WithdrawListItem as ListItem} from '../modules/utils/listItem'
import {view as NodeList} from '../modules/node/list/'
import {view as UserStore} from '../modules/user/store'
import {view as Invite} from '../modules/user/invite'
import Blue from "@material-ui/core/colors/blue";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider'
import {fetchCertificationStatus, fetchCoin, fetchInfo, transBalance} from "../modules/user/actions";
import {confirm, messageError, messageOk, messageWarning} from "../modules/utils/actions";
import {connect} from "react-redux";
import {minerGet, bcGet} from "../modules/auth/actions";
import Typography from "@material-ui/core/Typography";
import RightArrowIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import {
  addCollectionMethod, addWithdrawRequest,
  checkWithdrawLog,
  getCash,
  getCollectionMethod, getOpenid,
  getWithdrawLogs, resetOpenid,
  setCollectionMethodType
} from "../modules/userMine/actions";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import {Title} from "../modules/utils/title";
import Tab from "../modules/utils/tab";
import Table from "../modules/utils/table";
import {SimpleLinkListItem} from "../modules/utils/listItem";
import {reducerStatus} from "../modules/userMine/reducer";
import moment from "moment";

const styles = theme => ({

});

function MyReward(props) {
  const {classes} = props;

  return (
    <div>
      <Title>闲小节点</Title>
      <SimpleLinkListItem title="如何本地绑定闲小节点" to="/home/help_basic_local"/>
      <SimpleLinkListItem title="如何远程绑定闲小节点" to="/home/help_basic_remote"/>
      <SimpleLinkListItem title="已适配设备列表" to="/home/doc/supporteddevices"/>

      <Title>矿机节点 & 优享节点</Title>
      <SimpleLinkListItem title="矿机节点和优享节点的区别" to="/home/help_difference"/>
      <SimpleLinkListItem title="购买系统盘" useHref={true} to="" href="https://item.taobao.com/item.htm?id=613751308949" target="_blank" rel="noopener noreferrer"/>
      <SimpleLinkListItem title="系统盘制作教程" to="/home/doc/makesystemdisk"/>
      <SimpleLinkListItem title="Ubuntu Server 版系统网络配置教程" to="/home/doc/networkconfig"/>
      <SimpleLinkListItem title="Windows 版 PCDN 安装教程" to="/home/doc/pcdn"/>

      <Title>收益</Title>
      <SimpleLinkListItem title="各种节点的收益发放及提现规则" to="/home/help_rule"/>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyReward));
