import React, {useState, useEffect} from 'react';
//import { view as UserInfo } from '../modules/user/info/'
import {view as UserCoin} from '../modules/user/coin/'
import {view as UserToken} from '../modules/user/token/'
import Assets from '../modules/utils/displayAssets'
import Announce from '../modules/utils/announce'
import {WithdrawListItem as ListItem} from '../modules/utils/listItem'
import {view as NodeList} from '../modules/node/list/'
import {view as UserStore} from '../modules/user/store'
import {view as Invite} from '../modules/user/invite'
import Blue from "@material-ui/core/colors/blue";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider'
import {fetchCertificationStatus, fetchCoin, fetchInfo, transBalance} from "../modules/user/actions";
import {confirm, messageError, messageOk, messageWarning} from "../modules/utils/actions";
import {connect} from "react-redux";
import {minerGet, bcGet} from "../modules/auth/actions";
import Typography from "@material-ui/core/Typography";
import RightArrowIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import {
  addCollectionMethod, addWithdrawRequest,
  checkWithdrawLog, deleteUserMine,
  getCash, getCashHistory,
  getCollectionMethod, getInviteCode, getInviteNodes, getOpenid, getUserMine,
  getWithdrawLogs, resetOpenid, resubmitUserMine,
  setCollectionMethodType, setName
} from "../modules/userMine/actions";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import {Title} from "../modules/utils/title";
import Tab from "../modules/utils/tab";
import Table from "../modules/utils/table";
import {MineNodeListItem, LinkListItem, ActionListItem, InviteeListItem} from "../modules/utils/listItem";
import {reducerStatus} from "../modules/userMine/reducer";
import moment from "moment";
import {MineNodeDetail} from "../modules/utils/nodeDetail";

const styles = theme => ({});

function MyReward(props) {
  const {userMineList, inviteNodes, onRequire, onResubmit, confirm, onDelete, onSetNickname} = props;
  const [openedNodeInfo, setOpenedNodeInfo] = useState(null);
  useEffect(() => {
    onRequire()
  }, []);

  let sortedInviteNodes = [...inviteNodes];
  sortedInviteNodes.sort((a, b) => (b.inviter_reward_yesterday || 0) - (a.inviter_reward_yesterday || 0))

  const myNodesTableParam = {
    list: userMineList.map(v => ({
      ...v,
      ip: v.ssh || "",
      online: v.online,
      approved: (v.approve_status || 0) === 2,
      onlineTime: v.online_time || 0,
      rewardYesterday: (v.reward_yesterday || 0) / 100,
      totalReward: (v.reward_total || 0) / 100,
      waiting: (v.reward_yesterday || 0) < 0 || ((v.reward_yesterday || 0) === 0 && v.reward_status === 5)//结算中
    })),
    loading: false,
    render: (v) => <MineNodeListItem nodeInfo={v} key={v.ip} onClick={() => setOpenedNodeInfo(v)}/>
  };
  const inviteeTableParam = {
    list: sortedInviteNodes.map(v => ({
      ...v,
      userName: v.user_name || "",
      nodes: (v.macs || []).length,
      rewardYesterday: (v.inviter_reward_yesterday || 0) / 100,
      totalReward: (v.inviter_reward_total || 0) / 100
    })),
    loading: false,
    render: (v) => <InviteeListItem nodeInfo={v} key={v.id}/>
  };
  return (
    <div>
      <Title>矿机节点</Title>

      <Tab
        data={sortedInviteNodes.length > 0 ? [
          {
            label: "我的节点",
            content: <Table {...myNodesTableParam}/>
          },
          {
            label: "推广节点",
            content: <Table {...inviteeTableParam}/>
          },
        ]:[
          {
            label: "我的节点",
            content: <Table {...myNodesTableParam}/>
          }
        ]}
      />
      <MineNodeDetail open={openedNodeInfo != null} onClose={() => setOpenedNodeInfo(null)} nodeInfo={openedNodeInfo}
                      onResubmit={(id) => onResubmit(id)} onDelete={(id) => confirm(`确定删除矿机？该操作无法撤回`, () => {onDelete(id);setOpenedNodeInfo(null)}, false)} onSetNickname={(id, nickname) => onSetNickname(id, nickname)}/>
      <Title>添加节点</Title>
      <LinkListItem title="提交自有节点" subtitle="将有专人完成程序部署，验收成功后，即可产生收益" to="/home/submit_node"/>
      {/*<ActionListItem title="复制邀请链接" subtitle="朋友通过链接注册账号后，绑定的节点会成为你的推广节点" onClick={()=>this.copyText(`https://openfogos.com?usermine_inviter=${inviteCode}`)}/>*/}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userMineList: state.userMine.userMineList || [],
    inviteNodes: state.userMine.inviteNodes || [],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(getUserMine());
      dispatch(getInviteNodes());
    },
    onResubmit: (id) => {
      dispatch(resubmitUserMine(id, {
        success: () => {
          dispatch(messageOk('提交成功'));
          dispatch(getUserMine());
        },
        error: () => {
          dispatch(messageError('提交失败'));
        }
      }));
    },
    onDelete: (id) => {
      dispatch(deleteUserMine(id, {
        success: () => {
          dispatch(messageOk('删除成功'));
          dispatch(getUserMine());
        },
        error: () => {
          dispatch(messageError('删除失败'));
        }
      }));
    },
    onSetNickname: (id, name) => {
      dispatch(setName(id, name, {
        success: () => {
          dispatch(messageOk('设置成功'));
          dispatch(getUserMine());
        },
        error: () => {
          dispatch(messageError('设置失败'));
        }
      }));
    },
    confirm: (message, callback, hasVcode) => {
      dispatch(confirm(message, callback, hasVcode))
    },
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr))
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyReward));
