const Status = {
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'failure'
}

export {Status as reducerStatus};

export default (state = {bw: {}}, action) => {
    switch (action.type) {

        case 'USERMINE/NODE/GET_STARTED': {
            return {...state, getUserMineStatus: Status.LOADING, userMineList: []}
        }
        case 'USERMINE/NODE/GET_SUCCESS': {
            return {...state, getUserMineStatus: Status.SUCCESS, userMineList: action.result}
        }
        case 'USERMINE/NODE/GET_FAILURE': {
            return {...state, getUserMineStatus: Status.FAILURE, userMineList: []}
        }

        case 'USERMINE/NODE/TRAFFICTODAY_STARTED': {
            return {...state, getUserMineTrafficTodayStatus: Status.LOADING, userMineTrafficToday: []}
        }
        case 'USERMINE/NODE/TRAFFICTODAY_SUCCESS': {
            return {...state, getUserMineTrafficTodayStatus: Status.SUCCESS, userMineTrafficToday: action.result}
        }
        case 'USERMINE/NODE/TRAFFICTODAY_FAILURE': {
            return {...state, getUserMineTrafficTodayStatus: Status.FAILURE, userMineTrafficToday: []}
        }

        case 'USERMINE/NODE/CPUIOTODAY_STARTED': {
            return {...state, getUserMineCPUIOTodayStatus: Status.LOADING, userMineCPUIOToday: null}
        }
        case 'USERMINE/NODE/CPUIOTODAY_SUCCESS': {
            return {...state, getUserMineCPUIOTodayStatus: Status.SUCCESS, userMineCPUIOToday: action.result}
        }
        case 'USERMINE/NODE/CPUIOTODAY_FAILURE': {
            return {...state, getUserMineCPUIOTodayStatus: Status.FAILURE, userMineCPUIOToday: null}
        }

        case 'USERMINE/NODE/TRAFFICYESTERDAY_STARTED': {
            return {...state, getUserMineTrafficYesterdayStatus: Status.LOADING, userMineTrafficYesterday: []}
        }
        case 'USERMINE/NODE/TRAFFICYESTERDAY_SUCCESS': {
            return {...state, getUserMineTrafficYesterdayStatus: Status.SUCCESS, userMineTrafficYesterday: action.result}
        }
        case 'USERMINE/NODE/TRAFFICYESTERDAY_FAILURE': {
            return {...state, getUserMineTrafficYesterdayStatus: Status.FAILURE, userMineTrafficYesterday: []}
        }

        case 'USERMINE/NODE/CPUIOYESTERDAY_STARTED': {
            return {...state, getUserMineCPUIOYesterdayStatus: Status.LOADING, userMineCPUIOYesterday: null}
        }
        case 'USERMINE/NODE/CPUIOYESTERDAY_SUCCESS': {
            return {...state, getUserMineCPUIOYesterdayStatus: Status.SUCCESS, userMineCPUIOYesterday: action.result}
        }
        case 'USERMINE/NODE/CPUIOYESTERDAY_FAILURE': {
            return {...state, getUserMineCPUIOYesterdayStatus: Status.FAILURE, userMineCPUIOYesterday: null}
        }

        case 'USERMINE/NODE/CHARGINGBWS/LASTWEEK_STARTED': {
            return {...state, getUserMineChargingBWsLastWeekStatus: Status.LOADING, userMineChargingBWsLastWeek: []}
        }
        case 'USERMINE/NODE/CHARGINGBWS/LASTWEEK_SUCCESS': {
            return {...state, getUserMineChargingBWsLastWeekStatus: Status.SUCCESS, userMineChargingBWsLastWeek: action.result}
        }
        case 'USERMINE/NODE/CHARGINGBWS/LASTWEEK_FAILURE': {
            return {...state, getUserMineChargingBWsLastWeekStatus: Status.FAILURE, userMineChargingBWsLastWeek: []}
        }

        case 'USERMINE/NODE/CHARGINGBWS/LASTMONTH_STARTED': {
            return {...state, getUserMineChargingBWsLastMonthStatus: Status.LOADING, userMineChargingBWsLastMonth: []}
        }
        case 'USERMINE/NODE/CHARGINGBWS/LASTMONTH_SUCCESS': {
            return {...state, getUserMineChargingBWsLastMonthStatus: Status.SUCCESS, userMineChargingBWsLastMonth: action.result}
        }
        case 'USERMINE/NODE/CHARGINGBWS/LASTMONTH_FAILURE': {
            return {...state, getUserMineChargingBWsLastMonthStatus: Status.FAILURE, userMineChargingBWsLastMonth: []}
        }

        case 'USERMINE/CASH/GET_STARTED': {
            return {...state, getCashStatus: Status.LOADING, cash: {}}
        }
        case 'USERMINE/CASH/GET_SUCCESS': {
            return {...state, getCashStatus: Status.SUCCESS, cash: action.result}
        }
        case 'USERMINE/CASH/GET_FAILURE': {
            return {...state, getCashStatus: Status.FAILURE, cash: {}}
        }

        case 'USERMINE/CASH/HISTORY_STARTED': {
            return {...state, getCashHistoryStatus: Status.LOADING}
        }
        case 'USERMINE/CASH/HISTORY_SUCCESS': {

            return {...state, getCashHistoryStatus: Status.SUCCESS, cashHistory: [...(state.cashHistory||[]), ...action.result]}
        }
        case 'USERMINE/CASH/HISTORY_FAILURE': {
            return {...state, getCashHistoryStatus: Status.FAILURE}
        }

        case 'USERMINE/WITHDRAW/GET_STARTED': {
            return {...state, getWithdrawLogsStatus: Status.LOADING, withdrawLogs: []}
        }
        case 'USERMINE/WITHDRAW/GET_SUCCESS': {
            return {...state, getWithdrawLogsStatus: Status.SUCCESS, withdrawLogs: action.result}
        }
        case 'USERMINE/WITHDRAW/GET_FAILURE': {
            return {...state, getWithdrawLogsStatus: Status.FAILURE, withdrawLogs: []}
        }

        case 'USERMINE/WITHDRAW/CHECK_STARTED': {
            return {...state, checkWithdrawLogStatus: Status.LOADING, checkWithdrawLog: {}}
        }
        case 'USERMINE/WITHDRAW/CHECK_SUCCESS': {
            return {...state, checkWithdrawLogStatus: Status.SUCCESS, checkWithdrawLog: action.result}
        }
        case 'USERMINE/WITHDRAW/CHECK_FAILURE': {
            return {...state, checkWithdrawLogStatus: Status.FAILURE, checkWithdrawLog: {}}
        }

        case 'USERMINE/WITHDRAW/PREPARE_STARTED': {
            return {...state, prepareWithdrawStatus: Status.LOADING, prepareWithdrawID: 0}
        }
        case 'USERMINE/WITHDRAW/PREPARE_SUCCESS': {
            return {...state, prepareWithdrawStatus: Status.SUCCESS, prepareWithdrawID: action.result}
        }
        case 'USERMINE/WITHDRAW/PREPARE_FAILURE': {
            return {...state, prepareWithdrawStatus: Status.FAILURE, prepareWithdrawID: 0}
        }

        case 'USERMINE/CM/GET_STARTED': {
            return {...state, getCMStatus: Status.LOADING, collectionMethods: []}
        }
        case 'USERMINE/CM/GET_SUCCESS': {
            return {...state, getCMStatus: Status.SUCCESS, collectionMethods: action.result||[]}
        }
        case 'USERMINE/CM/GET_FAILURE': {
            return {...state, getCMStatus: Status.FAILURE, collectionMethods: []}
        }

        case 'USERMINE/OPENID/RESET_STARTED': {
            return {...state, resetOpenidStatus: Status.LOADING}
        }
        case 'USERMINE/OPENID/RESET_SUCCESS': {
            return {...state, resetOpenidStatus: Status.SUCCESS}
        }
        case 'USERMINE/OPENID/RESET_FAILURE': {
            return {...state, resetOpenidStatus: Status.FAILURE}
        }

        case 'USERMINE/OPENID/GET_STARTED': {
            return {...state, getOpenidStatus: Status.LOADING, openid: ""}
        }
        case 'USERMINE/OPENID/GET_SUCCESS': {
            return {...state, getOpenidStatus: Status.SUCCESS, openid: action.result}
        }
        case 'USERMINE/OPENID/GET_FAILURE': {
            return {...state, getOpenidStatus: Status.FAILURE}
        }

        case 'USERMINE/INVITE/CODE_STARTED': {
            return {...state, getInviteCodeStatus: Status.LOADING, inviteCode: ""}
        }
        case 'USERMINE/INVITE/CODE_SUCCESS': {
            return {...state, getInviteCodeStatus: Status.SUCCESS, inviteCode: action.result}
        }
        case 'USERMINE/INVITE/CODE_FAILURE': {
            return {...state, getInviteCodeStatus: Status.FAILURE}
        }
        case 'USERMINE/INVITE/NODES_STARTED': {
            return {...state, getInviteNodesStatus: Status.LOADING, inviteNodes: []}
        }
        case 'USERMINE/INVITE/NODES_SUCCESS': {
            return {...state, getInviteNodesStatus: Status.SUCCESS, inviteNodes: action.result}
        }
        case 'USERMINE/INVITE/NODES_FAILURE': {
            return {...state, getInviteNodesStatus: Status.FAILURE}
        }

        default: {
            return state
        }
    }
}
