import React, { useState, useEffect } from 'react';
//import { view as UserInfo } from '../modules/user/info/'
import {view as UserCoin} from '../modules/user/coin/'
import {view as UserToken} from '../modules/user/token/'
import Assets from '../modules/utils/displayAssets'
import Announce from '../modules/utils/announce'
import {AdvancedNodeListItem, WithdrawListItem as ListItem} from '../modules/utils/listItem'
import {view as NodeList} from '../modules/node/list/'
import {view as UserStore} from '../modules/user/store'
import {view as Invite} from '../modules/user/invite'
import Blue from "@material-ui/core/colors/blue";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider'
import {fetchCertificationStatus, fetchCoin, fetchInfo, transBalance} from "../modules/user/actions";
import {confirm, messageError, messageOk, messageWarning} from "../modules/utils/actions";
import {connect} from "react-redux";
import {minerGet, bcGet} from "../modules/auth/actions";
import Typography from "@material-ui/core/Typography";
import RightArrowIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import {
  addCollectionMethod, addWithdrawRequest,
  checkWithdrawLog,
  getCash,
  getCollectionMethod, getOpenid,
  getWithdrawLogs, resetOpenid,
  setCollectionMethodType, setFapiao
} from "../modules/userMine/actions";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import {Title} from "../modules/utils/title";
import {cashToRMB} from "../modules/utils/functions";
import Table from "../modules/utils/table";
import moment from "moment";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import UnionpayForm from "../modules/userMine/withdrawLog/unionpayForm";
import WechatpayForm from "../modules/userMine/withdrawLog/wechatpayForm";
import AlipayForm from "../modules/userMine/withdrawLog/alipayForm";
import FapiaoForm from "../modules/userMine/withdrawLog/fapiaoForm";
import CreditCard from "@material-ui/icons/CreditCard"
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Link} from "react-router-dom";

const styles = theme => ({

  gap: {
    height: theme.spacing( 2),
  },
  divider: {
    marginTop: theme.spacing( 2),
    marginBottom: theme.spacing( 2
  )},
  inputPaper: {
    // marginTop: 2,
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '70vw',
    maxWidth: '500px',
    minWidth: '300px',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  cmDiv: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  cmIcon: {
    verticalAlign: "middle",
    margin: 3,
    marginRight: 4,
  },
  cmTitle: {
    width: 130,
    display: "inline-block"
  },
  cmContent: {
    paddingRight: theme.spacing(2),
  },
  cmButton: {
    float: "right",
    marginRight: theme.spacing(2),
  }
});

function MyReward(props) {
  const {classes, onRequire, getWithdrawLogsLoading, list, cash, submitFapiao, collectionMethods, onAddWithdrawRequest, certificationStatus, messageError, fullScreen, onSetCollectionMethodType, onAddCollectionMethod, onResetOpenid, onCheckOpenid, confirm} = props;
  const assetsData = {
    title: "我的资产",
    total: cashToRMB(cash.cash) + cashToRMB(cash.frozen_cash),
    details: [
      {
        name: "可提现",
        amount: cashToRMB(cash.cash)
      },
      {
        name: "待解冻",
        amount: cashToRMB(cash.frozen_cash)
      },
    ],
    history: {
      title: "账单",
      linkTo: "/home/my_bill"
    }
  };
  const [formOpenType, setFormOpenType] = useState(0);
  const [formData, setFormData] = useState({});
  const [checkOpenidID, setCheckOpenidID] = useState(0);
  const [withdrawAmountStr, setWithdrawAmountStr] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [jumpToCertificationOpen, setJumpToCertificationOpen] = useState(false);
  useEffect(()=>{
    onRequire();
  }, []);
  let unionpayCM = null, wechatpayCM = null, alipayCM = null, fapiaoCM, activeCM = null;
  let activeType = 0;
  collectionMethods.forEach(v=>{
    switch(v.type) {
      case 1:
        unionpayCM = v;
        break;
      case 2:
        wechatpayCM = v;
        break;
      case 3:
        alipayCM = v;
        break;
      case 4:
        fapiaoCM = v;
        break;
      default:
        break;
    }
    if(v.type > 0 && v.type === v.active_type) {
      activeCM = v;
      activeType = v.type;
    }
  });
  const pendingSum = {
    unionpay: 0,
    wechat: 0,
    alipay: 0,
    fapiao: 0,
    amount: 0
  };
  for(let log of list){
    if(log.status === 1 || log.status === 6){
      switch(log.type) {
        case 1:
          pendingSum.unionpay++;
          break;
        case 2:
          pendingSum.wechat++;
          break;
        case 3:
          pendingSum.alipay++;
          break;
        case 4:
          pendingSum.fapiao++;
          break;
        default:
          break;
      }
      pendingSum.amount += log.cash;
    }
  }
  pendingSum.amount = Math.ceil(pendingSum.amount/100);
  function onActiveTypeChange(activeType){
    return (event) => {
      let newType = event.target.value;
      if (newType !== activeType.toString()) {
        onSetCollectionMethodType(newType);
      }
    }
  }
  const jumpToCertificationDialog = (
    <Dialog open={jumpToCertificationOpen}>
      <DialogTitle>尚未通过实名认证</DialogTitle>
      <DialogContent>
        {`您尚未通过实名认证，无法提取，是否前往实名认证页面?`}
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setJumpToCertificationOpen(false)} color="primary">
          取消
        </Button>
        <Link to={'/home/certification'}>
          <Button color="primary" autoFocus>
            确定
          </Button>
        </Link>

      </DialogActions>
    </Dialog>
  );

  function onWithdraw(activeCM, {unionpay, alipay, wechat, fapiao, amount: pendingAmount}) {
    if (certificationStatus !== 1) {
      setJumpToCertificationOpen(true);
      return;
    }
    if (!activeCM) {
      messageError("请先设置并选择收款方式");
      return;
    }
    if (activeCM.type !== 3 && activeCM.type !== 4) {
      messageError("提现方式暂仅支持支付宝和发票对公转账");
      return;
    }
    //时间
    const now = moment();
    if (activeCM.type === 3 && (now.date() < 16 || now.date() > 19 || now.hours() < 9 || now.hours() >= 17)){
      messageError("支付宝提现，请在16-19日（9点-17点）申请");
      return;
    }
    if (activeCM.type === 4 && now.date() > 14 ){
      messageError("发票对公转账，请在每月1-14日申请");
      return;
    }

    let amount = withdrawAmount;
    let maxAmountThisTime = Math.min(cashToRMB(cash.cash), 50000) - pendingAmount;
    if (amount + pendingAmount > cashToRMB(cash.cash)) {
      messageError(`本次提现金额加上待审核金额大于余额，您这次最多可提 ${maxAmountThisTime}元`);
      return;
    }
    if(amount + pendingAmount > 50000){
      messageError(`超过每月提现总额上限（50000元），您这次最多可提 ${maxAmountThisTime}元`);
      return;
    }
    switch(activeCM.type){
      case 1:
        if(unionpay >= 10){
          messageError("银联提现超过每月次数上限（10次）");
          return;
        }
        if (amount < 50 || amount > 1500) {
          messageError("请输入50-1500的提现金额");
          return;
        }
        break;
      case 2:
        if(wechat >= 5){
          messageError("微信提现超过每月次数上限（5次）");
          return;
        }
        if (amount < 50 || amount > 200) {
          messageError("请输入50-200的提现金额");
          return;
        }
        break;
      case 3:
        if(alipay >= 1){
          messageError("支付宝提现超过每月次数上限（1次）");
          return;
        }
        if (amount < 50 || amount > 50000) {
          messageError("请输入50-50000的提现金额");
          return;
        }
        break;
      case 4:
        if(fapiao >= 1){
          messageError("发票对公转账超过每月次数上限（1次）");
          return;
        }
        if (amount < 50 || amount > 50000) {
          messageError("请输入50-50000的提现金额");
          return;
        }
        break;
      default:
        messageError("不支持的提现方式！");
        return;
    }

    confirm(`确定提现${amount}元${activeCM.type == 4?"":"（将自动扣除6%的税点）"}？`,() => onAddWithdrawRequest(amount, activeCM), false)
    return;
  }




  const setOpenid = (openid) => {
    clearInterval(checkOpenidID);
    setFormData({
      ...formData,
      openid: openid
    });
  }

  const onOpenForm = (type, data) => {
    if (type === 2) {
      onResetOpenid();
      setFormOpenType(type);
      setFormData({...data});
      setCheckOpenidID(window.setInterval(()=>onCheckOpenid(setOpenid), 2000));
    } else if (type === 1) {
      setFormOpenType(type);
      setFormData({...data});
    } else if (type === 3) {
      setFormOpenType(type);
      setFormData({...data});
    } else if (type === 4) {
      setFormOpenType(type);
      setFormData({...data});
    }

  };

  const onChangeForm = (form) => {
    setFormData({...form});
  };

  const onCloseForm = () => {
    clearInterval(checkOpenidID);
    setFormOpenType(0);
    setFormData({});
  };

  const onSubmitForm = (type) => {
    clearInterval(checkOpenidID);
    onAddCollectionMethod({
      ...formData,
      type: type||formOpenType
    });
    setFormOpenType(0);
    setFormData({});
  };

  const onSubmitFapiao = () => {
    submitFapiao({
      ...formData,
    });
    setFormOpenType(0);
    setFormData({});
  };

  const commonFormParam = {
    fullScreen: fullScreen,
    onCancel: onCloseForm,
    onChange: onChangeForm,
    onOk: onSubmitForm,
    form: formData,
  }
  const unionpayFormParam = {
    ...commonFormParam,
    open: formOpenType === 1,
    title: "设置银行卡信息"
  };
  const wechatpayFormParam = {
    ...commonFormParam,
    open: formOpenType === 2,
    title: "设置微信"
  };
  const alipayFormParam = {
    ...commonFormParam,
    open: formOpenType === 3,
    title: "设置支付宝信息"
  };
  const fapiaoFormParam = {
    ...commonFormParam,
    open: formOpenType === 4,
    onOk: onSubmitFapiao,
  };

  function onBlur() {
    let amount = parseInt(withdrawAmountStr) || 0;
    setWithdrawAmountStr(amount?amount.toString():"");
    setWithdrawAmount(amount);
  }

  const withdrawStatusMap = {
    0: "预生成",
    1: "待审核",
    2: "发放成功",
    3: "拒绝",
    4: "发放失败",
    5: "发放中",
    6: "发票已确认"
  };
  const myWithdrawLogsTableParam = {
    list: [...list].sort((a, b) => (b.created_at - a.created_at)).map(v => ({
      ...v,
      total: cashToRMB(v.cash),
      tax: cashToRMB(v.tax),
      actual: cashToRMB(v.cash) - cashToRMB(v.tax),
      date: v.created_at,
      status: v.status ? (v.status === 1 && v.type === 4 && !v.fapiao? "待填写发票号":withdrawStatusMap[v.status || 0]) : (moment().unix() - (v.created_at || 0) > 30 * 60 ? "已失效" : withdrawStatusMap[0]),
      onClick: v.status === 1 && v.type === 4 ?()=>{setFormOpenType(4);setFormData({...v})}:null,
    })),
    loading: getWithdrawLogsLoading,
    render: (v, i)=><ListItem key={i} status={v.status} total={v.total} tax={v.tax} actual={v.actual} date={v.date} onClick={v.onClick}/>
  };
  return (
    <div className="Home">
      <Assets data={assetsData}/>
      <Title>申请提现</Title>
      <Paper className={classes.inputPaper}>
        <InputBase
          className={classes.input}
          placeholder={`可提现：28463.18`}
          value={withdrawAmountStr}
          onChange={e=>setWithdrawAmountStr(e.target.value)}
          onBlur={onBlur}
        />
        <IconButton className={classes.iconButton} aria-label="search" onClick={()=>onWithdraw(activeCM, pendingSum)} color="primary">
          <RightArrowIcon />
        </IconButton>
      </Paper>
      <Title>收款方式</Title>
      <div className={classes.cmDiv}>
        <Radio
          checked={activeType === 3}
          onChange={onActiveTypeChange(activeType)}
          value="3"
          color="primary"
          name="active-type"
          inputProps={{'aria-label': '支付宝转账'}}
          size="small"
          disabled={!alipayCM}
        />
        <span className={classes.cmTitle}>
                    <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#icon-zhifubao"></use>
                </svg>支付宝转账</span>
        {alipayCM && <React.Fragment>
          <span className={classes.cmContent}>{alipayCM.name || ""}</span>
        </React.Fragment>}
        <Button className={classes.cmButton} variant="outlined" color="primary"
                onClick={() => {onOpenForm(3, alipayCM || {})}}
        >{alipayCM ? "修改" : "添加"}</Button>
      </div>

      <div className={classes.cmDiv}>
        <Radio
          checked={activeType === 4}
          onChange={onActiveTypeChange(activeType)}
          value="4"
          color="primary"
          name="active-type"
          inputProps={{'aria-label': '对公转账'}}
          size="small"
          disabled={!fapiaoCM}
        />
        <span className={classes.cmTitle}><CreditCard className={classes.cmIcon}/>发票对公转账</span>

        {!fapiaoCM && <Button className={classes.cmButton} variant="outlined" color="primary"
                onClick={() => {
                  onSubmitForm(4);
                }}
        >添加</Button>}
      </div>

      <Title>提现记录</Title>
      <Table {...myWithdrawLogsTableParam}/>
      <UnionpayForm {...unionpayFormParam} />
      <WechatpayForm {...wechatpayFormParam} />
      <AlipayForm {...alipayFormParam} />
      <FapiaoForm {...fapiaoFormParam} />
      {jumpToCertificationDialog}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    cash: state.userMine.cash || {},
    getWithdrawLogsLoading: state.userMine.getWithdrawLogsStatus == "loading",
    list: state.userMine.withdrawLogs || [],
    certificationStatus: state.user.certificationStatus||0,
    collectionMethods: state.userMine.collectionMethods||[],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: () => {
      dispatch(getCash());
      dispatch(getWithdrawLogs());
      dispatch(fetchCertificationStatus())
      dispatch(getCollectionMethod());
    },
    onCheckRequest: (id, clearInterval) => {
      dispatch(checkWithdrawLog(id, {
        success: (result) => {
          if (result.status) {
            clearInterval();
            dispatch(getWithdrawLogs());
          }
        },
        error: () => {
        }
      }))
    },
    onSetCollectionMethodType: (type) => {
      dispatch(setCollectionMethodType(type, {
        success: () => {
          dispatch(getCollectionMethod());
        },
        error: () => {
          dispatch(messageError("设置失败"))
        }
      }))
    },
    onAddCollectionMethod: (data) => {
      dispatch(addCollectionMethod(data, {
        success: () => {
          dispatch(messageOk("设置成功"))
          dispatch(getCollectionMethod());
        },
        error: () => {
          dispatch(messageError("设置失败"))
        }
      }))
    },
    onResetOpenid: () => {
      dispatch(resetOpenid());
    },
    onCheckOpenid: (setOpenid) => {
      dispatch(getOpenid({
        success: (result) => {
          if (result) {
            dispatch(messageOk("openid 上传成功"))
            setOpenid(result);
          }
        },
        error: () => {
        }
      }))
    },
    onAddWithdrawRequest: (cash, collectionMethod) => {
      dispatch(addWithdrawRequest(cash, collectionMethod, {
        success: () => {
          dispatch(messageOk("提交成功"))
          dispatch(getWithdrawLogs());
        },
        error: () => {
          dispatch(messageError("提交失败"))
        }
      }))
    },
    submitFapiao: (form) => {
      dispatch(setFapiao(form, {
        success: () => {
          dispatch(messageOk("提交成功"))
          dispatch(getWithdrawLogs());
        },
        error: () => {
          dispatch(messageError("提交失败"))
        }
      }))
    },
    messageOk: (dataStr) => {
      dispatch(messageOk(dataStr))
    },
    messageError: (dataStr) => {
      dispatch(messageError(dataStr))
    },
    confirm: (message, callback, hasVcode) => {
      dispatch(confirm(message, callback, hasVcode))
    },
  }
}

export default withMobileDialog()(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyReward)));
