import xhr from '../../Xhr';
import moment from "moment";

const API = (param = {}) => ({
    node: {
        get: {//获取节点列表
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine`,
            method: 'GET'
        },
        add: {//提交节点
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine`,
            method: 'POST'
        },
        modify: {//修改节点配置
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/modify/${param.id || ""}`,
            method: 'PUT'
        },
        resubmit: {//重新提交节点
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/resubmit/${param.id || ""}`,
            method: 'PUT'
        },
        setName: {//设置节点别名
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/${param.id || ""}?name=${param.name || ""}`,
            method: 'PUT'
        },
        deleteNode: {//删除节点
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/${param.id || ""}`,
            method: 'DELETE'
        },
        trafficToday: {//当天流量详情
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/traffic_m/${param.id || ""}`,
            method: 'GET'
        },
        cpuIoToday: {//当天流量详情
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/cpu_io/${param.id || ""}`,
            method: 'GET'
        },
        trafficYesterday: {//当天流量详情
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/traffic_m/${param.id || ""}?date=${moment().subtract(1, 'd').unix()}`,
            method: 'GET'
        },
        cpuIoYesterday: {//当天流量详情
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/cpu_io/${param.id || ""}?date=${moment().subtract(1, 'd').unix()}`,
            method: 'GET'
        },
        chargingBWs: {//N天计费带宽
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/charging_bws/${param.id || ""}/${param.days || ""}`,
            method: 'GET'
        }
    },
    invite: {
        getCode: {//获取节点列表
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/inviter/code`,
            method: 'GET'
        },
        getNodes: {
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/inviter/nodes`,
            method: 'GET'
        }
    },
    cash: {//散户矿机收益
        get: {//获取余额信息
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/cash`,
            method: 'GET'
        },
        history: {//获取余额信息
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/cash/history?from=${param.from || "0"}&to=${param.to || "0"}`,
            method: 'GET'
        },
    },
    withdraw: {
        get: {//获取提现记录
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/withdraw`,
            method: 'GET'
        },
        prepare: {//提现请求预生成
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/withdraw/${param.amount||0}`,
            method: 'POST'
        },
        check: {//查询请求状态
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/withdraw/${param.id||0}`,
            method: 'GET'
        },
        add: {//发起提现请求
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/withdraw`,
            method: 'POST'
        },
        fapiao: {//发起提现请求
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/withdraw/fapiao`,
            method: 'PUT'
        },
    },
    collectionMethod: {
        get: {//获取收款方式列表
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/collection_method`,
            method: 'GET'
        },
        add: {//添加或修改收款方式
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/collection_method`,
            method: 'POST'
        },
        setType: {//选择默认收款类型
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/collection_method/${param.type||""}`,
            method: 'PUT'
        },
        resetOpenid: {//重置临时openid
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/openid`,
            method: 'DELETE'
        },
        getOpenid: {//获取临时openid
            url: process.env.REACT_APP_NMSAPI_SERVER + `/user_mine/openid`,
            method: 'GET'
        },
    }
})

//获取节点列表
export const getUserMine = () => {
    return xhr({
        api: API().node.get,
        action: 'USERMINE/NODE/GET',
    })
};

//提交矿机节点
export const submitUserMine = ({data, ...event}) => {
    return xhr({
        api: API().node.add,
        action: 'USERMINE/NODE/ADD',
        data,
        event
    })
};

//修改矿机节点
export const modifyUserMine = ({id, data, ...event}) => {
    return xhr({
        api: API({id}).node.modify,
        action: 'USERMINE/NODE/MODIFY',
        data,
        event
    })
};

//重新提交节点
export const resubmitUserMine = (id, event) => {
    return xhr({
        api: API({id}).node.resubmit,
        action: 'USERMINE/NODE/RESUBMIT',
        event
    })
};

//设置矿机别名
export const setName = (id, name, event) => {
    return xhr({
        api: API({id: id, name: name}).node.setName,
        action: 'USERMINE/NODE/SETNAME',
        event
    })
};

//删除节点
export const deleteUserMine = (id, event) => {
    return xhr({
        api: API({id:id}).node.deleteNode,
        action: 'USERMINE/NODE/DELETE',
        event
    })
};

//获取节点当天流量详情
export const getUserMineTrafficToday = (id, event) => {
    return xhr({
        api: API({id:id}).node.trafficToday,
        action: 'USERMINE/NODE/TRAFFICTODAY',
        event
    })
};

//获取节点当天IO异常详情
export const getUserMineIOToday = (id, event) => {
    return xhr({
        api: API({id:id}).node.cpuIoToday,
        action: 'USERMINE/NODE/CPUIOTODAY',
        event
    })
};

//获取节点昨天流量详情
export const getUserMineTrafficYesterday = (id, event) => {
    return xhr({
        api: API({id:id}).node.trafficYesterday,
        action: 'USERMINE/NODE/TRAFFICYESTERDAY',
        event
    })
};

//获取节点昨天IO异常详情
export const getUserMineIOYesterday = (id, event) => {
    return xhr({
        api: API({id:id}).node.cpuIoYesterday,
        action: 'USERMINE/NODE/CPUIOYESTERDAY',
        event
    })
};

//获取节点7天计费带宽
export const getUserMineChargingBWsLastWeek = (id, event) => {
    return xhr({
        api: API({id:id, days:7}).node.chargingBWs,
        action: 'USERMINE/NODE/CHARGINGBWS/LASTWEEK',
        event
    })
};

//获取节点30天计费带宽
export const getUserMineChargingBWsLastMonth = (id, event) => {
    return xhr({
        api: API({id:id, days:30}).node.chargingBWs,
        action: 'USERMINE/NODE/CHARGINGBWS/LASTMONTH',
        event
    })
};

//获取收益历史
export const getCashHistory = (from, to, event) => {
    return xhr({
        api: API({from, to}).cash.history,
        action: 'USERMINE/CASH/HISTORY',
        event
    })
};

//获取余额信息
export const getCash = () => {
    return xhr({
        api: API().cash.get,
        action: 'USERMINE/CASH/GET',
    })
};

//获取提现记录
export const getWithdrawLogs = () => {
    return xhr({
        api: API().withdraw.get,
        action: 'USERMINE/WITHDRAW/GET',
    })
};

//查询请求状态
export const checkWithdrawLog = (id, event) => {
    return xhr({
        api: API({id:id}).withdraw.check,
        action: 'USERMINE/WITHDRAW/CHECK',
        event,
    })
};

//提现请求预生成
export const prepareWithdraw = (amount, event) => {
    return xhr({
        api: API({amount:amount}).withdraw.prepare,
        action: 'USERMINE/WITHDRAW/PREPARE',
        event,
    })
};

//添加或修改收款方式
export const addWithdrawRequest = (cash, collectionMethod, event) => {
    return xhr({
        api: API().withdraw.add,
        action: 'USERMINE/WITHDRAW/ADD',
        data: {
           cash: cash,
           collection_method:  collectionMethod
        },
        event
    })
};

//提交发票号
export const setFapiao = (form, event) => {
    return xhr({
        api: API().withdraw.fapiao,
        action: 'USERMINE/WITHDRAW/FAPIAO',
        data: form,
        event
    })
};

//获取收款方式列表
export const getCollectionMethod = () => {
    return xhr({
        api: API().collectionMethod.get,
        action: 'USERMINE/CM/GET',
    })
};
//添加或修改收款方式
export const addCollectionMethod = (data, event) => {
    return xhr({
        api: API().collectionMethod.add,
        action: 'USERMINE/CM/ADD',
        data,
        event
    })
};
//选择默认收款类型
export const setCollectionMethodType = (type, event) => {
    return xhr({
        api: API({type}).collectionMethod.setType,
        action: 'USERMINE/CM/TYPE/SET',
        event
    })
};
//重置临时openid
export const resetOpenid = () => {
    return xhr({
        api: API().collectionMethod.resetOpenid,
        action: 'USERMINE/OPENID/RESET',
    })
};
//获取临时openid
export const getOpenid = (event) => {
    return xhr({
        api: API().collectionMethod.getOpenid,
        action: 'USERMINE/OPENID/GET',
        event
    })
};
//获取矿场推广邀请码
export const getInviteCode = () => {
    return xhr({
        api: API().invite.getCode,
        action: 'USERMINE/INVITE/CODE',
    })
};
//获取矿场推广节点
export const getInviteNodes = () => {
    return xhr({
        api: API().invite.getNodes,
        action: 'USERMINE/INVITE/NODES',
    })
};