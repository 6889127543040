import moment from 'moment'

const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export {Status as reducerStatus};

export default (state = {info: {}, coin: {}}, action) => {
  switch(action.type) {
    case 'USER/INFO_STARTED': {
      return {...state, infoStatus: Status.LOADING}
    }
    case 'USER/INFO_SUCCESS': {
      let result = action.result
      delete result.nodes
      localStorage.setItem('openfogos_userphone', result.mobile)
      console.log("openfogos_userphone", localStorage.getItem('openfogos_userphone'))
      return {...state, infoStatus: Status.SUCCESS, info: result}
    }
    case 'USER/INFO_FAILURE': {
      return {...state, infoStatus: Status.FAILURE}
    }
    case 'USER/COIN_STARTED': {
      return {...state, coinStatus: Status.LOADING}
    }
    case 'USER/COIN_SUCCESS': {
      let result = action.result
      return {...state, coinStatus: Status.SUCCESS, coin: result}
    }
    case 'USER/COIN_FAILURE': {
      return {...state, coinStatus: Status.FAILURE}
    }
    case 'USER/TRAFFIC_YESTERDAY_STARTED': {
      return {...state, trafficYesterdayStatus: Status.LOADING}
    }
    case 'USER/TRAFFIC_YESTERDAY_SUCCESS': {
      let result = action.result
      return {...state, trafficYesterdayStatus: Status.SUCCESS, traffic_yesterday: result}
    }
    case 'USER/TRAFFIC_YESTERDAY_FAILURE': {
      return {...state, trafficYesterdayStatus: Status.FAILURE}
    }
    case 'USER/TRANS_COIN_STARTED': {
      return {...state, transCoinStatus: Status.LOADING}
    }
    case 'USER/TRANS_COIN_SUCCESS': {
      let data = action.data
      let info = {
        ...state.info,
        pear_coin: state.info.pear_coin + data,
      }
      let coin = {
        ...state.coin,
        traffic: 0,
        pear_coin: 0,
        last_convert_time: moment().unix()
      }
      return {...state, transCoinStatus: Status.SUCCESS, info: info, coin: coin}
    }
    case 'USER/TRANS_COIN_FAILURE': {
      return {...state, transCoinStatus: Status.FAILURE}
    }
    case 'USER/TRANS_BALANCE_STARTED': {
      return {...state, transCoinStatus: Status.LOADING}
    }
    case 'USER/TRANS_BALANCE_SUCCESS': {
      let data = action.data
      let info = {
        ...state.info,
        pear_coin: state.info.pear_coin - data.coinToConvert,
        balance: state.info.balance + data.balanceToGet,
      }
      return {...state, transCoinStatus: Status.SUCCESS, info: info}
    }
    case 'USER/TRANS_BALANCE_FAILURE': {
      return {...state, transCoinStatus: Status.FAILURE}
    }
    case 'USER/PASSWORD_STARTED': {
      return {...state, passwordStatus: Status.LOADING}
    }
    case 'USER/PASSWORD_SUCCESS': {
      return {...state, passwordStatus: Status.SUCCESS}
    }
    case 'USER/PASSWORD_FAILURE': {
      return {...state, passwordStatus: Status.FAILURE}
    }
    case 'USER/EMAIL_STARTED': {
      return {...state, emailStatus: Status.LOADING}
    }
    case 'USER/EMAIL_SUCCESS': {
      return {...state, emailStatus: Status.SUCCESS}
    }
    case 'USER/EMAIL_FAILURE': {
      return {...state, emailStatus: Status.FAILURE}
    }
    case 'USER/PHONE_STARTED': {
      return {...state, phoneStatus: Status.LOADING}
    }
    case 'USER/PHONE_SUCCESS': {
      return {...state, phoneStatus: Status.SUCCESS}
    }
    case 'USER/PHONE_FAILURE': {
      return {...state, phoneStatus: Status.FAILURE}
    }
    case 'USER/CONVERT_GOODS_STARTED': {
      return {...state, convertStatus: Status.LOADING}
    }
    case 'USER/CONVERT_GOODS_SUCCESS': {
      return {...state, convertStatus: Status.SUCCESS}
    }
    case 'USER/CONVERT_GOODS_FAILURE': {
      return {...state, convertStatus: Status.FAILURE}
    }
      case 'USER/CERTIFICATION/STATUS_STARTED': {
          return {...state, certificationStatusStatus: Status.LOADING}
      }
      case 'USER/CERTIFICATION/STATUS_SUCCESS': {
          return {...state, certificationStatusStatus: Status.SUCCESS, certificationStatus: action.result}
      }
      case 'USER/CERTIFICATION/STATUS_FAILURE': {
          return {...state, certificationStatusStatus: Status.FAILURE}
      }
    default: {
      return state
    }
  }
}
