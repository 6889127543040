import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Theme from './theme';
import store from './Store.js';
import { Provider } from 'react-redux';
import { Routes } from './Routes.js';
import SnackBars from "./modules/utils/snackbars";
import Confirm from "./modules/utils/confirm";
import * as Sentry from '@sentry/browser';

// Sentry.init({dsn: "https://67684c5a23494964b3633cf7760443e2@o408002.ingest.sentry.io/5278133"});

ReactDOM.render(
    <MuiThemeProvider theme={Theme}>
        <Provider store={store}>
            <SnackBars/>
            <Confirm/>
            <Routes/>
        </Provider>
    </MuiThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
