import React, {Component} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core/styles/index";
import withMobileDialog from "@material-ui/core/withMobileDialog/index";
const QRCode = require('qrcode.react');

const styles = theme => ({
    textField: {
        minWidth: 223
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 223,
    },
});

class AddForm extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.onChange(
            {
                ...this.props.form,
                [event.target.name]: event.target.name === "mac_addr"? (event.target.value||"").toLowerCase():event.target.value
            }
        );
    }

    render() {
        const {classes, fullScreen, open, onCancel, onOk, form, title} = this.props;
        const textFieldProps = {
            className: classes.textField,
            variant: "outlined",
            margin: "dense",
        };
        return (
            <Dialog fullWidth maxWidth="xs" fullScreen={fullScreen} open={open} onClose={onCancel}>
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent style={{textAlign:"center"}}>
                    <TextField
                        {...textFieldProps}
                        onChange={this.onChange}
                        label="姓名"
                        name="name"
                        type="text"
                        value={form.name||""}
                    /><br/>
                    <TextField
                        {...textFieldProps}
                        onChange={this.onChange}
                        label="微信号"
                        name="account"
                        type="text"
                        value={form.account||""}
                    /><br/>
                    <TextField
                        {...textFieldProps}
                        onChange={this.onChange}
                        label="微信 openid"
                        name="openid"
                        type="text"
                        value={form.openid||""}
                        disabled={true}
                    /><br/>
                    <div style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: 20, width: 222}}>
                        <span style={{fontSize:".8em", lineHeight:1.2}}>关注公众号“梨享雾计算”后，扫描下方二维码上传openid</span>
                        <br/>
                        <QRCode style={{marginTop: 5}} size={222} value={`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0253252851e1874c&redirect_uri=https://nmsapi.webrtc.win/user_mine/wechat/openid/${localStorage.getItem("openfogos_userid")}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`}/>
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        取消
                    </Button>
                    <Button onClick={onOk} color="primary"
                    disabled={!(form.name && form.account && form.openid)}
                    >
                        完成设置
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default withMobileDialog()(withStyles(styles)(AddForm))
