import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { view as Logout } from '../modules/auth/logout/';
import {connect} from "react-redux";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import RouterIcon from '@material-ui/icons/RouterOutlined';
import DNSIcon from '@material-ui/icons/DnsOutlined';
import SubmitIcon from '@material-ui/icons/AssignmentOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import BuildIcon from '@material-ui/icons/Build';
import ManageIcon from '@material-ui/icons/ShowChart';
import BindNodeIcon from '@material-ui/icons/AddBox';
import ExitIcon from '@material-ui/icons/ExitToApp';
import {logout} from "../modules/auth/actions";
import HintIcon from '@material-ui/icons/HelpOutline';
import IconAddShopping from '@material-ui/icons/AddShoppingCart';
import IconSetting from '@material-ui/icons/SettingsOutlined';
import IconInstall from '@material-ui/icons/GetAppOutlined';
import IconFeedback from '@material-ui/icons/FeedbackOutlined';
import classNames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import { Redirect } from 'react-router-dom';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyIcon from '@material-ui/icons/Money';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import StorageIcon from '@material-ui/icons/Storage';
import './header.css';

const styles = theme => ({
    grow: {
        flexGrow: 0,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    list: {
        width: 250,
    },
    golden: {
        color: '#a68540',
    },
    nameTag: {
        color: '#a68540',
        lineHeight: "3.2em",
    },
    toolbar: {
        justifyContent: 'space-between',
        '& > span > a:first-child': {
            paddingRight: "1em"
        }
    },
    nav: {
        color: '#a68540',
        '& a': {
            padding: '0 0 0 1.5em',
            [theme.breakpoints.down('sm')]: {
                padding: '0 1em 0 0',
            },
        },
        flexGrow: 1,
    },
    active: {
        color: '#e2c04b',
    },
    flex: {
        display: 'flex',
        alignItems: 'center'
    }
});



const SiteHeader = (props) => {
    const { classes } = props;
    const onLinkClick = (linkNum) => {
        for( let i = 1; i < 3; i++){
            if(i == linkNum){
                document.getElementById('toolbar-link-' + i).className = classes.active;
            } else {
                document.getElementById('toolbar-link-' + i).className = "";
            }

        }
    };
    if (props.isAuthenticated) {
        return (<Redirect to={{ pathname: '/home/', from: props.location }} />);
    }
    return (
        <header>
            <AppBar position="static" color="secondary">
                <Toolbar className={classes.toolbar}>
                    <Hidden smDown>
                        <Link to={"/"}>
                        <Typography variant="h6" className={classNames(classes.grow, classes.golden)}>
                            OpenFogOS
                        </Typography>
                        </Link>
                    </Hidden>

                    <nav className={classes.nav}>
                        <Link to={"/mini"} id={'toolbar-link-1'} className={document.location.pathname.indexOf('/mini') >= 0 ? classes.active: ""} onClick={()=>onLinkClick(1)}>闲置小节点招募</Link>
                        <Link to={"/large"} id={'toolbar-link-2'} className={document.location.pathname.indexOf('/large') >= 0 ? classes.active: ""} onClick={()=>onLinkClick(2)}>矿机节点招募</Link>
                    </nav>
                    <span>
                        {props.isAuthenticated ? <Link to={"/home/mini_manage"} className={classes.golden}>闲置小节点</Link> :
                            <a target='_self' href={process.env.REACT_APP_ACCOUNT_SERVER + "/login?" + encodeURIComponent(`source=${document.location.host}&title=OpenFogOS`)} className={classes.golden}>登录</a>}
                        {props.isAuthenticated ? <Logout/> : <a target='_self' href={process.env.REACT_APP_ACCOUNT_SERVER + "/signup?" + encodeURIComponent(`source=${document.location.host}&`+window.location.search.substr(1))} className={classes.golden}>
                            注册
                        </a>}
                    </span>

                </Toolbar>
            </AppBar>
        </header>
    )};
SiteHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

class HomeHeader extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.openDrawer = this.openDrawer.bind(this);
        this.closeDrawer = this.closeDrawer.bind(this);
        this.state = {
            drawerOpen: false,
        }
    }


    openDrawer() {
        this.setState({
            drawerOpen: true,
        })
    }
    closeDrawer() {
        this.setState({
            drawerOpen: false,
        })
    }

    render() {
        const { classes } = this.props;
        const onLinkClick = (linkNum) => {
            for( let i = 1; i < 4; i++){
                if(i == linkNum){
                    document.getElementById('toolbar-link-' + i).className = classes.active;
                } else {
                    document.getElementById('toolbar-link-' + i).className = "";
                }

            }
        };
        return (
            <header>
                <AppBar position="static" color="secondary">
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.flex}>
                            <IconButton className={classNames(classes.menuButton, classes.golden)}  aria-label="Menu" onClick={this.openDrawer}>
                                <MenuIcon />
                            </IconButton>
                            <Link to={"/"}>
                                <Typography variant="h6"  className={classNames(classes.grow, classes.golden)}>
                                    OpenFogOS
                                </Typography>
                            </Link>

                        </div>

                        <Hidden smDown>
                            <nav className={classes.nav}>
                                <Link to={"/home/my_basic_nodes"} key='toolbar-link-1' id={'toolbar-link-1'} className={document.location.pathname.indexOf('/my_basic_nodes') >= 0 ? classes.active: ""} onClick={()=>onLinkClick(1)}>闲小节点收益</Link>
                                <Link to={"/home/large_recruit"} key='toolbar-link-2' id={'toolbar-link-2'} className={document.location.pathname.indexOf('/large_recruit') >= 0 ? classes.active: ""} onClick={()=>onLinkClick(2)}>矿机节点招募</Link>
                                <Link to={"/home/my_mine_nodes"} key='toolbar-link-3' id={'toolbar-link-3'} className={document.location.pathname.indexOf('/my_mine_nodes') >= 0 ? classes.active: ""} onClick={()=>onLinkClick(3)}>矿机设备收益</Link>
                                {/*<Link to={"/home/windows_manage"} key='toolbar-link-4' id={'toolbar-link-4'} className={document.location.pathname.indexOf('/windows_manage') >= 0 ? classes.active: ""} onClick={()=>onLinkClick(4)}>Windows节点招募(公测)</Link>*/}
                            </nav>
                        </Hidden>
                        <div className={"list-link"}>
                            <Typography variant="body1"  className={`${classes.nameTag} list-open`}>
                                {localStorage.getItem("openfogos_username")}
                            </Typography>
                            <Paper className={"list-paper"}>
                                <List component="nav">
                                    <ListItem button onClick={this.props.onLogout}>
                                        <ListItemText primary="退出登录" />
                                    </ListItem>
                                </List>
                            </Paper>

                            {/*<ul>
                                <li>
                                    退出登录
                                </li>
                                <li>
                                    然后呢
                                </li>
                            </ul>*/}
                        </div>
                        {/*<Logout className={classes.golden}/>*/}
                    </Toolbar>
                </AppBar>
                <SwipeableDrawer
                    open={this.state.drawerOpen}
                    onClose={this.closeDrawer}
                    onOpen={this.openDrawer}
                >
                    <div
                        tabIndex={0}
                        role="button"
                        onClick={this.closeDrawer}
                        onKeyDown={this.closeDrawer}
                    >
                        <div className={classes.list}>
                            <List>
                                <Link to={'/home/my_reward'}>
                                    <ListItem button>
                                        <ListItemIcon>{<AttachMoneyIcon/>}</ListItemIcon>
                                        <ListItemText primary={'我的收益'} />
                                    </ListItem>
                                </Link>
                                <Link to={'/home/my_withdraw'}>
                                    <ListItem button>
                                        <ListItemIcon>{<MoneyIcon/>}</ListItemIcon>
                                        <ListItemText primary={'提现'} />
                                    </ListItem>
                                </Link>
                                <Divider/>
                                <Link to={'/home/my_basic_nodes'}>
                                    <ListItem button>
                                        <ListItemIcon>{<RouterIcon/>}</ListItemIcon>
                                        <ListItemText primary={'闲小节点'} />
                                    </ListItem>
                                </Link>
                                <Link to={'/home/my_mine_nodes'}>
                                    <ListItem button>
                                        <ListItemIcon>{<DNSIcon/>}</ListItemIcon>
                                        <ListItemText primary={'矿机节点'} />
                                    </ListItem>
                                </Link>
                                <Link to={'/home/my_advanced_nodes'}>
                                    <ListItem button>
                                        <ListItemIcon>{<StorageIcon/>}</ListItemIcon>
                                        <ListItemText primary={'优享节点'} />
                                    </ListItem>
                                </Link>
                                <Divider/>
                                <Link to={'/home/certification'}>
                                    <ListItem button>
                                        <ListItemIcon>{<HowToRegIcon/>}</ListItemIcon>
                                        <ListItemText primary={'实名认证'} />
                                    </ListItem>
                                </Link>
                                <a href={"https://account.openfogos.com/update/password?u=" + localStorage.getItem("openfogos_username")} target="_self">
                                    <ListItem button >
                                        <ListItemIcon>{<IconSetting/>}</ListItemIcon>
                                        <ListItemText primary={'修改密码'} />
                                    </ListItem>
                                </a>

                                <ListItem button onClick={this.props.onLogout}>
                                    <ListItemIcon>{<ExitIcon/>}</ListItemIcon>
                                    <ListItemText primary={'退出登录'} />
                                </ListItem>
                                <Divider/>
                                <Link to={'/home/help'}>
                                    <ListItem button>
                                        <ListItemIcon>{<HelpOutlineIcon/>}</ListItemIcon>
                                        <ListItemText primary={'帮助'} />
                                    </ListItem>
                                </Link>
                                <a href="https://www.wjx.top/jq/39650739.aspx" target="_blank">
                                    <ListItem button >
                                        <ListItemIcon>{<IconFeedback/>}</ListItemIcon>
                                        <ListItemText primary={'问题反馈'} />
                                    </ListItem>
                                </a>
                                {/*<Divider/>
                                <Link to={'/home/mini_manage'}>
                                    <ListItem button>
                                        <ListItemIcon>{<RouterIcon/>}</ListItemIcon>
                                        <ListItemText primary={'闲置小节点'} />
                                    </ListItem>
                                </Link>
                                <Link to={'/home/large_manage'}>
                                    <ListItem button>
                                        <ListItemIcon>{<DNSIcon/>}</ListItemIcon>
                                        <ListItemText primary={'矿机节点'} />
                                    </ListItem>
                                </Link>
                                <Link to={'/home/windows_manage'}>
                                    <ListItem button>
                                        <ListItemIcon>{<DNSIcon/>}</ListItemIcon>
                                        <ListItemText primary={'Windows节点(公测)'} />
                                    </ListItem>
                                </Link>
                                <Link to={'/home/submit_node'}>
                                    <ListItem button>
                                        <ListItemIcon>{<SubmitIcon/>}</ListItemIcon>
                                        <ListItemText primary={'提交矿机节点'} />
                                    </ListItem>
                                </Link>
                                {this.props.isMiner?
                                    <Link to={'/home/advanced'}>
                                        <ListItem button>
                                            <ListItemIcon>{<ManageIcon/>}</ListItemIcon>
                                            <ListItemText primary={'高级管理'} />
                                        </ListItem>
                                    </Link>:null}
                                {(!this.props.isMiner)&&(this.props.isBC)?
                                    <Link to={'/home/advanced_bc'}>
                                        <ListItem button>
                                            <ListItemIcon>{<ManageIcon/>}</ListItemIcon>
                                            <ListItemText primary={'节点管理'} />
                                        </ListItem>
                                    </Link>:null}
                                <Link to={'/home/bind'}>
                                    <ListItem button>
                                        <ListItemIcon>{<BindNodeIcon/>}</ListItemIcon>
                                        <ListItemText primary={'远程绑定节点'} />
                                    </ListItem>
                                </Link>
                                <Link to={'/home/store'}>
                                    <ListItem button>
                                        <ListItemIcon>{<IconAddShopping/>}</ListItemIcon>
                                        <ListItemText primary={'兑换商城'} />
                                    </ListItem>
                                </Link>
                                <Link to={'/home/hints'}>
                                    <ListItem button>
                                        <ListItemIcon>{<HintIcon/>}</ListItemIcon>
                                        <ListItemText primary={'如何提升节点收益'} />
                                    </ListItem>
                                </Link>
                                <Link to={'/home/script'}>
                                    <ListItem button>
                                        <ListItemIcon>{<IconInstall/>}</ListItemIcon>
                                        <ListItemText primary={'安装OpenFogOS'} />
                                    </ListItem>
                                </Link>
                                <a href="https://www.wjx.top/jq/39650739.aspx" target="_blank">
                                    <ListItem button >
                                        <ListItemIcon>{<IconFeedback/>}</ListItemIcon>
                                        <ListItemText primary={'问题反馈'} />
                                    </ListItem>
                                </a>
                                <Link to={'/home/tools'}>
                                    <ListItem button>
                                        <embed src="/tools.svg" type="image/svg+xml" width="24" height="24" style={{marginRight:"16px"}}/>
                                        <ListItemText primary={'实用工具'} />
                                    </ListItem>
                                </Link>

                                <Divider/>
                                <Link to={'/home/certification'}>
                                    <ListItem button>
                                        <ListItemIcon>{<IconInstall/>}</ListItemIcon>
                                        <ListItemText primary={'实名认证'} />
                                    </ListItem>
                                </Link>
                                <a href={"https://account.openfogos.com/update/password?u=" + localStorage.getItem("openfogos_username")} target="_self">
                                    <ListItem button >
                                        <ListItemIcon>{<IconSetting/>}</ListItemIcon>
                                        <ListItemText primary={'修改密码'} />
                                    </ListItem>
                                </a>

                                <ListItem button onClick={this.props.onLogout}>
                                    <ListItemIcon>{<ExitIcon/>}</ListItemIcon>
                                    <ListItemText primary={'退出登录'} />
                                </ListItem>*/}

                            </List>
                        </div>
                    </div>
                </SwipeableDrawer>
            </header>
        );
    }
};

HomeHeader.defaultProps = {
    isAuthenticated: false,
    isMiner: false,
    isBC: false,
}
HomeHeader.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isMiner: state.auth.isMiner,
        isBC: state.auth.isBC,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => {
            dispatch(logout({}))
        }
    }
};

const siteHeaderWithStyles = withStyles(styles)(connect(mapStateToProps, {})(SiteHeader));
const homeHeaderWithStyles = withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(HomeHeader));
export {siteHeaderWithStyles as SiteHeader, homeHeaderWithStyles as HomeHeader};
