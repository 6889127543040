import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

//系统盘制作教程

const Hints = () => {
    const contentMD = `## PCDN安装教程
### 简介
> PCDN是Pear团队推出的一款支持Windows平台的Fog CDN端点，通过对计算、网络和I/O参数充分调优，可让资源贡献者跑出最佳的利用率，获得超高的回报。


### 安装过程：
1. 下载安装包
   - [下载链接](https://download.openfogos.com/pcdn_windows/pcdn-20200916.zip) 
   
2. 右键pcdn.zip解压到pcdn

   ![系统盘快速分区.png](/pcdn/pcdn-1.png)
3. 解压完成后双击pcdn.exe，弹出安装窗口

    ![系统盘快速分区.png](/pcdn/pcdn-2.png)
4. 点击按钮“立即安装”，会弹窗显示“正在安装”，点击确定即可

    ![系统盘快速分区.png](/pcdn/pcdn-3.png)
5. 安装完成，点击“立即注册”，弹窗显示正在跳转，点击确定即可

    ![系统盘快速分区.png](/pcdn/pcdn-4.png)
6. 跳转至官网：https://i.openfogos.com/ 进行注册，即可开始成为节点会员

    ![系统盘快速分区.png](/pcdn/pcdn-5.png)

    `;

    return (
        <div className="Advice" >
            <ReactMarkdown source={contentMD}/>
        </div>
    )
}

export default Hints;