import React from 'react';
import {Title} from "../modules/utils/title";
import {BillHistoryListItem} from "../modules/utils/listItem";
import SlideForMore from "../modules/utils/slideForMore";
import {cashToRMB} from "../modules/utils/functions";

export default function MyBill(props) {
  return (
    <div>
      <Title>账单</Title>
      <SlideForMore getAPI={(from, to)=>process.env.REACT_APP_NMSAPI_SERVER + `/cash/history?from=${from}&to=${to}`} render={(v,i)=><BillHistoryListItem key={i} title={v.comment||""} timestamp={v.time} amount={cashToRMB(v.cash)} />}/>
    </div>
  )
}

