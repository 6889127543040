import React, { useState, useEffect } from 'react';
//import { view as UserInfo } from '../modules/user/info/'
import {view as UserCoin} from '../modules/user/coin/'
import {view as UserToken} from '../modules/user/token/'
import Assets from '../modules/utils/displayAssets'
import Announce from '../modules/utils/announce'
import {WithdrawListItem as ListItem} from '../modules/utils/listItem'
import {view as NodeList} from '../modules/node/list/'
import {view as UserStore} from '../modules/user/store'
import {view as Invite} from '../modules/user/invite'
import Blue from "@material-ui/core/colors/blue";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider'
import {confirm, messageError, messageOk, messageWarning} from "../modules/utils/actions";
import {connect} from "react-redux";
import {minerGet, bcGet} from "../modules/auth/actions";
import Typography from "@material-ui/core/Typography";
import RightArrowIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import {
  addCollectionMethod, addWithdrawRequest,
  checkWithdrawLog,
  getCash,
  getCollectionMethod, getOpenid,
  getWithdrawLogs, resetOpenid,
  setCollectionMethodType
} from "../modules/userMine/actions";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import {Title} from "../modules/utils/title";
import Tab from "../modules/utils/tab";
import Table from "../modules/utils/table";
import {BasicNodeListItem, LinkListItem, ActionListItem, InviteeListItem} from "../modules/utils/listItem";
import {reducerStatus} from "../modules/userMine/reducer";
import moment from "moment";
import {BasicNodeDetail} from "../modules/utils/nodeDetail";
import {fetchTotal, fetchInfo, setLimit, setNickname, deleteMac, getInviterNodes} from "../modules/node/actions";
import {cashToRMB} from "../modules/utils/functions";

function MyReward(props) {
  const {onRequire, onSetNickname, onDelete, confirm, list, total, setLimit, inviterNodes} = props;
  const [openedNodeInfo, setOpenedNodeInfo] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(()=>{
    onRequire(page, rowsPerPage);
  }, []);

  const myNodesTableParam = {
    list: list.map(v=>({
      ...v,
      mac: v.mac_addr,
      online: v.online,
      approved: (v.approved||0) === 3,
      onlineTime: v.online_time || 0,
      rewardYesterday: cashToRMB(v.reward_yesterday),
      moreReward: cashToRMB(v.reward_yesterday) - cashToRMB(v.reward_ideal),
      cpu_cores: v.cpu_cores || 0,
      nodeLimit: {
        storage: v.limit_storage || 100,
        bandwidth: v.limit_bandwidth || 100,
        memory: v.limit_memory || 100,
        cpu: v.limit_cpu || 0,
      }
    })),
    loading: false,
    total: total,
    page: page,
    length: rowsPerPage,
    onPageChange: (newValue)=>{
      setPage(newValue);
      onRequire(newValue, rowsPerPage);
    },
    onRowsPerPageChange:(newValue)=>{
      setPage(0);
      setRowsPerPage(newValue);
      onRequire(0, newValue);
    },
    render: (v)=><BasicNodeListItem nodeInfo={v} key={v.mac} onClick={()=>setOpenedNodeInfo(v)}/>
  };
  const inviteeTableParam = {
    list: inviterNodes.map(v=>({
      userName: v.user_name,
      nodes: v.node_num,
      rewardYesterday: cashToRMB(v.inviter_reward_yesterday)
    })),
    loading: false,
    render: (v)=><InviteeListItem nodeInfo={{...v}} key={v.userName} />
  };
  const bind_node_url = "http://118.25.127.105:8001/?p=" + localStorage.getItem('openfogos_userphone') + "&name=" + localStorage.getItem('openfogos_username') + "&src=" + window.location.href;

  return (
    <div>
      <Title>闲小节点</Title>
      <Announce title={"温馨提示"} abstract={"请尽量保持节点常在线，连续不在线2天，会被禁止调度1个月"}>
        <Typography variant="body1" paragraph>
          请尽量保持节点常在线，连续不在线2天，会被禁止调度1个月
        </Typography>
      </Announce>
      <Tab
        data={inviterNodes.length > 0?[
          {
            label: "我的节点",
            content: <Table {...myNodesTableParam}/>
          },
          {
            label: "推广节点",
            content: <Table {...inviteeTableParam}/>
          },
        ]:[
          {
            label: "我的节点",
            content: <Table {...myNodesTableParam}/>
          }
        ]}
      />
      <BasicNodeDetail open={openedNodeInfo != null} onClose={()=>setOpenedNodeInfo(null)} nodeInfo={openedNodeInfo} onSaveLimit={(nodeLimit)=>setLimit(nodeLimit, page, rowsPerPage)}
                       onSetNickname={(mac, nickname)=>onSetNickname(mac, nickname)} onDelete={(mac) => confirm(`确定删除该节点？`, () => {onDelete(mac, page, rowsPerPage);setOpenedNodeInfo(null)}, false)}/>
      <Title>添加节点</Title>
      <LinkListItem title="绑定本地节点" subtitle="将手机或电脑连接到节点所在的局域网，即可自动搜索绑定" useHref={true} href={bind_node_url} target='_self'/>
      <LinkListItem title="绑定远程节点" subtitle="输入节点的 MAC 地址和 NodeID，即可绑定远程节点" to="/home/bind"/>
      {/*<ActionListItem title="复制邀请链接" subtitle="朋友通过链接注册账号后，绑定的节点会成为你的推广节点" onClick={f=>f}/>*/}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    list: state.node.info||[],
    inviterNodes: state.node.inviterNodes||[],
    total: state.node.totalNode ||0,
    loading: (state.node.infoStatus === 'loading') ? true : false,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: (page, pageLength) => {
      dispatch(fetchInfo(page + 1, pageLength))
      dispatch(fetchTotal());
      dispatch(getInviterNodes());
    },
    setLimit: (data, page, pageLength) => {
      dispatch(setLimit({data,
        success: () => {
          dispatch(messageOk("修改成功"));
          dispatch(fetchInfo(page + 1, pageLength))
          dispatch(fetchTotal())
        },
        error: () => {
          dispatch(messageError('修改配置失败'));
        }
      }));
    },
    onSetNickname: (mac, nickname) => {
      dispatch(setNickname(mac, nickname, {
        success: () => {
          dispatch(messageOk('设置成功'));
        },
        error: () => {
          dispatch(messageError('修改失败'));
        }
      }))
    },
    onDelete: (mac, page, pageLength) => {
      dispatch(deleteMac(mac,
        {
          success: () => {
            dispatch(messageOk("删除成功"));
            dispatch(fetchInfo(page + 1, pageLength))
            dispatch(fetchTotal())
          },
          error: () => {
            dispatch(messageError('删除失败'));
          }
        }));
    },
    confirm: (message, callback, hasVcode) => {
      dispatch(confirm(message, callback, hasVcode))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyReward);
