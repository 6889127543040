import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import {
    fetchInfo,
    searchMac,
    fetchTotal,
    showModal,
    hideModal,
    setNickname,
    fetchMinerBW,
    fetchMinerMacs, fetchMacInfo
} from '../actions'
import copy from 'copy-to-clipboard';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Edit from '@material-ui/icons/Edit';
import Power from '@material-ui/icons/PowerTwoTone';
import PowerOff from '@material-ui/icons/PowerOffTwoTone';
import SmileIcon from '@material-ui/icons/SentimentVerySatisfied';
import SadIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import IconList from '@material-ui/icons/List';
import moment from 'moment';
import IconCircleOutline from '@material-ui/icons/AddCircleOutline';
import {messageOk, messageError} from "../../utils/actions";
import classNames from "classnames";
import green from '@material-ui/core/colors/green';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import AreaChart from 'recharts/lib/chart/AreaChart';
import Area from 'recharts/lib/cartesian/Area';
import ChartIcon from '@material-ui/icons/ShowChart';
import { CSVLink, CSVDownload } from "react-csv";
import DownloadIcon from '@material-ui/icons/CloudDownload';

import echarts from 'echarts/lib/echarts';
// 引入柱状图
import  'echarts/lib/chart/bar';
import  'echarts/lib/chart/line';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';



//const Search = Input.Search

const styles = theme => ({
    root: {
        // overflowX: 'auto',
    },
    table: {
        overflowX: 'auto',
    },
    tableCell: {
        paddingRight: '20px',
        paddingLeft: '0px',
        paddingTop: '5px',
        paddingBottom: '0px'
    },
    leftButton: {
        marginRight: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    downloadIcon: {
        marginLeft: theme.spacing(1),
        marginBottom: "7px"
    },
    verticalMiddle: {
        verticalAlign:'middle'
    },
    verticalBottom: {
        verticalAlign:'bottom'
    },
    power: {
        // color: '#a68540',
        // color: green['A700']
    },
    mac: {
        // color: '#898989',
        marginTop: '4px'
    },
    golden: {
        color: '#a68540',
    },
});

class NodeList extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {

    }
  }


  render() {
      const {classes, bw, platformMacs, macInfo} = this.props;
      const today = moment().format('YYYYMMDD');
      console.log("today", today);

      var datas = [];
      var dataPlatforms = [];
      var platforms = [];
      var nodeStatistics = {};
      if(bw[today]){
          let allData = bw[today];
          for(let i in allData){
              dataPlatforms.push(i);
              var platformData = allData[i].filter((value)=> value>0).map((value, index)=>({
                  name: moment(index * 300000 + 1553616000000).format('HH:mm'),
                  BW: value,
              }));
              datas.push(platformData)
          }
      }
      for(let i in platformMacs){
          platforms.push(i);
              let macInfos = macInfo[i];//节点信息列表
              let ok = [];
              let bad = [];
              let offline = [];
              (macInfos||[]).forEach((node)=>{
                  if(node.online){
                      if(node.online_time >= 20){
                          ok.push(node);
                      }else{
                          bad.push(node);
                      }

                  }else{
                      offline.push(node);
                  }
              });
              nodeStatistics[i] = {
                  ok:ok,
                  bad: bad,
                  offline: offline
              }

      }

      const csvHeaders = [
          { label: "MAC地址", key: "mac_addr" },
          { label: "公网IP", key: "public_ip" },
          { label: "省份", key: "province" },
          { label: "运营商", key: "isp" },
          { label: "CPU核数", key: "cpu_cores" },
          { label: "剩余内存(MB)", key: "mem_free" },
          { label: "版本", key: "version" },
          { label: "硬盘大小（GB）", key: "storage_total" },
          { label: "剩余空间（GB）", key: "storage_avail" },
          { label: "在线", key: "online" },
          { label: "平台号", key: "platform" },
          { label: "上行带宽（Mbps）", key: "upload_bw" },
          { label: "开启挖矿", key: "open_mine" },
          { label: "昨日流量（B）", key: "traffic_yesterday" },
          { label: "昨日在线时长（h）", key: "online_time" },
          { label: "HTTP可连通", key: "http" },
      ];

    return (
      <div className={classes.root} >
          <Typography gutterBottom variant='h6'>
              <IconList className={classNames(classes.verticalMiddle, classes.leftIcon)}/><span className={classes.verticalMiddle}>节点状态统计</span>
          </Typography>
          <div className={classes.table}>
              <Table>
                  <TableHead>
                      <TableRow>
                          <TableCell className={classes.tableCell}>平台号</TableCell>
                          <TableCell className={classes.tableCell} align="right">节点数</TableCell>
                          <TableCell className={classes.tableCell} align="right">正常</TableCell>
                          <TableCell className={classes.tableCell} align="right">异常</TableCell>
                          <TableCell className={classes.tableCell} align="right">离线</TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {(platforms || []).map((row, index) => (
                          <TableRow key={index}>
                              <TableCell className={classes.tableCell} align="left">
                                  <Typography variant="caption">{row}</Typography>
                              </TableCell >
                              <TableCell className={classes.tableCell} align="right">
                                  {platformMacs[row].length}
                              </TableCell >
                              <TableCell className={classNames(classes.tableCell, classes.verticalMiddle)} align="right">{nodeStatistics[row].ok.length}<CSVLink headers={csvHeaders} data={nodeStatistics[row].ok} filename={row + "-正常节点.csv"}><DownloadIcon className={classNames(classes.golden, classes.verticalMiddle, classes.downloadIcon)}/></CSVLink></TableCell>
                              <TableCell className={classes.tableCell} align="right">{nodeStatistics[row].bad.length}<CSVLink headers={csvHeaders} data={nodeStatistics[row].bad} filename={row + "-异常节点.csv"}><DownloadIcon className={classNames(classes.golden, classes.verticalMiddle, classes.downloadIcon)}/></CSVLink></TableCell>
                              <TableCell className={classes.tableCell} align="right">{nodeStatistics[row].offline.length}<CSVLink headers={csvHeaders} data={nodeStatistics[row].offline} filename={row + "-离线节点.csv"}><DownloadIcon className={classNames(classes.golden, classes.verticalMiddle, classes.downloadIcon)}/></CSVLink></TableCell>
                              {/*<TableCell align="right" className={classes.tableCell}>{row.storage_total ? (row.storage_total > 1024 * 1024 ? (row.storage_total / 1024 / 1024).toFixed(1) + 'TB' :(row.storage_total / 1024).toFixed(1) + 'GB') : '未知'}</TableCell>*/}
                          </TableRow>
                      ))}
                  </TableBody>
              </Table>
          </div>



              <Typography gutterBottom variant='h6' style={{marginTop:"20px"}}>
                  <ChartIcon className={classNames(classes.verticalMiddle, classes.leftIcon)}/><span className={classes.verticalMiddle}>当天带宽统计</span>
              </Typography>
          {dataPlatforms.map((platform, index)=><div key={index}>
              <Typography gutterBottom variant='subtitle1'>
                  {platform}
              </Typography>
              <ResponsiveContainer width="100%" height={320}>
                  <AreaChart data={datas[index]}>
                      <defs>
                          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="10%" stopColor="#a68540" stopOpacity={0.8}/>
                              <stop offset="100%" stopColor="#a68540" stopOpacity={0}/>
                          </linearGradient>
                      </defs>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <CartesianGrid vertical={false} strokeDasharray="3 3" />
                      <Tooltip />
                      <Area type="monotone" dataKey="BW" stroke="#a68540" fillOpacity={1} fill="url(#colorUv)" />
                  </AreaChart>
              </ResponsiveContainer>



          </div>)}

          {/*<ResponsiveContainer width="100%" height={320}>
              <AreaChart data={data}>
                  <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="10%" stopColor="#a68540" stopOpacity={0.8}/>
                          <stop offset="100%" stopColor="#a68540" stopOpacity={0}/>
                      </linearGradient>
                      <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                      </linearGradient>
                  </defs>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid vertical={false} strokeDasharray="3 3" />
                  <Tooltip />
                  <Area type="monotone" dataKey="Visits" stroke="#a68540" fillOpacity={1} fill="url(#colorUv)" />
              </AreaChart>
          </ResponsiveContainer>*/}
              {/*<Table {...tableParam} />*/}
      </div>
    )
  }
  componentDidMount() {
      console.log("componentDidMount");
          this.props.onRequire(this.state.page + 1, this.state.rowsPerPage);
  }
  componentDidUpdate(){
      console.log("componentDidUpdate");
      // this.props.onUpdate(this.props.platformMacs);
  }
}

NodeList.propTypes = {
    classes: PropTypes.object.isRequired,
};

NodeList.defaultProps = {
    bw: {},
    platformMacs: {},
    macInfo: {},
    fetchLoading: true
}

const mapStateToProps = (state) => {
  return {
    bw: state.miner.bw,
      platformMacs: state.miner.macs,
      macInfo: state.miner.macInfo,
    fetchLoading: (state.miner.bwStatus === 'loading') ? true : false,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onRequire: (page, pageLength) => {
      dispatch(fetchMinerBW());
      dispatch(fetchMinerMacs({success:(data)=>{
          console.log("success data: ", data);
              for(let i in data){
                  dispatch(fetchMacInfo({
                      data: {
                          mac_addrs: data[i],
                          platform: i
                      }
                  }));
              }
          }}));
    },
      messageOk: (str) => {
        dispatch(messageOk(str));
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeList))
