import React from 'react'
import copy from "copy-to-clipboard/index";
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import {withStyles} from "@material-ui/core/styles/index";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconCash from '@material-ui/icons/GroupAdd';
import IconCopy from '@material-ui/icons/FileCopyOutlined';
import {messageError, messageOk, confirm} from "../../utils/actions";
import {connect} from "react-redux";
import classNames from "classnames";
import {reducerStatus} from "../reducer";
import {getCash, getUserMine, deleteUserMine, setName, resubmitUserMine, getInviteCode, getInviteNodes, getCashHistory} from "../actions";
import {fetchCertificationStatus} from  '../../user/actions';
import PearTable from "../../utils/table";
import Edit from '@material-ui/icons/Edit';
import Paper from '@material-ui/core/Paper';
import IconPRC from '@material-ui/icons/LocalAtm';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import blue from "@material-ui/core/colors/blue";
import red from "@material-ui/core/colors/red";
import withMobileDialog from "@material-ui/core/withMobileDialog/index";
import RewardHistory from "./rewardHistory";
import Chip from '@material-ui/core/Chip';
import SubmitIcon from '@material-ui/icons/AssignmentOutlined';
import './animation.css';
import Tab from "../../utils/tab";
import Table from "../../utils/table";
import {Title} from "../../utils/title";
import {ActionListItem, InviteeListItem, LinkListItem, MineNodeListItem} from "../../utils/listItem";
import {MineNodeDetail} from "../../../modules/utils/nodeDetail";

const styles = theme => ({
    root: {
        // marginTop: theme.spacing( 2),
    },
    button: {
        width: '100%',
        size: 'medium',
    },
    input: {
        width: '100%',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    verticalMiddle: {
        verticalAlign: 'middle'
    },
    tablePaper: {
        // marginTop: theme.spacing( 1),
        overflowX: 'auto',
        position: 'relative',
        top: '-13px'
    },
    rewardBox: {
        width: "300px",
        marginTop: "60px",
        marginBottom: "30px"
    },
    link: {
        color: blue[500],
        verticalAlign: 'bottom'
    },
    certificationSpan: {
        fontSize: '.75rem',
        margin: "0 1em .3em",
        padding: '.3em',
        borderRadius: '.2em',
        border: '1px solid',
        whiteSpace: 'no-wrap'
    },
    spanButton: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        color: blue[500]
    },
    spanButtonRed: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        color: red[500]
    },
    leftButton: {
        marginRight: theme.spacing(1),
    },
    inviteButton: {
        marginLeft: theme.spacing(1),
    },
    '@keyframes row': {
        from: {opacity: 0},
        to: {opacity: 1}
    },
    chipRoot: {
        width: "100%",
        display: "flex",
        overflow: "hidden"
    },
    chipLabel: {
        flexGrow: 1,
        flexShrink:1,
        overflow: "hidden",
        margin: "0 12px",
    },
    chipLabelContent: {
        overflow: "visible",
        position: "relative",
        animation: "roll 20s linear infinite",
        "&:hover": {
            animationPlayState: "paused"
        }
    },
    chipButton: {
        flexGrow: 0,
        flexShrink:0
    },
    notice: {
        margin: theme.spacing(2),
        // padding: '.5em',
        // border: '.5em solid transparent',
        // background: 'linear-gradient(white, white) padding-box, repeating-linear-gradient(-45deg, red 0, red 12.5%, #eee 0, #eee 25%, #58a 0, #58a 37.5%, #eee 0, #eee 50%) 0/ 5em 5em'
    }
});

const configStatusMap = {
    0: "待配置",
    1: "配置中",
    2: "配置成功",
    3: "配置失败"
};
const approveStatusMap = {
    0: "未验收",
    1: "验收中",
    2: "验收通过",
    3: "验收不通过"
};

const certificationStatusMap = {
    0: "未提交实名认证",
    1: "实名认证通过",
    2: "实名认证审核中",
    3: "实名认证失败",
};

class NodeManage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            setNicknameID: 0,
            nickname: "",
            nicknameDirty: false,
            rewardHistoryOpen: false,
            rewardHistoryFrom: 0,
            rewardHistoryTo: 9,
            noticeOpen: true,
            detailOpen: false,
            openedNodeInfo: null,
        };
        this.openSetNickname = this.openSetNickname.bind(this);
        this.closeSetNickname = this.closeSetNickname.bind(this);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.setNickname = this.setNickname.bind(this);
        this.nicknameOnchange = this.nicknameOnchange.bind(this);
        this.onOpenRewardHistory = this.onOpenRewardHistory.bind(this);
        this.onCloseRewardHistory = this.onCloseRewardHistory.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
        this.openDetail = this.openDetail.bind(this);
        this.closeDetail = this.closeDetail.bind(this);
    }

    openSetNickname = (id, nickname) => {
        this.setState({
            setNicknameID: id,
            nickname: nickname,
            nicknameDirty: false,
        })
    };

    closeSetNickname = () => {
        this.setState({
            setNicknameID: 0
        })
    };

    openDetail = (nodeInfo) => {
        this.setState({
            detailOpen: true,
            openedNodeInfo: nodeInfo
        })
    };
    closeDetail = () => {
        this.setState({
            detailOpen: false,
            openedNodeInfo: null
        })
    };

    onKeyUp(event) {
        if(event.keyCode === 13){
            console.log('press enter!');
            this.setNickname();
        }
    }
    onBlur() {
        console.log('onBlur');
        this.setNickname();
    }

    setNickname() {
        if(this.state.nicknameDirty){
            this.props.onSetNickname(this.state.setNicknameID, this.state.nickname);
        }
        this.closeSetNickname();
    }

    nicknameOnchange(event) {
        this.setState({
            nickname: event.target.value,
            nicknameDirty: true,
        })
    }
    copyText(text) {
        copy(text);
        this.props.messageOk("复制成功");
    }

    onOpenRewardHistory = () => {
        this.setState({
            rewardHistoryOpen: true,
        });
        if((this.props.rewardHistoryList||[]).length < 1){
            this.props.onGetCashHistory(0, 9);
        }
    };

    onCloseRewardHistory = () => {
        this.setState({
            rewardHistoryOpen: false,
        });
    };

    onLoadMore = () => {
        if(!this.props.rewardHisrotyLoading){
            this.props.onGetCashHistory(this.props.rewardHistoryList.length, this.props.rewardHistoryList.length + 9);
        }
    }

    render() {
        const {classes, cash, userMineList, certificationStatus, inviteCode, inviteNodes, fullScreen, rewardHistoryList, rewardHisrotyLoading} = this.props;
        const titleProps = {
            gutterBottom: true,
            align: 'left',
            variant: 'h4',

        };
        const buttonConvertBalanceParam = {
            variant: 'contained',
            color:'primary',
            size: 'medium',
            style: { background: 'linear-gradient(135deg,  #E9C85F, #cfae51, #a68540)' },//#cfae51,
        };
        const buttonHistoryParam = {
            variant: 'outlined',
            color:'primary',
            size: 'medium',
            onClick: this.onOpenRewardHistory,
        };

        let sortedInviteNodes = [...inviteNodes];
        sortedInviteNodes.sort((a,b)=>(b.inviter_reward_yesterday||0)-(a.inviter_reward_yesterday||0))

        let totalRewardYesterday = userMineList.reduce((total, node) => {
            return total + (node.reward_yesterday || 0)
        }, sortedInviteNodes.reduce((total, node) => {
            return total + ((node.reward_status === 5)? 0 : (node.inviter_reward_yesterday || 0))
        }, 0));

        const tableParam = {
            list: userMineList,
            columns: [
                {
                    title: "序号",
                    key: "id",
                    render: (value, index) => ((index || 0) + 1)
                },
                {
                    title: "备注名",
                    key: "nickname",
                    render: (value, index, arr) => (
                        this.state.setNicknameID === arr[index].id ?
                            <Input maxLength={20} autoFocus value={this.state.nickname} onChange={this.nicknameOnchange}
                                   onKeyUp={this.onKeyUp} onBlur={this.onBlur}/> :
                            <span className={classes.verticalMiddle}>{value || "设置别名"}{<Edit
                                style={{fontSize: 16}} className={classNames(classes.icon, classes.verticalMiddle)}
                                color="action" onClick={() => {
                                this.openSetNickname(arr[index].id || 0, arr[index].nickname||"")
                            }}/>}</span>
                    )
                },
                {
                    title: "SSH地址",
                    key: "ssh",
                    render: (value) => (value || "")
                },
                {
                    title: "昨日预估收益",
                    key: "reward_yesterday",
                    render: (value, index, arr) => ((value||0) < 0 || ((value||0) === 0 && arr[index].reward_status === 5))?"结算中" : `${(value || 0) / 100} 元`
                },
                {
                    title: "累计收益（含预估）",
                    key: "reward_total",
                    render: (value) => `${(value || 0) / 100} 元`
                },
                {
                    title: "配置状态",
                    key: "config_status",
                    render: (value) => (configStatusMap[value || 0])
                },
                {
                    title: "验收状态",
                    key: "approve_status",
                    render: (value) => (approveStatusMap[value || 0])
                },
                {
                    title: "工程师备注",
                    key: "comment",
                    render: (value) => (value || "")
                },
                {
                    title: "操作",
                    key: "config_status",
                    render: (value, index, list)=>(<span>
                        <Link className={classes.spanButton} to={{pathname: "/home/modify_node", state: {...list[index]}}}>修改配置&ensp;</Link>
                        {value === 3 && <span className={classes.spanButton} onClick={() => this.props.onResubmit(list[index].id || 0)}>重新提交&ensp;</span>}
                        <span className={classes.spanButtonRed} onClick={() => this.props.confirm(`确定删除矿机？该操作无法撤回`,() => this.props.onDelete(list[index].id || 0), false)}>删除&ensp;</span>
                    </span>)
                }
            ]
        };
        const inviteNodesTableParam = {
            list: sortedInviteNodes,
            columns: [
                {
                    title: "序号",
                    key: "id",
                    render: (value, index) => ((index || 0) + 1)
                },
                {
                    title: "用户名",
                    key: "user_name"
                },
                {
                    title: "昨日预估推广收益",
                    key: "inviter_reward_yesterday",
                    render: (value, index, arr) => (arr[index].reward_status === 5)?"结算中" : `${(value || 0) / 100} 元`
                },
                {
                    title: "累计收益（含预估）",
                    key: "inviter_reward_total",
                    render: (value) => `${(value || 0) / 100} 元`
                },
                {
                    title: "配置状态",
                    key: "config_status",
                    render: (value) => (configStatusMap[value || 0])
                },
                {
                    title: "验收状态",
                    key: "approve_status",
                    render: (value) => (approveStatusMap[value || 0])
                },
            ]
        };
        const rewardHistoryParam = {
            open: this.state.rewardHistoryOpen,
            fullScreen: fullScreen,
            onCancel: this.onCloseRewardHistory,
            list: rewardHistoryList,
            loading: rewardHisrotyLoading,
            loadMore: this.onLoadMore,
        };
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const thisMonth = yesterday.getMonth() + 1;
        yesterday.setDate( -1);
        const lastMonth = yesterday.getMonth() + 1;
        const myNodesTableParam = {
            list: userMineList.map(v => ({
                ...v,
                ip: v.ssh||"",
                online: v.online,
                approved: (v.approve_status||0) === 2,
                onlineTime: v.online_time||0,
                rewardYesterday: (v.reward_yesterday||0)/100,
                totalReward: (v.reward_total||0)/100,
                waiting: (v.reward_yesterday||0) < 0 || ((v.reward_yesterday||0) === 0 && v.reward_status === 5)//结算中
            })),
            loading: false,
            render: (v)=><MineNodeListItem nodeInfo={v} key={v.ip} onClick={()=>this.openDetail(v)}/>
        };
        const inviteeTableParam = {
            list: sortedInviteNodes.map(v=>({
                ...v,
                userName: v.user_name||"",
                nodes: (v.macs||[]).length,
                rewardYesterday: (v.inviter_reward_yesterday||0)/100,
                totalReward: (v.inviter_reward_total||0)/100
            })),
            loading: false,
            render: (v)=><InviteeListItem nodeInfo={v} key={v.userName} />
        };
        return (
            <div>
                {
                    // this.state.noticeOpen && <Chip style={{width: "100%"}} classes={{label: classes.chipLabel, deleteIcon: classes.chipButton}} label={<span className={classes.chipLabelContent}>通知：2020年8月1日起，矿机设备收益由日结改为月结, 每月3日结算上一个月的收益，每天可以查看预估收益；提现时间改为每月4~6号（9:00~17:00）；提现方式仅支持支付宝。</span>} onDelete={()=>this.setState({noticeOpen: false})} color="primary" />
                }
                <div className={classes.notice}>
                    <Typography variant="h5" gutterBottom>
                        通知
                    </Typography>
                    {/*<Typography variant="body1" paragraph>
                    &emsp;&emsp;梨享为最早落地共享雾计算业务的企业，过程中开拓了小散、矿机、联营、自建等多种不同的节点资源合作模式，同时因为历史原因，不同模式下并存的日结、周结、月结、协议等结算和支付方式给公司的财务和内审团队带来了巨大的工作负荷与压力。为缓解此方面引起的压力与混乱，自2020年8月1日起，参考金融领域一些著名基金理财产品的运营经验，矿机设备收益改为月结，贡献者仍可看到每日预估收益，每月15日结算上个月的收益，16-19日9:00--17:00可提现，并在接下来1~2个工作日内审核后自动到账，简便起见提现通道暂只支持支付宝。
                </Typography>
                <Typography variant="body1" paragraph>
                    &emsp;&emsp;不久的将来，闲小节点模式也将归并于按月提现规则，当然也会相应放开单次提现金额限制。
                </Typography>
                <Typography variant="body1" paragraph>
                    &emsp;&emsp;待此统一阶段规则走顺后，即逐步开放微信、银联等其它提现通道。
                </Typography>
                <Typography variant="body1" paragraph>
                    &emsp;&emsp;祝愿大家更好收益！
                </Typography>*/}
                    <Typography variant="body1" paragraph>
                        &emsp;&emsp;近期需求缺口：<br/>
                        &emsp;&emsp;华北移动10G；华南联通5G<br/>
                        &emsp;&emsp;不分地区的：电信35G；移动20G；联通20G<br/>
                        &emsp;&emsp;都是利用率100%附近的高质量业务！请赶在元旦前后上机！<br/>
                        &emsp;&emsp;另公司有高性能服务器部件及大容量固态硬盘库存，可选购或租用，或者探讨其它融资租赁类合作模式。<br/><br/>

                        &emsp;&emsp;如有相应资源，请联系：shu@pear.hk<br/>
                    </Typography>
                    <Typography variant="body2" align="right">
                        OpenFogOS 团队{<br/>}
                        2020年12月21日
                    </Typography>
                </div>

{/*通知：*/}
{/*梨享为最早落地共享雾计算业务的企业，过程中开拓了小散、矿机、联营、自建等多种不同的节点资源合作模式，同时因为历史原因，不同模式下并存的日结、周结、月结、协议等结算和支付方式给公司的财务和内审团队带来了巨大的工作负荷与压力。为缓解此方面引起的压力与混乱，自2020年8月1日起，参考金融领域一些著名基金理财产品的运营经验，矿机设备收益改为月结，贡献者仍可看到每日预估收益，每月15日结算上个月的收益，16-19日9:00--17:00可提现，并在接下来1~2个工作日内审核后自动到账，简便起见提现通道暂只支持支付宝。*/}

{/*不久的将来，闲小节点模式也将归并于按月提现规则，当然也会相应放开单次提现金额限制。*/}

{/*待此统一阶段规则走顺后，即逐步开放微信、银联等其它提现通道。*/}

{/*祝愿大家更好收益！*/}
                <Grid container  justify="space-around">
                    <Grid item>
                        <div className={classes.rewardBox} >
                            <Typography {...titleProps}>
                                当前余额
                                <Link to={'/home/certification'} className={classes.certificationSpan}>
                                    {certificationStatusMap[certificationStatus]}
                                </Link>
                            </Typography>
                            <Typography {...titleProps} variant="body1">
                                {(cash.cash || 0) / 100}元
                            </Typography>
                            <Typography variant="body2" align='left' gutterBottom>
                                <Link to={'/home/large_withdraw_log'} className={classes.link}>
                                    <Button {...buttonConvertBalanceParam} ><IconPRC className={classes.leftIcon}/>提现</Button>
                                </Link>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.rewardBox}>
                            <Typography {...titleProps}>
                                昨日预估收益
                            </Typography>
                            <Typography {...titleProps} variant="body1">
                                {(totalRewardYesterday || 0) / 100}元
                            </Typography>
                            <Typography variant="body2" align='left' gutterBottom>
                                <Button {...buttonHistoryParam} >收入历史</Button>

                            </Typography>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.rewardBox}>
                            <Typography {...titleProps} variant="subtitle1">
                                {thisMonth}月预估收益 {(cash.expected_this_month || 0) / 100}元
                            </Typography>
                            <Typography {...titleProps} variant="subtitle1">
                                {lastMonth}月预估收益 {(cash.expected_last_month || 0) / 100}元
                            </Typography>
                            <Typography {...titleProps} variant="subtitle1">
                                {lastMonth}月实发收益 {(cash.delivered_last_month || 0) < 0 ? "待结算":`${(cash.delivered_last_month || 0) / 100}元`}
                            </Typography>


                        </div>
                    </Grid>
                </Grid>
                {/*<Typography {...titleProps} variant="h5">我的节点
                    <Link to="/home/submit_node">
                        <Button className={classes.inviteButton} color="primary" size="small" variant="contained"><SubmitIcon className={classes.leftButton}/>提交新节点</Button>
                    </Link>
                </Typography>
                <div className={classes.tablePaper} >
                    <PearTable {...tableParam}/>
                </div>
                <div style={{marginTop: 24, display: sortedInviteNodes.length > 0?"block":"none"}}>
                    <Typography {...titleProps} variant="h5">我的推广节点
                        <Button style={{display: inviteCode?"inline-flex":"none"}} className={classes.inviteButton} color="primary" size="small" variant="contained" onClick={()=>this.copyText(`https://openfogos.com?usermine_inviter=${inviteCode}`)}><IconCopy className={classes.leftButton}/>复制邀请链接</Button>
                    </Typography>
                    <div className={classes.tablePaper} >
                        <PearTable {...inviteNodesTableParam}/>
                    </div>
                </div>*/}
                <RewardHistory {...rewardHistoryParam} />

                <Tab
                  data={sortedInviteNodes.length > 0?[
                      {
                          label: "我的节点",
                          content: <Table {...myNodesTableParam}/>
                      },
                      {
                          label: "推广节点",
                          content: <Table {...inviteeTableParam}/>
                      },
                  ]:[
                      {
                          label: "我的节点",
                          content: <Table {...myNodesTableParam}/>
                      }
                  ]}
                />
                <Title>添加节点</Title>
                <MineNodeDetail open={this.state.detailOpen} onClose={()=>this.closeDetail()} nodeInfo={this.state.openedNodeInfo} onResubmit={(id)=>this.props.onResubmit(id)} onDelete={(id)=>this.props.confirm(`确定删除矿机？该操作无法撤回`,() => this.props.onDelete(id), false)} onSetNickname={(id, nickname)=>this.props.onSetNickname(id, nickname)}/>
                <LinkListItem title="提交自有节点" subtitle="将有专人完成程序部署，验收成功后，即可产生收益" to="/home/submit_node"/>
                {/*<ActionListItem title="复制邀请链接" subtitle="朋友通过链接注册账号后，绑定的节点会成为你的推广节点" onClick={()=>this.copyText(`https://openfogos.com?usermine_inviter=${inviteCode}`)}/>*/}
            </div>
        );

    }

    componentDidMount() {
        this.props.onRequire();
    }
}

NodeManage.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        cash: state.userMine.cash || {},
        getCashStatus: state.userMine.getCashStatus,
        userMineList: state.userMine.userMineList || [],
        inviteNodes: state.userMine.inviteNodes || [],
        inviteCode: state.userMine.inviteCode || "",
        rewardHistoryList: state.userMine.cashHistory || [],
        rewardHisrotyLoading: state.userMine.getCashHistoryStatus === "loading",
        certificationStatus: state.user.certificationStatus ||0,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onRequire: () => {
            dispatch(getCash());
            dispatch(getUserMine());
            dispatch(fetchCertificationStatus());
            dispatch(getInviteCode());
            dispatch(getInviteNodes());
        },
        onResubmit: (id) => {
            dispatch(resubmitUserMine(id, {
                success: () => {
                    dispatch(messageOk('提交成功'));
                    dispatch(getUserMine());
                },
                error: () => {
                    dispatch(messageError('提交失败'));
                }
            }));
        },
        onDelete: (id) => {
            dispatch(deleteUserMine(id, {
                success: () => {
                    dispatch(messageOk('删除成功'));
                    dispatch(getUserMine());
                },
                error: () => {
                    dispatch(messageError('删除失败'));
                }
            }));
        },
        onSetNickname: (id, name) => {
            dispatch(setName(id, name, {
                success: () => {
                    dispatch(messageOk('设置成功'));
                    dispatch(getUserMine());
                },
                error: () => {
                    dispatch(messageError('设置失败'));
                }
            }));
        },
        onGetCashHistory: (from, to) => {
            dispatch(getCashHistory(from, to, {
                error: () => {
                    dispatch(messageError('获取失败'));
                }
            }));
        },
        confirm: (message, callback, hasVcode) => {
            dispatch(confirm(message, callback, hasVcode))
        },
        messageOk: (dataStr) => {
            dispatch(messageOk(dataStr))
        }
    }
}


export default withMobileDialog()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeManage)));
