import React from 'react';
import {makeStyles} from "@material-ui/core/styles/index";
import Typography from '@material-ui/core/Typography';
import DefaultIcon from '@material-ui/icons/DoubleArrow';
import AreaChart from "recharts/lib/chart/AreaChart";
import ComposedChart from "recharts/lib/chart/ComposedChart";
import XAxis from "recharts/lib/cartesian/XAxis";
import YAxis from "recharts/lib/cartesian/YAxis";
import Legend from "recharts/lib/component/Legend";
import CartesianGrid from "recharts/lib/cartesian/CartesianGrid";
import Tooltip from "recharts/lib/component/Tooltip";
import Area from "recharts/lib/cartesian/Area";
import Line from "recharts/lib/cartesian/Line";
import ResponsiveContainer from "recharts/lib/component/ResponsiveContainer";
import CircularProgress from '@material-ui/core/CircularProgress';
import {func} from "prop-types";
//LabelImportant

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: 300,
    // transform: "scale(0.5)"
  },
  tooltip: {
    background: "rgba(255, 255, 255, 0.8)",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2)
  },
  loading: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  }
}));

function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgress/>
    </div>)
}

/**
 * data:[
 * {
 *   label: "00.05",
 *   bw: 13.5,//单位 Mbps
 * }
 * ]
 */
const CustomTooltip = ({bwOnly, maxValue, active, payload, label}) => {
  const classes = useStyles();
  if (active) {
    return (
      payload && <div className={classes.tooltip}>
        <Typography variant="body1">{label}</Typography>
        <Typography variant="body1">{`带宽：${(payload[0].payload.bw).toFixed(maxValue > 1?2:4)} Mbps`}</Typography>
        {!bwOnly && [
          <Typography variant="body1" key={1}>{`在线节点：${payload[0].payload.online}`}</Typography>,
          <Typography variant="body1" key={2}>{`IO读异常：${payload[0].payload.ioRead}`}</Typography>,
          <Typography variant="body1" key={3}>{`IO写异常：${payload[0].payload.ioWrite}`}</Typography>,
          <Typography variant="body1" key={4}>{`IO使用率异常：${payload[0].payload.io}`}</Typography>,
          <Typography variant="body1" key={5}>{`CPU使用率异常：${payload[0].payload.cpu}`}</Typography>,
        ]}
      </div>
    );
  }

  return null;
};

export default function DisplayBandwidth(props) {
  const {data, bwOnly} = props;
  const classes = useStyles();
  const Chart = bwOnly ? AreaChart : ComposedChart;
  var lineColorList = ["#31ef3a", "#ef3131", "#d930de", "#3262cd", "#f87e21"];
  return (
    <div className={classes.root}>
      {data.length ? <ResponsiveContainer>
        <Chart data={data} margin={{
          top: 30, right: bwOnly? 30:0, left: 0, bottom: 0,
        }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="10%" stopColor="#a68540" stopOpacity={0.8}/>
              <stop offset="100%" stopColor="#a68540" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="label"/>
          <YAxis/>
          {!bwOnly && <YAxis yAxisId="right" orientation="right"/>}
          <CartesianGrid vertical={false} strokeDasharray="3 3"/>
          <Tooltip content={<CustomTooltip bwOnly={bwOnly} maxValue={data.reduce((max, v)=>{if(v.bw > max)return v.bw; return max}, 0)}/>}/>
          <Legend/>
          <Area type="monotone" name="带宽" unit=" Mbps" dataKey="bw" stroke="#a68540" fillOpacity={1}
                fill="url(#colorUv)"/>

          {!bwOnly && [
            <Line yAxisId="right" key={1} type="monotone" dataKey="online" name="在线节点" unit=" 个" dot={false} stroke={lineColorList[0]} strokeWidth={1}/>,
            <Line yAxisId="right" key={2} type="monotone" dataKey="ioRead" name="IO读异常" unit=" 次" dot={false} stroke={lineColorList[1]} strokeWidth={1}/>,
            <Line yAxisId="right" key={3} type="monotone" dataKey="ioWrite" name="IO写异常" unit=" 次" dot={false} stroke={lineColorList[2]} strokeWidth={1}/>,
            <Line yAxisId="right" key={4} type="monotone" dataKey="io" name="IO使用率异常" unit=" 次" dot={false} stroke={lineColorList[3]} strokeWidth={1}/>,
            <Line yAxisId="right" key={5} type="monotone" dataKey="cpu" name="CPU使用率异常" unit=" 次" dot={false} stroke={lineColorList[4]} strokeWidth={1}/>
          ]}
        </Chart>
      </ResponsiveContainer> : <Loading/>}
    </div>

  )
}
