import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

// import classNames from 'classnames';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing( 3),
        marginRight: theme.spacing( 3),
        [theme.breakpoints.up(400 + theme.spacing( 3 * 2))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing( 8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing( 2)}px ${theme.spacing( 3)}px ${theme.spacing( 3)}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing( 3),
    },
});

class LoginForm extends Component {
    render() {
        const handleChange = event => {
            this.props.onChange({
                [event.target.name]: {
                    name: [event.target.name], value: event.target.value
                }
            });
            console.log("handleChange", event.target);
        };

        console.log('render username', this.props.username);
        console.log('render password', this.props.password);
        const {classes} = this.props;

        return (
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        OpenFogOS
                    </Typography>
                    <form className={classes.form}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="username">用户名/手机号/邮箱</InputLabel>
                            <Input id="username" name="username" autoComplete="username" autoFocus value={this.props.username ? this.props.username.value : ""} onChange={handleChange}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">密码</InputLabel>
                            <Input name="password" type="password" id="password" autoComplete="current-password" value={this.props.password ? this.props.password.value : ""}
                                   onChange={handleChange}/>
                        </FormControl>
                        {/*<FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />*/}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.props.onSubmit}
                            className={classes.submit}
                        >
                            登录
                        </Button>
                    </form>
                </Paper>
            </main>

                /*<main className={classes.main}>
                    <CssBaseline />
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">Email Address</InputLabel>
                                <Input id="email" name="username" autoComplete="email" autoFocus value={this.props.username ? this.props.username.value : ""}
                                       onChange={handleChange}/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Input name="password" type="password" id="password" autoComplete="current-password" value={this.props.password ? this.props.password.value : ""}
                                       onChange={handleChange}/>
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={this.props.onSubmit}
                                className={classes.submit}
                            >
                                Sign in
                            </Button>
                        </form>
                    </Paper>
                </main>*/



        )
    }
}

const option = {
    onFieldsChange(props, changedFields) {
        props.onChange(changedFields)
    },
    // mapPropsToFields(props) {
    //   return {
    //     ...props
    //   }
    // }
}

export default withStyles(styles)(LoginForm);
