import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const Hints = () => {
    const contentMD = `## 上行带宽如何提速优化？
1. 提升上行带宽速度
   - 一条线路只挂一台机器，多挂会分摊带宽，并不能使总收益提升
   - 升级/更换速率更快的宽带套餐
   - 购买网络运营商的上行带宽提速包
2. 关闭不需要的后台及网页
   - 当不适用电脑或其他设备时，建议关机或者关闭部分已不在使用的网页或程序后台，减少上行带宽占用率`;

    return (
        <div className="Advice" >
            <ReactMarkdown source={contentMD}/>

        </div>
    )
}
Hints.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default Hints;