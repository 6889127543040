import xhr from '../../Xhr'

const API = (id, coinToConvert) => ({
  user: {
    password: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/${id}/password`,
      method: 'POST'
    },
    email: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/${id}/email`,
      method: 'POST'
    },
    phone: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/${id}/mobile`,
      method: 'POST'
    },
    info: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}`,
      method: 'GET'
    },
    coin: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/pear_coin_info`,
      method: 'GET'
    },
    trans_coin: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/pear_coin`,
      method: 'POST'
    },
    trans_balance: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/balance/${coinToConvert}`,
      method: 'GET'
    },
    traffic_yesterday: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/traffic_yesterday`,
      method: 'GET'
    },
    convert_goods: {
      url: process.env.REACT_APP_API_SERVER + `/v1/vdn/owner/${id}/convert_goods`,
      method: 'GET'
    },
      certification_status: {
          url: process.env.REACT_APP_NMSAPI_SERVER + `/user_certification/status`,
          method: 'GET'
      },
      submit_certification: {
          url: process.env.REACT_APP_NMSAPI_SERVER + `/user_certification`,
          method: 'POST'
      },
  }
})

export const updatePassword = ({data, ...event}) => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).user.password,
    action: 'USER/PASSWORD',
    data,
    event
  })
}

export const updateEmail = ({data, ...event}) => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).user.email,
    action: 'USER/EMAIL',
    data,
    event
  })
}

export const updatePhone = ({data, ...event}) => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).user.phone,
    action: 'USER/PHONE',
    data,
    event
  })
}

export const fetchInfo = () => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).user.info,
    action: 'USER/INFO'
  })
}

export const fetchCoin = () => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).user.coin,
    action: 'USER/COIN'
  })
}

export const fetchTrafficYesterday = () => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).user.traffic_yesterday,
    action: 'USER/TRAFFIC_YESTERDAY'
  })
}

export const transCoin = ({data, ...event}) => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid')).user.trans_coin,
    action: 'USER/TRANS_COIN',
    data,
    event
  })
}

export const transBalance = ({data, ...event}) => {
  return xhr({
    api: API(localStorage.getItem('openfogos_userid'), data.coinToConvert).user.trans_balance,
    action: 'USER/TRANS_BALANCE',
    data,
    event
  })
}

export const convertGoods = ({data, ...event}) => {
  let api =  API(localStorage.getItem('openfogos_userid')).user.convert_goods;
  api.url = api.url + "?goods_no=" + data.goods_no + "&data=" + data.data
  return xhr({
    api: api,
    action: 'USER/CONVERT_GOODS',
    event
  })
}

export const fetchCertificationStatus = () => {
    return xhr({
        api: API().user.certification_status,
        action: 'USER/CERTIFICATION/STATUS'
    })
}

export const submitCertification = ({data, ...event}) => {
    return xhr({
        api: API().user.submit_certification,
        action: 'USER/CERTIFICATION/SUBMIT',
        data,
        event
    })
}