import React from 'react';
import ReactMarkdown from 'react-markdown';

//如何本地绑定闲小节点

const Hints = () => {
    const contentMD = `## 矿机节点和优享节点的区别
   |  | 矿机节点 | 优享节点 |
   | --- | --- | --- |
   | 技术支持 | 每次技术支持需收取一定的服务费用 | 享受免费的技术支持 |
   | 收益结算 | 每月15日结算并发放上个月的收益 | 每月15日结算并发放上个月的收益，但是出于冻结状态，下个月1日自动解冻，方可提取 |
   

    `;

    return (
      <div className="Advice" >
          <ReactMarkdown source={contentMD}/>
      </div>
    )
}

export default Hints;