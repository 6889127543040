import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

class PrivateRoute extends React.Component {
  render () {
    let {component: Component, ...rest} = this.props
    return (
      <Route {...rest} render={props => (
        (this.props.isAuthenticated) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', from: props.location }} />
        )
      )} />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, {})(PrivateRoute)
