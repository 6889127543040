import moment from 'moment'

const Status = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

const checkTime = () => (
  ((moment().unix() - localStorage.getItem('openfogos_timestamp')) < 6 * 3600 ) ? true : false
)

export default (state = {isAuthenticated: checkTime()}, action) => {
    const setStorage = (result) => {
        localStorage.setItem('openfogos_token', result.token)
        localStorage.setItem('openfogos_userid', result.user_id)
        localStorage.setItem('openfogos_username', result.username)
        localStorage.setItem('openfogos_timestamp', moment().unix())
    }
  switch(action.type) {
    case 'AUTH/LOGIN_STARTED': {
      return {...state, loginStatus: Status.LOADING}
    }
    case 'AUTH/LOGIN_SUCCESS': {
      setStorage(action.result);
      return {...state, loginStatus: Status.SUCCESS, isAuthenticated: true}
    }
    case 'AUTH/LOGIN_FAILURE': {
      return {...state, loginStatus: Status.FAILURE, error: action.error}
    }
      case 'AUTH/LOGIN': {
          setStorage(action.data)
          return {...state, isAuthenticated: true}
      }
    case 'AUTH/LOGOUT': {
      localStorage.clear()
      return {...state, isAuthenticated: false}
    }
      case 'AUTH/MINER/INFO_SUCCESS': {
          console.log("miner info success");
          if(action.result && action.result.length > 0){
              console.log("miner info success result");
              return {...state, isMiner: true, platforms: action.result}
          }
          return state
      }
      case 'AUTH/BC/IDS_SUCCESS': {
          console.log("bc ids success");
          if(action.result && action.result.length > 0){
              for(var bc of action.result){
                  if(bc.user_id == localStorage.getItem("openfogos_userid")){
                      console.log("BC info success result");
                      return {...state, isBC: true}
                  }
              }
          }
          return {...state, isBC: false}
      }
    default: {
      return state
    }
  }
}
